import { useEffect, useState } from "react";
import { FormProps, PersonalParticularsFormStruct } from "./Form.types";
import { SubmitAppointmentRequestRequestPayload } from "api/appointment/SubmitAppointmentRequest/submitAppointmentRequest.types";
import { CreateApptFormSubmission } from "../CreateAppointmentForm/CreateApptForm/CreateApptForm.types";
import {
  OpenApptFormSubmission,
  RescheduleApptFormSubmission,
} from "../WebAppointmentForm/WebApptForm/WebApptForm.types";
import { CancelApptFormSubmission } from "../CancelAppointmentForm/CancelApptForm/CancelApptForm.types";
import CreateApptFormContainer from "../CreateAppointmentForm/CreateApptForm/CreateApptFormContainer";
import WebApptForm from "../WebAppointmentForm/WebApptForm/WebApptForm";
import CancelApptForm from "../CancelAppointmentForm/CancelApptForm/CancelApptForm";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { Box, CircularProgress } from "@mui/material";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import Page from "lib/components/layout/Page/Page";
import MammogramRequestFormContainer from "../MammogramRequestForm/MammogramRequestForm/MammogramRequestFormContainer";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { ACTIONS, PATHS } from "lib/routing";

// Hooks
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  fetchSystemSettings,
  fetchDocumentForAllTypes,
} from "ui/appointment/ducks/thunks";
import { submitAppointmentRequest } from "api/appointment/SubmitAppointmentRequest/submitAppointmentRequest";

import { handleErrorModal } from "lib/redux/notifications";
import {
  selectAppointmentDocByType,
  selectAppointmentMeta,
  selectAppointmentsSystemSettings,
} from "ui/appointment/ducks/selectors";
import { selectUser } from "lib/redux/user/selectors";
import { AxiosError } from "axios";
import { useGetLiveChatFlag } from "ui/appointment/hooks/useGetLiveChatFlag";
import { useLocation } from "react-router-dom";

const Form = ({ apptFormType }: FormProps) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  // Redux States
  const { institutionCode, targetSystem, departmentName } = useAppSelector(
    selectAppointmentMeta,
  );
  const isPatient = useAppSelector(selectUser).isPatient;
  const memberIdentifier = useAppSelector(selectUser).memberIdentifier;
  const {
    patientName: name,
    contact,
    dateOfBirth,
    email,
  } = useAppSelector(selectUser);
  const {
    isLoading: isLoadingFormSettings,
    hasErrored: hasErroredFormSettings,
    errorMessage: errorMessageFormSettings,
    values,
  } = useAppSelector(selectAppointmentsSystemSettings);
  const {
    isLoading: isLoadingCancellationReasons,
    hasErrored: hasErroredCancellationReasons,
    errorMessage: errorMessageCancellationReasons,
  } = useAppSelector(selectAppointmentDocByType);

  const { isLiveChatEnabled, isLiveChatLoading } = useGetLiveChatFlag();

  // Redirecting to LiveChat if business hours from dashboard
  useEffect(() => {
    if (
      isLiveChatEnabled &&
      (location.pathname === PATHS.APPOINTMENT_RESCHEDULE_FORM_MOBILE.path ||
        location.pathname === PATHS.APPOINTMENT_CANCEL_FORM_MOBILE.path ||
        location.pathname === PATHS.APPOINTMENT_RESCHEDULE_FORM.path ||
        location.pathname === PATHS.APPOINTMENT_CANCEL_FORM.path)
    ) {
      mobileNavigate(
        ACTIONS.LIVECHAT_PAGE_INTERCEPTION(PATHS.APPOINTMENT_MOBILE.path),
      );
    }
  }, [isLiveChatEnabled, location.pathname]);

  // Call getSystemSettings to get Service List if previously wasn't called
  useEffect(() => {
    if (!values["ServiceList"]) {
      dispatch(
        fetchSystemSettings(
          "RequestForm",
          "NUHsAndOthers",
          null,
          institutionCode,
        ),
      );
    }
  }, [dispatch, institutionCode, values]);

  // Log event to Google Analytics based on the form type
  useEffect(() => {
    switch (apptFormType) {
      case "make":
        logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_FORM);
        break;
      case "make-mammogram":
        logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_MAMMOGRAM);
        break;
      case "reschedule":
        logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_FORM);
        break;
      case "missed":
        logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_FORM);
        break;
      case "open":
        logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_FORM);
        break;
      case "cancel":
        logEventToGoogleAnalytics(EVENTS.VIEW_CANCEL_FORM);
        if (isLoadingCancellationReasons === null) {
          dispatch(fetchDocumentForAllTypes());
        }
        break;
      default:
        break;
    }
  }, [apptFormType, dispatch, isLoadingCancellationReasons]);

  // --------------------------------------
  // Personal Particulars Form Field Controls
  // --------------------------------------

  const [personalParticularsFormField, setPersonalParticularsFormField] =
    useState<PersonalParticularsFormStruct>({
      name: isPatient ? name : null,
      contact: isPatient ? contact : null,
      dateOfBirth: isPatient ? dateOfBirth : null,
      email: isPatient ? email : null,
    });

  // Event fired whenever a field on the particulars form on any of these children forms has been updated
  // name: name attribute of input component
  const handlePersonalParticularsFormFieldChange = (event: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = event.target;
    const updatedCreateApptFormField = {
      ...personalParticularsFormField,
      [name]: value,
    };
    setPersonalParticularsFormField(updatedCreateApptFormField);
  };

  // --------------------------------------
  // API Form Submission Controls
  // --------------------------------------

  // States for the form submission API call
  const [submitIsLoading, setSubmitIsLoading] = useState(false);

  const [openSubmitConfirmationModal, setOpenSubmitConfirmationModal] =
    useState(false);

  // Controls form state & invokes API call to submit the forms
  const sendSubmitAppointmentRequest = async (
    request:
      | CreateApptFormSubmission
      | RescheduleApptFormSubmission
      | OpenApptFormSubmission
      | CancelApptFormSubmission,
  ) => {
    try {
      setSubmitIsLoading(true);
      const defaultRequest: SubmitAppointmentRequestRequestPayload = {
        InstitutionCode: institutionCode,
        TargetSystem: targetSystem,
        DepartmentName: departmentName,
        SendToEmail: null,
        PatientNo: memberIdentifier,
        PatientName: personalParticularsFormField.name,
        FirstName: null,
        LastName: null,
        PatientHandphoneNo: personalParticularsFormField.contact,
        PatientEmail: personalParticularsFormField.email,
        DateOfBirth: personalParticularsFormField.dateOfBirth,
        ContactHandphoneNo: personalParticularsFormField.contact,
        ContactEmail: personalParticularsFormField.email,

        // Flags for each form, will be set to TRUE by each form themselves
        IsMakeApptAction: false,
        IsRescheduleApptAction: false,
        IsCancelApptAction: false,

        // Create Appointment Fields
        NewAppointmentReferredby: null,
        NewAppointmentSpecialty: null,
        NewAppointmentSymptoms: null,
        NewAppointmentInstitution: institutionCode,
        NewAppointmentDoctorName: null,

        // Reschedule Appointment Fields
        ChangeAppointmentOriginalDate: null,
        ChangeAppointmentPreferredStartDate: null,
        ChangeAppointmentPreferredEndDate: null,
        ChangeAppointmentRemarks: null,
        ChangeAppointmentInstitution: institutionCode,

        // Cancel Appointment Fields
        CancelAppointmentOriginalDate: null,
        CancelAppointmentReason: null,
        CancelAppointmentRemarks: null,
        CancelAppointmentInstitution: institutionCode,
      };
      await submitAppointmentRequest({
        ...defaultRequest, // copy fields from default first
        ...request, // then override with each form's own custom fields
      });

      setOpenSubmitConfirmationModal(true);
    } catch (error) {
      setOpenSubmitConfirmationModal(false);
      if (error instanceof AxiosError) {
        dispatch(
          handleErrorModal({
            open: true,
            message: error.response?.data.Message,
          }),
        );
      } else {
        dispatch(handleErrorModal({ open: true, message: null }));
      }
    } finally {
      setSubmitIsLoading(false);
    }
  };

  return (
    <Page>
      {isLoadingFormSettings ||
      isLoadingCancellationReasons ||
      isLiveChatLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : apptFormType === "make" ? (
        hasErroredFormSettings ? (
          <ErrorDisplay
            errorMessage={errorMessageFormSettings}
            onTryAgain={() =>
              dispatch(
                fetchSystemSettings(
                  "RequestForm",
                  "NUHsAndOthers",
                  null,
                  institutionCode,
                ),
              )
            }
          />
        ) : (
          <CreateApptFormContainer
            personalParticularsFormField={personalParticularsFormField}
            handlePersonalParticularsFormFieldChange={
              handlePersonalParticularsFormFieldChange
            }
            submitIsLoading={submitIsLoading}
            openConfirmationModal={openSubmitConfirmationModal}
            onSubmitAppointmentRequest={sendSubmitAppointmentRequest}
          />
        )
      ) : apptFormType === "reschedule" ||
        apptFormType === "open" ||
        apptFormType === "missed" ? (
        <WebApptForm
          apptFormType={apptFormType}
          personalParticularsFormField={personalParticularsFormField}
          handlePersonalParticularsFormFieldChange={
            handlePersonalParticularsFormFieldChange
          }
          submitIsLoading={submitIsLoading}
          openConfirmationModal={openSubmitConfirmationModal}
          onSubmitAppointmentRequest={sendSubmitAppointmentRequest}
        />
      ) : apptFormType === "cancel" ? (
        hasErroredCancellationReasons ? (
          <ErrorDisplay
            errorMessage={errorMessageCancellationReasons}
            onTryAgain={() => dispatch(fetchDocumentForAllTypes())}
          />
        ) : (
          <CancelApptForm
            personalParticularsFormField={personalParticularsFormField}
            handlePersonalParticularsFormFieldChange={
              handlePersonalParticularsFormFieldChange
            }
            submitIsLoading={submitIsLoading}
            openConfirmationModal={openSubmitConfirmationModal}
            onSubmitAppointmentRequest={sendSubmitAppointmentRequest}
          />
        )
      ) : apptFormType === "make-mammogram" ? (
        <MammogramRequestFormContainer
          personalParticularsFormField={personalParticularsFormField}
          handlePersonalParticularsFormFieldChange={
            handlePersonalParticularsFormFieldChange
          }
        />
      ) : null}
    </Page>
  );
};

export default Form;
