export const ACTIONS = {
  MOBILE_DASHBOARD_PATH: `action=mobile_dashboard`,
  MOBILE_MEDICATION_LANDING: `action=medication_landing`,
  MOBILE_APPOINTMENT_CREATE: `action=appointment_create`,
  MOBILE_PROFILE_MENU_PATH: `action=profile_menu`,
  MOBILE_UNAUTHORISED_PATH: `action=mobile_unauthorised`,
  ANDROID_UPLOAD_PREVIEW: (filename: string) =>
    `action=android_upload_preview&filename=${filename}`,

  MOBILEINNIT: `action=mobileInnit`,
  EXTERNAL_LINK_INTERCEPTION: `action=externallink`,
  STANDARD_PAGE_INTERCEPTION: `action=standardpage`,
  BACK_ACTION: `action=back`,
  SHOW_CLOSE_ACTION: `action=showclose`,
  SHARE_ACTION: `action=share`,
  ORDER_MED_ACTION: `action=orderMed`,
  REG_TUTORIAL_PROMPTED: `action=reg_tutorial_prompted`,

  MY_CHART: {
    MEDICATION: `action=mychart&url=epichttp://medslist`,
    PREVENTIVE_CARE: `action=mychart&url=epichttp://healthadvisories`,
  },

  LIVECHAT_PAGE_INTERCEPTION: (returnUrl: string) =>
    `action=livechat&returnUrl=${returnUrl}`,

  // =================
  // Patient Master
  // =================
  MOBILE_PM_SYNC: `action=profile_sync`, //redirects user to patient master sync
  PM_SYNC_DO_IT_LATER_ANDROID: `action=do_it_later_android`, // for android back button handling on pm sync to mimic do it later
  PM_SYNC_DO_IT_LATER: `action=do_it_later`, // during sync patient master user clicks to do it later
  PROFILE_UPDATE_ACTION: `action=profile_update`,
  PROFILE_SYNC_SUCCESS: `action=profile_sync_success`, // after successful save to patient master, when user clicks OK to navigate back to profile
  MYCHART_UUID: (uuid: string | null) => `action=mychartuuid&uuid=${uuid}`,

  // actions for downloading of BLOB
  GET_PDF_BILL_ACTION: `action=get_pdf_bill`,
  DOWNLOAD_MEDICAL_REPORT_ACTION: `action=download_medical_report`,
  GET_RECEIPT_PDF_ACTION: `action=get_receipt_pdf`,

  // ====
  // HH Standard Pages
  // ====
  HHNG_STANDARD: `targetmodule=HhNgStandard`,
  SS_DATASERVICES: `targetmodule=ss_dataservices`,
};
