import { MessageActionsType } from "./messageActions.types";

// action values are always sent as camelCase string
export const MessageActions = {
  acknowledge: () => ({
    action: "acknowledge",
  }),

  rating: () => ({
    action: "rating",
  }),

  landing: (isLanding: boolean) => ({
    action: "landing",
    params: {
      isLanding,
    },
  }),

  staticTitle: ({
    title,
    isEnabled,
  }: {
    title: string;
    isEnabled: boolean;
  }) => ({
    action: "staticTitle",
    params: {
      title,
      isEnabled,
    },
  }),

  dosClinic: ({
    serviceTypes = ["ALL"],
    clinicTypes = [],
    postalCode,
  }: {
    serviceTypes?: string[];
    clinicTypes?: string[];
    postalCode: string;
  }) => ({
    action: "directory",
    params: {
      category: "CLINICS",
      serviceTypes,
      clinicTypes,
      postalCode,
    },
  }),

  dosDining: ({
    types = [],
    postalCode,
  }: {
    types?: string[];
    postalCode: string;
  }) => ({
    action: "directory",
    params: {
      category: "DINING_PLACES",
      types,
      postalCode,
    },
  }),

  back: (state: "ENABLED" | "DISABLED" | "HIDDEN") => ({
    action: "back",
    params: {
      state,
    },
  }),
} satisfies MessageActionsType;
