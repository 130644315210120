import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import HeaderBlock from "../../components/HeaderBlock/HeaderBlock";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { Box, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { updateReferralForCreate } from "ui/appointment/ducks/appointmentsSlice";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { useEffect, useState } from "react";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { selectCreateAppointment } from "ui/appointment/ducks/selectors";
import { PATHS } from "lib/routing";
import ReferralBlock from "../../components/ReferralBlock/ReferralBlock";
import { REFERRED_BY } from "api/appointment/CreateAppointment/FormData/createAppointmentRequestFormData";

const ReferralSelectionPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { selectedInstitution, selectedSpecialty, selectedReferral } =
    useAppSelector(selectCreateAppointment);

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Reset referral state
  useEffect(() => {
    dispatch(updateReferralForCreate(""));
  }, [dispatch]);

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <HeaderBlock location={selectedInstitution} />

      <Box
        sx={{
          m: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <Box sx={{ mt: 3, mb: 4 }}>
            <SingleLineTextField
              value={selectedSpecialty?.description}
              placeholder="Specialty"
              disabled
            />
          </Box>

          <ReferralBlock />
        </Box>

        <Stack gap={2}>
          <Button
            disabled={!selectedReferral}
            onClick={() => {
              // !exp: Business logic to display popup when referral is "Myself"
              if (selectedReferral === REFERRED_BY[0].value) {
                setIsModalOpen(true);
              } else {
                navigate(PATHS.APPOINTMENT_CREATE_FORM.path);
              }
            }}
          >
            Next
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
        </Stack>
      </Box>

      <ConfirmationModal
        title="Book Private Appointment"
        open={isModalOpen}
        body={MapRawStringToReactElement(
          selectedSpecialty?.beforeCareMessage ?? "",
        )}
        nextButtonText="Proceed"
        onClickNext={() => {
          setIsModalOpen(false);
          navigate(PATHS.APPOINTMENT_CREATE_CONSULTANT.path);
        }}
        cancelButtonText="Cancel"
        onClickCancel={() => setIsModalOpen(false)}
      />
    </Box>
  );
};

export default ReferralSelectionPage;
