import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    summaryPanel: {
      display: "flex",
      marginTop: theme.spacing(2),
      alignItems: "center",
    },
    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.caption1_semibold,
      width: "100%",
    },
    content: {
      color: theme.palette.grey[600],
      typography: theme.typography.caption1_semibold,
      width: "100%",
    },
    payValue: {
      color: theme.palette.warning.main,
      typography: theme.typography.body2_semibold,
      width: "100%",
    },
  } satisfies SxObjType;
};

export { sxStyles };
