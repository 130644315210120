import { Box, RadioGroup, Typography, useTheme } from "@mui/material";
import RadioButton from "lib/components/formInputs/FullRadioButton/FullRadioButton";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { updateReferralForCreate } from "ui/appointment/ducks/appointmentsSlice";
import { REFERRED_BY } from "api/appointment/CreateAppointment/FormData/createAppointmentRequestFormData";
import { size } from "lib/theme/size";
import { selectCreateAppointment } from "ui/appointment/ducks/selectors";

const ReferralBlock = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { selectedReferral } = useAppSelector(selectCreateAppointment);

  return (
    <>
      <Typography
        sx={{
          color: theme.palette.text.primary,
          fontWeight: theme.typography.fontWeightMedium,
          fontSize: size.LIST_TITLEFONTSIZE,
          marginTop: theme.spacing(2),
          "&.Mui-focused": {
            color: theme.palette.text.primary,
          },
        }}
      >
        I was referred by{" "}
      </Typography>
      <RadioGroup
        name={"referredBy"}
        value={selectedReferral}
        onChange={(event) => {
          dispatch(updateReferralForCreate(event.target.value));
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mt: 1.5,
          }}
        >
          {REFERRED_BY.map((choice) => (
            <Box
              key={choice.id}
              display="flex"
              flexDirection="column"
              mb={1}
              width={"fit-content"}
            >
              <RadioButton
                checked={choice.value === selectedReferral}
                label={choice.label}
                value={choice.value}
              />
            </Box>
          ))}
        </Box>
      </RadioGroup>
      <Box
        sx={{
          color: theme.palette.error.main,
          typography: theme.typography.caption1_regular,
          padding: theme.spacing(0.5, 1.5),
        }}
      >
        *Required
      </Box>
    </>
  );
};

export default ReferralBlock;
