import { Box, Typography, useTheme } from "@mui/material";
import { BillCardProps } from "./BillCard.types";
import { sxStyles } from "./BillCard.styles";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import SimpleCheckbox from "lib/components/formInputs/Checkbox/SimpleCheckbox";
import PendingDisclaimer from "../../common/PendingDisclaimer/PendingDisclaimer";
import IMAGES from "lib/assets/images";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { ACTIONS } from "lib/routing";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { ViewOutstandingBills } from "api/payment/GetOutstandingBills/getOutstandingBills.fromApi.types";
import { ViewManualBill } from "api/payment/AddManualBill/addManualBill.fromApi.types";
import { useEffect } from "react";

const BillCard = ({
  bill,
  checked,
  disabled = false,
  amountToPayForThisBill,
  memberIdentifier,
  showDownloadBillIcon = false,
  onCheckBill,
  onChangeAmount,
}: BillCardProps) => {
  const theme = useTheme();
  const classes = sxStyles(theme);

  // set the amount to pay to due amount when input amount exceed due amount
  useEffect(() => {
    if (
      dueAmount(bill) !== 0 &&
      Number(amountToPayForThisBill) > dueAmount(bill)
    ) {
      onChangeAmount(dueAmount(bill).toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountToPayForThisBill]);

  const downloadPdfForpaidBill = async () => {
    mobileNavigate(
      `${ACTIONS.GET_PDF_BILL_ACTION}&BillDate=${bill.VisitDate}&BillingSystem=${bill.BillingSystem}&InstitutionCode=${bill.InstitutionCode}&DownloadId=${bill.DownloadId}&MemberIdentifier=${memberIdentifier}&BillCategory=Outstanding`,
    );
  };

  return (
    <>
      <Box sx={classes.cardPanel}>
        <Box sx={classes.cardLeftPanel}>
          <Box sx={classes.checkboxField}>
            <SimpleCheckbox
              checked={checked}
              disabled={disabled}
              value=""
              label=""
              handleChange={onCheckBill}
            ></SimpleCheckbox>
          </Box>
        </Box>
        <Box sx={classes.cardRightPanel}>
          <Box sx={classes.cardDetails}>
            <Box sx={classes.cardLeft}>
              <Typography sx={classes.cardTitle}>
                {bill.InstitutionName}
              </Typography>
            </Box>

            <Box sx={classes.cardRight}>
              {showDownloadBillIcon ? (
                <IconTextButton
                  icon={IMAGES.general.DownloadIcon}
                  label={["Download", "bill"]}
                  onClick={async () => {
                    downloadPdfForpaidBill();
                  }}
                  ariaLabel={"Download bill"}
                />
              ) : null}
            </Box>
          </Box>

          <Box sx={classes.cardDetails}>
            <Box sx={classes.cardLeft}>
              <Typography sx={classes.cardLabel}>
                {getDateDescription(bill)}
              </Typography>
            </Box>
          </Box>

          <Box sx={classes.cardDetails}>
            <Box sx={classes.cardRow}>
              <Typography sx={classes.cardRowLabel}>
                Bill Reference No:
              </Typography>
              <Typography sx={classes.cardRowValue}>
                {bill.InvoiceNumber}
              </Typography>
            </Box>
          </Box>
          {disabled ? (
            <PendingDisclaimer />
          ) : (
            <>
              <Box sx={classes.cardDetails}>
                <Box sx={classes.cardRow}>
                  <Typography sx={classes.cardRowLabel}>
                    Total Amount:
                  </Typography>
                  <Typography sx={classes.cardRowValue}>
                    {bill.AmountDueFromPatient === null ? (
                      <span>-</span>
                    ) : (
                      <span>{formatPayment(bill.AmountDueFromPatient)}</span>
                    )}
                  </Typography>
                </Box>
              </Box>

              <Box sx={classes.cardValueDetails}>
                <Box sx={classes.cardLeft}>
                  <Typography sx={classes.cardLabel}>
                    <Box component={"span"} mt={1.6}>
                      Amount to Pay:
                    </Box>
                  </Typography>
                </Box>
                <Box sx={classes.cardRight}>
                  {checked ? (
                    <Box sx={classes.cardValueBox}>
                      <Typography sx={classes.dollarSign}>$</Typography>
                      <SingleLineTextField
                        type="decimal"
                        name="amountToPay"
                        decimalPlaces={2}
                        maxCharLength={8}
                        value={
                          Number(amountToPayForThisBill) < 0
                            ? "0.00"
                            : amountToPayForThisBill
                        }
                        required={false}
                        handleChange={(event) => {
                          onChangeAmount(event.target.value);
                        }}
                        handleBlur={(event) => {
                          unselectZeroAmountOnBlur({
                            checked,
                            amountToPayForThisBill,
                            onCheckBill,
                          });
                        }}
                      ></SingleLineTextField>
                    </Box>
                  ) : (
                    <Typography sx={classes.cardValue}>
                      <Box component={"span"} mt={1.6}>
                        {formatPayment(Number(amountToPayForThisBill))}
                      </Box>
                    </Typography>
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

// Helper functions
const dueAmount = (bill: ViewOutstandingBills | ViewManualBill) => {
  if (bill.AmountDueFromPatient) {
    if (bill.AmountDueFromPatient < 0) {
      return 0;
    } else {
      return bill.AmountDueFromPatient;
    }
  }
  return 0;
}; // This should be a mandartory field, assigning 0 for typecheck only

const getDateDescription = (bill: ViewOutstandingBills | ViewManualBill) => {
  let dateTimeInfoStr: string | null = "";
  let descriptionStr: string = "";
  if (bill.VisitType === "O") {
    descriptionStr = "Visited on ";
    dateTimeInfoStr = bill.VisitDate;
  } else if (bill.VisitType === "I" && "DischargeDate" in bill) {
    descriptionStr = "Discharged on ";
    dateTimeInfoStr = bill.DischargeDate;
  } else {
    descriptionStr = "Bill on ";
    dateTimeInfoStr = bill.VisitDate;
    if (!dateTimeInfoStr && "DischargeDate" in bill)
      dateTimeInfoStr = bill.DischargeDate;
  }
  let dateTimeObj = getDateFromISOString(dateTimeInfoStr);
  return dateTimeObj
    ? descriptionStr + formatDate(formatDateToISO(dateTimeObj), "dd MMM yyyy")
    : null;
};

const unselectZeroAmountOnBlur = ({
  checked,
  amountToPayForThisBill,
  onCheckBill,
}: {
  checked: boolean;
  amountToPayForThisBill: string;
  onCheckBill: () => void;
}) => {
  if (checked && Number(amountToPayForThisBill) === 0) onCheckBill();
};

export const exportForTesting = {
  dueAmount,
  getDateDescription,
  unselectZeroAmountOnBlur,
};

export default BillCard;
