import { Box } from "@mui/material";
import SelectionListingHeaderText from "ui/appointment/components/common/selection-listing/SelectionListingHeaderText/SelectionListingHeaderText";

const HeaderBlock = ({
  location,
  service,
  specialist,
}: {
  location?: string | null;
  service?: string | null;
  specialist?: string | null;
}) => {
  return (
    <Box
      sx={(theme) => ({
        bgcolor: theme.palette.secondary.main,
        py: 0.5,
        px: 0,
      })}
    >
      {location && (
        <SelectionListingHeaderText label="Location" text={location} />
      )}

      {service && <SelectionListingHeaderText label="Service" text={service} />}

      {specialist && (
        <SelectionListingHeaderText label="Specialist" text={specialist} />
      )}
    </Box>
  );
};

export default HeaderBlock;
