import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  useTheme,
} from "@mui/material";
import IMAGES from "lib/assets/images";
import { sxStyles } from "./AccordionBlock.styles";

const AccordionBlock = ({
  summaryNode,
  detailsNode,
  expanded,
  summaryBackgroundColor,
  onExpand,
}: {
  summaryNode: React.ReactNode | string;
  detailsNode: React.ReactNode | string;
  expanded: boolean;
  summaryBackgroundColor?: string;
  onExpand: (event: React.ChangeEvent<{}>, expanded: boolean) => void;
}) => {
  const theme = useTheme();
  const classes = sxStyles({ summaryBackgroundColor, theme });

  return (
    <Accordion
      square={true}
      sx={classes.accordion}
      onChange={onExpand}
      expanded={expanded}
    >
      <AccordionSummary
        sx={classes.accordionSummary}
        expandIcon={
          <Box component={"img"} src={IMAGES.general.AccordionIcon} />
        }
      >
        {summaryNode}
      </AccordionSummary>

      <AccordionDetails sx={classes.accordionDetails}>
        {detailsNode}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionBlock;
