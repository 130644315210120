import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, CircularProgress, Typography } from "@mui/material";
import { ACTIONS, PATHS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { KioskQuestionsProps } from "./KioskQuestionnairePage.types";
import { KioskQuestionAndAnswer } from "api/appointment/GetKioskQuestionnaire/getKioskQuestionnaire.toUi";
import { actualiseAppointment } from "api/appointment/ActualiseAppontment/actualiseAppontment";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import VerticalSpreadLayout from "lib/components/layout/VerticalSpreadLayout/VerticalSpreadLayout";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { useGetKioskQuestionnaire } from "ui/appointment/hooks/useGetKioskQuestionnaire";
import KioskQuestion from "../KioskQuestion/KioskQuestion";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import { sxStyles } from "./KioskQuestionnairePage.styles";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { checkIn } from "api/appointment/CheckIn/checkIn";
import IMAGES from "lib/assets/images";
import { AxiosError } from "axios";
import { useAppSelector } from "lib/redux/hooks";
import { selectAppointmentMeta } from "ui/appointment/ducks/selectors";
import { isNUP } from "lib/util/ValidatorUtil/isNupValidator/isNupValidator";

const TARGET_SYSTEM = {
  NGEMR: "NGEMR",
};

const TITLES = {
  REGISTRATION_SUCCESS: "Registration Successful",
  CHECKIN_SUCCESS: "Welcome to Our Clinic",
  CHECKIN_WARNING: "Oops...",
};

const KioskQuestionnairePage = ({
  appointmentId,
  memberIdentifier,
  regInstitutionCode,
  institutionCode,
  clinicCode,
  targetSystem,
  queueNumber,
  institutionName,
  consultType,
  actualizeStatus,
  visitType,
  isCheckIn,
  checkInClinicCode,
  encounterId,
  currentAppointmentTime,
  regAppointmentId,
  vcSupportStatus,
  onActualisedAppointment,
  handleErrorModal,
  handleSuccessModal,
}: KioskQuestionsProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = sxStyles();

  const { logoCode } = useAppSelector(selectAppointmentMeta);

  const isVirtualConsultAppointment = vcSupportStatus === "supported";

  useEffect(() => {
    logEventToGoogleAnalytics(EVENTS.VIEW_REGISTRATION_TRIAGE);
  }, []);

  const [pageFlag, setPageFlag] = useState(false);
  const [hasCheckInError, setHasCheckInError] = useState(false);
  const [checkInErrorMsg, setCheckInErrorMsg] = useState<string | null>();
  const [hasActualiseError, setHasActualiseError] = useState(false);
  const [actualiseErrorMsg, setActualiseErrorMsg] = useState<string | null>();

  const shouldGetKioskQuestionnaire = useMemo(
    () =>
      shouldGetKioskQuestionnaireAccordingToTargetSystem(
        targetSystem,
        actualizeStatus,
        queueNumber,
      ),
    [actualizeStatus, queueNumber, targetSystem],
  );
  const shouldProceedActualize = useMemo(
    () =>
      shouldProceedActualizeAccordingToTargetSystem(
        targetSystem,
        actualizeStatus,
        queueNumber,
        encounterId,
        logoCode,
        institutionCode,
      ),
    [
      targetSystem,
      actualizeStatus,
      queueNumber,
      encounterId,
      logoCode,
      institutionCode,
    ],
  );

  const shouldProceedQueueStatusAndJourney = useMemo(
    () =>
      shouldProceedQueueStatusAndJourneyAccordingToTargetSystem(
        targetSystem,
        actualizeStatus,
        queueNumber,
        logoCode,
        institutionCode,
      ),
    [actualizeStatus, institutionCode, logoCode, queueNumber, targetSystem],
  );

  // Retrieve Kiosk Questionnaire from the remote API & Handle states for recorded Answers
  const [
    questionAnswers,
    setKioskQuestionAnswers,
    isLoadingQuestionnaire,
    hasErroredQuestionnaire,
    errorMessage,
    queueNumberFromKioskQuestionnaire,
  ] = useGetKioskQuestionnaire(
    pageFlag,
    isCheckIn ? true : shouldGetKioskQuestionnaire,
    memberIdentifier,
    regInstitutionCode,
    institutionCode,
    targetSystem,
    isCheckIn ? checkInClinicCode : clinicCode,
    visitType,
    appointmentId,
    logoCode,
  );

  const handleQuestionnaireChange = (
    isChecked: boolean,
    questionIndex: number,
  ) => {
    if (questionAnswers) {
      let updated = [...questionAnswers];
      updated[questionIndex].QuestionAnswer = isChecked;
      setKioskQuestionAnswers(updated);
    }
  };

  // States for Appointment Actualization
  const [isActualizing, setIsActualizing] = useState(false);

  // States for Check In
  const [isCheckingIn, setIsCheckingIn] = useState(false);

  // States & Handlers for Confirmation Dialog, after Actualizing an appointment successfully
  const [confirmationTitle, setConfirmationTitle] = useState<string | null>();
  const [confirmationText, setConfirmationText] = useState<string | null>();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  // Send Appointment Registration Request
  // Actualise Appointment and Submit Questionnaire in the process
  const sendActualiseAppointmentRequest = async () => {
    try {
      if (appointmentId && regInstitutionCode && regAppointmentId) {
        // Make the request
        setIsActualizing(true);

        const actualiseAppointmentPayload = await actualiseAppointment(
          memberIdentifier,
          regAppointmentId,
          regInstitutionCode,
          institutionCode,
          clinicCode,
          questionAnswers.map((qna) => {
            return {
              QuestionId: qna.QuestionId,
              // Defensive case included here, where for some reason, the answer is NULL
              Response: isVirtualConsultAppointment
                ? false
                : qna.QuestionAnswer ?? false,
            };
          }),
          vcSupportStatus,
        );

        // Update the queue number and encounterid, so that it can be used in the next step
        onActualisedAppointment(
          appointmentId,
          actualiseAppointmentPayload.QueueNumber,
          institutionName,
          consultType,
          actualiseAppointmentPayload.EncounterId,
          currentAppointmentTime,
          logoCode,
        );

        // redirect back to appointment landing page for virtual consult appointment
        if (isVirtualConsultAppointment) {
          navigate(PATHS.APPOINTMENT_MOBILE.path);
        } else if (questionAnswers.length === 0) {
          navigate(PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_NO_BACK.path);
        } else {
          setConfirmationTitle(TITLES.REGISTRATION_SUCCESS);
          setConfirmationText(actualiseAppointmentPayload.ErrorText);
          handleOpenConfirmation();
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (questionAnswers.length === 0) {
          setHasActualiseError(true);
          setActualiseErrorMsg(error.response?.data.Message);
        } else {
          handleErrorModal(true, error.response?.data.Message);
        }
      } else {
        handleErrorModal(true, null);
      }
    } finally {
      setIsActualizing(false);
    }
  };

  // Send CheckIn Request
  // CheckIn and Submit Questionnaire in the process
  const sendCheckInRequest = async () => {
    try {
      if (regAppointmentId && regInstitutionCode && checkInClinicCode) {
        // Make the request
        setIsCheckingIn(true);

        const checkInPayload = await checkIn(
          memberIdentifier,
          regAppointmentId,
          encounterId,
          currentAppointmentTime,
          questionAnswers.map((qna) => {
            return {
              QuestionId: qna.QuestionId,
              // Defensive case included here, where for some reason, the answer is NULL
              Response: qna.QuestionAnswer ?? false,
            };
          }),
        );

        if (checkInPayload.EncounterStatus === "S") {
          handleSuccessModal(
            true,
            "checkIcon",
            TITLES.CHECKIN_SUCCESS,
            checkInPayload.Message,
          );
          navigate(PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_NO_BACK.path);
        } else if (checkInPayload.EncounterStatus === "W") {
          handleSuccessModal(
            true,
            "errorIcon",
            TITLES.CHECKIN_WARNING,
            checkInPayload.ErrorText,
          );
          navigate(PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_NO_BACK.path);
        }
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        if (questionAnswers.length === 0) {
          setCheckInErrorMsg(error.response?.data.Message);
          setHasCheckInError(true);
        } else {
          handleErrorModal(true, error.response?.data.Message);
        }
      } else {
        handleErrorModal(true, null);
      }
    } finally {
      setIsCheckingIn(false);
    }
  };

  useEffect(() => {
    if (isCheckIn) {
      if (hasErroredQuestionnaire === false) {
        if (questionAnswers.length === 0) {
          sendCheckInRequest();
        }
      }
    } else {
      // flow handling when getKioskQuestionnaire is not called
      if (!shouldGetKioskQuestionnaire) {
        if (shouldProceedActualize) {
          // Appt is not actualized
          sendActualiseAppointmentRequest();
        } else if (shouldProceedQueueStatusAndJourney) {
          // Appt is actualized
          onActualisedAppointment(
            appointmentId || "",
            queueNumber || "",
            institutionName,
            consultType,
            encounterId || "",
            currentAppointmentTime || "",
            logoCode,
          );
          navigate(PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_NO_BACK.path);
        }
      }
      // normal flow handling after getKioskQuestionnaire is called
      else if (hasErroredQuestionnaire === false) {
        if (isVirtualConsultAppointment) {
          sendActualiseAppointmentRequest();
        } else if (queueNumberFromKioskQuestionnaire) {
          // If there is already the queue number return from getKioskQuestionnaire, just use the queue number and no calling of actualizeAppointment
          onActualisedAppointment(
            appointmentId || "",
            queueNumberFromKioskQuestionnaire,
            institutionName,
            consultType,
            encounterId || "",
            currentAppointmentTime || "",
            logoCode,
          );
          navigate(PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_NO_BACK.path);
        }
        // register the appointment directly if there is no kiosk question
        else if (questionAnswers.length === 0) {
          sendActualiseAppointmentRequest();
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasErroredQuestionnaire,
    questionAnswers,
    queueNumberFromKioskQuestionnaire,
  ]);

  return (
    <VerticalSpreadLayout>
      {isLoadingQuestionnaire || isActualizing || isCheckingIn ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : hasErroredQuestionnaire ? (
        <ErrorDisplay
          errorMessage={errorMessage}
          onTryAgain={() => setPageFlag(!pageFlag)}
        />
      ) : hasActualiseError ? (
        <ErrorDisplay
          errorMessage={actualiseErrorMsg}
          onTryAgain={() => {
            sendActualiseAppointmentRequest();
            setHasActualiseError(false);
          }}
        />
      ) : hasCheckInError ? (
        <ErrorDisplay
          errorMessage={checkInErrorMsg}
          onTryAgain={() => {
            sendCheckInRequest();
            setHasCheckInError(false);
          }}
        />
      ) : questionAnswers && !isVirtualConsultAppointment ? (
        <>
          <Box sx={{ px: 2, pt: 1, mb: 4 }}>
            <Box sx={{ mt: 3 }}>
              <Box sx={classes.requireBox}>
                <Box
                  component={"img"}
                  src={IMAGES.general.InformationErrorIcon}
                />
                <Typography component={"span"} sx={classes.requireText}>
                  All fields required
                </Typography>
              </Box>
            </Box>
            {questionAnswers?.map((question, i) => {
              return (
                <Box key={question.QuestionId}>
                  <KioskQuestion
                    questionNumber={i}
                    questionText={question.QuestionText}
                    questionAns={questionAnswers[i].QuestionAnswer}
                    handleQuestionnaireChange={(isChecked) =>
                      handleQuestionnaireChange(isChecked, i)
                    }
                  />
                </Box>
              );
            })}
          </Box>
          <Box sx={{ px: 2, pb: 2.5 }}>
            <ButtonsFooter
              nextButtonText="Next"
              cancelButtonText="Back"
              isDisabled={
                isActualizing ||
                isCheckingIn ||
                !areAllQuestionsAnswered(questionAnswers)
              }
              onClickNext={() => {
                if (isCheckIn) {
                  sendCheckInRequest();
                } else if (encounterId) {
                  navigate(PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_NO_BACK.path);
                } else {
                  sendActualiseAppointmentRequest();
                }
              }}
              onClickCancel={() => {
                if (
                  location.pathname ===
                  PATHS.APPOINTMENT_REGISTER_KIOSK_MOBILE.path
                ) {
                  mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH);
                } else {
                  navigate(-1);
                }
              }}
            />
          </Box>

          {/* After Successful Actualization */}
          <ConfirmationModal
            open={openConfirmation}
            hideCancelButton={true}
            title={confirmationTitle || ""}
            body={MapRawStringToReactElement(confirmationText || "")}
            nextButtonText="OK"
            onClickNext={() => {
              // proceed next after successful actualisation
              navigate(PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_NO_BACK.path);
            }}
            onClose={handleCloseConfirmation}
          />
        </>
      ) : null}
    </VerticalSpreadLayout>
  );
};

/**
 * If the target system is NGEMR, and the actualize status is P and there is no queue number, then
 * return true. Otherwise, if there is no queue number, return true. Otherwise, return false
 * @param {string | null} targetSystem - The system that the patient is registered in.
 * @param {string | null} actualizeStatus - This is the status of the patient's actualization.
 * @param {string | null} queueNumber - The queue number of the patient.
 * @returns A boolean value
 */
const shouldGetKioskQuestionnaireAccordingToTargetSystem = (
  targetSystem: string | null,
  actualizeStatus: string | null,
  queueNumber: string | null,
) => {
  if (targetSystem === TARGET_SYSTEM.NGEMR) {
    if (actualizeStatus === "P" && !queueNumber) {
      return true;
    } else {
      return false;
    }
  } else if (!queueNumber) {
    return true;
  } else {
    return false;
  }
};

/**
 * If the target system is NGEMR and the actualize status is P and the queue number is not null, then
 * return true. Otherwise, return false
 * @param {string | null} targetSystem - string | null
 * @param {string | null} actualizeStatus - The status of the actualization process.
 * @param {string | null} queueNumber - The queue number of the patient in the target system.
 * @returns A boolean value
 */
const shouldProceedActualizeAccordingToTargetSystem = (
  targetSystem: string | null,
  actualizeStatus: string | null,
  queueNumber: string | null,
  encounterId: string | null,
  logoCode: string | null,
  institutionCode: string | null,
) => {
  if (targetSystem === TARGET_SYSTEM.NGEMR) {
    // NUP flow
    if (isNUP(institutionCode, logoCode)) {
      if (actualizeStatus === "P" && !encounterId) {
        return true;
      } else {
        return false;
      }
    } else {
      // SOC flow
      if (actualizeStatus === "P" && queueNumber) {
        // SOC flow
        return true;
      } else {
        return false;
      }
    }
  } else {
    return false;
  }

  // !exp: original logic
  // if (targetSystem === TARGET_SYSTEM.NGEMR) {
  //   if (actualizeStatus === "P" && queueNumber) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // } else {
  //   return false;
  // }
};

/**
 * It returns true if the target system is NGEMR and the actualize status is W, S, or E and the queue
 * number is not null
 * @param {string | null} targetSystem - string | null
 * @param {string | null} actualizeStatus - string | null
 * @param {string | null} queueNumber - The queue number of the patient
 * @returns A boolean value
 */
const shouldProceedQueueStatusAndJourneyAccordingToTargetSystem = (
  targetSystem: string | null,
  actualizeStatus: string | null,
  queueNumber: string | null,
  logoCode: string | null,
  institutionCode: string | null,
) => {
  if (targetSystem === TARGET_SYSTEM.NGEMR) {
    // NUP flow
    if (isNUP(institutionCode, logoCode)) {
      if (queueNumber) {
        return true;
      } else {
        return false;
      }
    } else {
      // SOC flow
      if (
        (actualizeStatus === "W" ||
          actualizeStatus === "S" ||
          actualizeStatus === "E") &&
        queueNumber
      ) {
        return true;
      } else {
        return false;
      }
    }
  } else if (queueNumber) {
    return true;
  } else {
    return false;
  }
};

/**
 * Determines if all kiosk questions have been answered. We should disable the
 * NEXT button to prevent user from actualizing this appointment, if not all
 * questions are answered.
 *
 * @param {string[] | null} ansList  List of answers for the kiosk questionnaire
 * @returns {boolean}  True if all answered, False otherwise.
 */
const areAllQuestionsAnswered = (ansList: KioskQuestionAndAnswer[]) => {
  if (ansList) {
    // at least 1 answer hasn't been answered yet
    return ansList.every((ans) => ans.QuestionAnswer !== null);
  } else {
    return true;
  }
};

export default KioskQuestionnairePage;
