import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    profileHeader: {
      backgroundColor: theme.palette.secondary.main,
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
    },
    profileName: {
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
      margin: theme.spacing(2),
      wordBreak: "break-word",
    },

    root: {
      backgroundColor: theme.palette.secondary.main,
      padding: 0,
      width: "80%",
      color: theme.palette.text.primary,
      typography: theme.typography.body1_semibold,
      margin: theme.spacing(2),
      wordBreak: "break-word",
    },

    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      margin: theme.spacing(2),
    },
    cardContainer: {
      margin: theme.spacing(2, 1),
    },
    dividerBox: {
      margin: theme.spacing(4, 2, 0),
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    viewPastRequestTextBox: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1),
    },
    viewPastContainer: {
      display: "flex",
      alignItems: "center",
    },
    buttonText: {
      color: theme.palette.primary.main,
      typography: theme.typography.body2_semibold,
      marginRight: theme.spacing(1),
    },

    bottomTitle: {
      marginLeft: theme.spacing(2),
      typography: theme.typography.header4_semibold,
      color: theme.palette.text.primary,
    },
    noRecordsImageContainer: {
      padding: theme.spacing(5),
    },
    noRecordsImage: {
      width: "100%",
    },
    noRecordsTitle: {
      color: theme.palette.text.primary,
      fontSize: size.FONTSIZE_18,
      textAlign: "center",
      fontWeight: theme.typography.fontWeightRegular,
    },
    noRecordsText: {
      color: theme.palette.grey[600],
      typography: theme.typography.body1_regular,
      textAlign: "center",
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
    },
    bottomTitleContainer: {
      marginTop: theme.spacing(2),
      display: "flex",
      alignItems: "center",
    },
  } satisfies SxObjType;
};

export { sxStyles };
