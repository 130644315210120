export enum color {
  // ========================
  // Primary color
  // ========================
  PRIMARY_MAIN = "#00A9E0",
  PRIMARY_DARK = "#002F6C",
  PRIMARY_BRAND = "#0077C8",

  // ========================
  // Warning color
  // ========================
  WARNING_MAIN = "#E57200",
  WARNING_LIGHT = "#EBAA4D",
  WARNING_DARK = "#EF5713",

  // ========================
  // Error color
  // ========================
  ERROR_MAIN = "#E4002B",
  ERROR_LIGHT = "#F44336",
  ERROR_DARK = "#FF84A6",

  // ========================
  // Secondary color
  // ========================
  SECONDARY_MAIN = "#F6F6F6",
  SECONDARY_LIGHT = "#E8E8E8",
  SECONDARY_LIGHTER = "#F5F7FB",

  // ========================
  // Success color
  // ========================
  SUCCESS_MAIN = "#00B071",

  // ========================
  // Grey color
  // ========================
  GREY100 = "#CCCCCC",
  GREY400 = "#404040",
  GREY600 = "#666666",

  // ========================
  // Common color
  // ========================
  WHITE = "#FFFFFF",
  BLACK = "#000000",

  // ========================
  // Text color
  // ========================
  TEXT_PRIMARY = "#002F6C",
  TEXT_SECONDARY = "#808080",

  // ========================
  // Info color
  // ========================
  INFO_ALERTBOX_RED = "#FFF3F6",
  INFO_ALERTBOX_BLUE = "#F2F8FC",
  INFO_ORANGE = "#F5C799",

  // ========================
  // RBGA color
  // ========================
  RGBA_102 = "rgba(102, 102, 102, 0.5)",
  RGBA_52 = "rgba(52, 54, 52, 0.24)",
  RGBA_0 = "rgba(0, 0, 0, 0.25)",
  RGBA_204 = "rgba(204, 204, 204, 0.5)",
  RGBA_52_16 = "rgba(52, 54, 52, 0.161)",
  RGBA_246 = "rgba(246, 246, 246, 1)",
}
