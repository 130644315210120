import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { updatePatientMasterProfile } from "api/patientMaster/UpdatePatientMasterProfile/updatePatientMasterProfile";
import { updateUserProfile } from "api/shared/UpdateUserProfile/updateUserProfile";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { setFromInitialProfile } from "lib/redux/navigation";
import { handleErrorModal } from "lib/redux/notifications";
import {
  fetchUserParticulars,
  setProfileAccessEntryPoint,
  setShouldDirectToMyInfo,
} from "lib/redux/user";
import { selectUser } from "lib/redux/user/selectors";
import { UserState } from "lib/redux/user/user.types";
import { ACTIONS, PATHS, retrievePath } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import {
  formatDate,
  formatOptions,
} from "lib/util/DateTimeUtil/formatDate/formatDate";

import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { convertISOStringToCustomDateString } from "lib/util/DateTimeUtil/formatISOStringToCustomDateString/formatISOStringToCustomDateString";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ContactDetails from "../ContactDetails/ContactDetails";
import { initialState, setEpicProfileState } from "../PatientMaster/ducks";
import {
  setBypassEpicProfile,
  setEpicProfileTempState,
  setHasFetchedEpicProfile,
  setOldEpicProfileState,
  setPreferredDateError,
} from "../PatientMaster/ducks/pmSlice";
import {
  selectEpicProfile,
  selectEpicProfileState,
  selectEpicProfileTempState,
  selectOldEpicProfileState,
  selectPMGeneral,
  selectPMGeneralErrors,
  selectPMSettings,
  selectPMSettingsValue,
} from "../PatientMaster/ducks/selectors";
import {
  fetchEpicProfile,
  fetchSystemSettings,
} from "../PatientMaster/ducks/thunks";
import PersonalDetails from "../PersonalDetails/EpicPersonalDetails";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import ProfileImageUploader from "../ProfileImageUploader/ProfileImageUploader";
import ExitProfileDialog from "./ExitProfileDialog/ExitProfileDialog";
import { sxStyles } from "./MyProfile.styles";
import AccordionBlock from "lib/components/accordion/AccordionBlock";
import {
  PatientMasterCreateUpdateProfile,
  PatientMasterProfile,
  PreferredMailingAddPage,
} from "api/patientMaster/patientMasterProfile.types";
import { UserProfile } from "api/shared/UpdateUserProfile/updateUserProfile.types";
import { MessageActions } from "lib/routing/messageChannel/messageActions";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";
import { setMessageToSend } from "lib/redux/navigation/navigationSlice";
import { PM_ERROR_422 } from "api/patientMaster/GetPatientMasterProfile/getPatientMasterProfile";

export const accordionSection1Title = "Personal Details from Myinfo";
export const accordionSection2Title = "Preferred Contact Information";
const MyProfile = ({ error }: { error?: boolean }) => {
  const theme = useTheme();
  const classes = sxStyles(theme);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const path = retrievePath(pathname);

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  // Redux states
  const userState = useAppSelector(selectUser);
  const epicProfileData = useAppSelector(selectEpicProfileState);
  const oldEpicProfileData = useAppSelector(selectOldEpicProfileState);
  const epicProfileState = useAppSelector(selectEpicProfile);
  const {
    isLoading: pmIsLoading,
    hasErrored: pmHasErrored,
    errorMessage: pmErrorMessage,
  } = useAppSelector(selectEpicProfile);
  const checkErrors = useAppSelector(selectPMGeneralErrors);
  const { disclaimers } = useAppSelector(selectPMSettingsValue);
  const { bypassEpicProfile } = useAppSelector(selectPMGeneral);
  const {
    isLoading: pmSettingsIsLoading,
    hasErrored: pmSettingsHasErrored,
    errorMessage: pmSettingsErrorMessage,
  } = useAppSelector(selectPMSettings);
  const uuid = useAppSelector(selectUser)?.myChartUuid || null;

  const PMAStates = useAppSelector(selectEpicProfileTempState);

  // Local states
  const [profileState, setProfileState] = useState<UserState>(userState);
  const [pwrErrorModalPopUp, setpwrErrorModalPopUp] = useState(false);
  const [isExpandAll, setIsExpandAll] = useState<boolean | null>(null);

  const [expandedAccordions, setExpandedAccordions] = useState<string[]>(
    expandAccordionRules(profileState, epicProfileData)
      ? ["block1", "block2"]
      : ["block2"],
  );

  useEffect(() => {
    if (expandAccordionRules(profileState, epicProfileData)) {
      setExpandedAccordions(["block1", "block2"]);
    } else {
      setExpandedAccordions(["block2"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileState.isLoading, epicProfileState.isLoading]);

  const expandedAccordionsMemo = useMemo(() => {
    return expandedAccordions;
  }, [expandedAccordions]);

  // States for the profile update API call
  const [updateIsLoading, setUpdateIsLoading] = useState(false);
  const [openUpdateSuccesfulDialog, setOpenUpdateSuccessfulDialog] =
    useState(false);
  // const [localContactNumber, setLocalContactNumber] = useState<string | null>(
  //   "",
  // );
  // const [localSMSContactNumber, setLocalSMSContactNumber] = useState<
  //   string | null
  // >("");
  // const [localEmail, setLocalEmail] = useState<string | null>("");
  // const [localHomeNumber, setLocalHomeNumber] = useState<string | null>("");
  // const [localWorkNumber, setLocalWorkNumber] = useState<string | null>("");
  const [changeStartDate, setChangeStartDate] = useState(false);

  const resetPreventDirectBackNavigationState = () => {
    consumeMessageActionObj();
    dispatch(setMessageToSend(MessageActions.back("ENABLED")));
  };
  const hasEditedPMA = useMemo(() => {
    if (PMAStates) {
      if (
        PMAStates.PreferredPostalCode !== epicProfileData.PreferredPostalCode ||
        PMAStates.PreferredStreetName !== epicProfileData.PreferredStreetName ||
        PMAStates.PreferredBlockNumber !==
          epicProfileData.PreferredBlockNumber ||
        PMAStates.PreferredCombinedUnitNumber !==
          epicProfileData.PreferredUnitNumber ||
        PMAStates.MailingAddressExpiryDate !==
          epicProfileData.MailingAddressExpiryDate
      ) {
        setChangeStartDate(true);
        return true; //only if PMAState is not undefined and the values are different, return true
      } else {
        setChangeStartDate(false);
        return false;
      }
    } else {
      return false;
    }
  }, [
    PMAStates,
    epicProfileData.MailingAddressExpiryDate,
    epicProfileData.PreferredBlockNumber,
    epicProfileData.PreferredPostalCode,
    epicProfileData.PreferredStreetName,
    epicProfileData.PreferredUnitNumber,
  ]);
  const hasEdited = useMemo(() => {
    if (
      oldEpicProfileData.SMSContactNumber !==
        epicProfileData.SMSContactNumber ||
      oldEpicProfileData.ContactNumber !== epicProfileData.ContactNumber ||
      oldEpicProfileData.Email !== epicProfileData.Email ||
      oldEpicProfileData.HomeNumber !== epicProfileData.HomeNumber ||
      oldEpicProfileData.WorkNumber !== epicProfileData.WorkNumber ||
      userState.photo !== profileState.photo
    ) {
      return true;
    } else {
      return false;
    }
  }, [
    oldEpicProfileData.SMSContactNumber,
    oldEpicProfileData.ContactNumber,
    oldEpicProfileData.Email,
    oldEpicProfileData.HomeNumber,
    oldEpicProfileData.WorkNumber,
    epicProfileData.SMSContactNumber,
    epicProfileData.ContactNumber,
    epicProfileData.Email,
    epicProfileData.HomeNumber,
    epicProfileData.WorkNumber,
    userState.photo,
    profileState.photo,
  ]);

  const disableNext = useMemo(() => {
    if (
      checkErrors.hasEmailErrored ||
      checkErrors.hasHomeNumberErrored ||
      checkErrors.hasMobileErrored ||
      checkErrors.hasSMSMobileErrored ||
      checkErrors.hasWorkNumberErrored ||
      checkErrors.hasPostalCodeErrored ||
      checkErrors.hasBlockErrored ||
      checkErrors.hasStreetErrored ||
      checkErrors.hasDateErrored ||
      !(hasEdited || hasEditedPMA)
    ) {
      return true;
    }
    return false;
  }, [checkErrors, hasEdited, hasEditedPMA]);

  const updateProfilePayload = (
    hasEditedPMA: boolean,
    PMAStates?: PreferredMailingAddPage,
  ) => {
    var PMpayload: PatientMasterCreateUpdateProfile | null = null;
    var Userpayload: UserProfile | null = null;
    if (hasEditedPMA && PMAStates) {
      PMpayload = {
        Name: epicProfileData.Name ? epicProfileData.Name : null,
        Title: epicProfileData.Title ? epicProfileData.Title : null,
        Sex: epicProfileData.Sex ? epicProfileData.Sex : null,
        DateOfBirth: convertISOStringToCustomDateString(
          epicProfileData.DateOfBirth,
        )
          ? convertISOStringToCustomDateString(epicProfileData.DateOfBirth)
          : null,
        Nationality: epicProfileData.Nationality
          ? epicProfileData.Nationality
          : null,
        NationalityCode: epicProfileData.NationalityCode
          ? epicProfileData.NationalityCode
          : null,
        Race: epicProfileData.Race ? epicProfileData.Race : null,
        RaceCode: epicProfileData.RaceCode ? epicProfileData.RaceCode : null,
        DocumentTypeCode: epicProfileData.DocumentTypeCode
          ? epicProfileData.DocumentTypeCode
          : null,
        DocumentTypeDisplay: epicProfileData.DocumentTypeDisplay
          ? epicProfileData.DocumentTypeDisplay
          : null,
        DocumentTypeExpiry: convertISOStringToCustomDateString(
          epicProfileData.DocumentTypeExpiry,
        )
          ? convertISOStringToCustomDateString(
              epicProfileData.DocumentTypeExpiry,
            )
          : null,
        RegisteredPostalCode: epicProfileData.RegisteredPostalCode
          ? epicProfileData.RegisteredPostalCode
          : "",
        RegisteredStreetName: epicProfileData.RegisteredStreetName
          ? epicProfileData.RegisteredStreetName
          : "",
        RegisteredBlockNumber: epicProfileData.RegisteredBlockNumber
          ? epicProfileData.RegisteredBlockNumber
          : "",
        RegisteredFloorNumber: epicProfileData.RegisteredUnitNumber
          ? epicProfileData.RegisteredUnitNumber?.split("-")[0]
              .trim()
              .replaceAll("#", "")
          : "",
        RegisteredUnitNumber: epicProfileData.RegisteredUnitNumber
          ? epicProfileData.RegisteredUnitNumber.split("-")[1]?.trim()
          : "",
        PreferredPostalCode: PMAStates.PreferredPostalCode,
        PreferredStreetName: PMAStates.PreferredStreetName,
        PreferredBlockNumber: PMAStates.PreferredBlockNumber,
        PreferredFloorNumber: PMAStates.PreferredFloorNumber,
        PreferredUnitNumber: PMAStates.PreferredUnitNumber,
        MailingAddressStartDate: !PMAStates.PreferredPostalCode
          ? null
          : !changeStartDate
            ? epicProfileData.MailingAddressStartDate
              ? convertISOStringToCustomDateString(
                  epicProfileData.MailingAddressStartDate,
                )
              : convertISOStringToCustomDateString(formatDateToISO(new Date()))
            : convertISOStringToCustomDateString(formatDateToISO(new Date())),
        MailingAddressExpiryDate: convertISOStringToCustomDateString(
          PMAStates.MailingAddressExpiryDate,
        )
          ? convertISOStringToCustomDateString(
              PMAStates.MailingAddressExpiryDate,
            )
          : null,
        ContactNumber: epicProfileData.ContactNumber
          ? epicProfileData.ContactNumber
          : "",
        Email: epicProfileData.Email ? epicProfileData.Email : "",
        HomeNumber: epicProfileData.HomeNumber
          ? epicProfileData.HomeNumber
          : "",
        WorkNumber: epicProfileData.WorkNumber
          ? epicProfileData.WorkNumber
          : "",
        SMSNumber: epicProfileData.SMSContactNumber
          ? epicProfileData.SMSContactNumber
          : "",
      };
      Userpayload = {
        Name: epicProfileData.Name,
        Nric: epicProfileData.Nric,
        DateOfBirth: epicProfileData.DateOfBirth,
        ContactNumber: epicProfileData.ContactNumber,
        Email: epicProfileData.Email,
        Photo: profileState.photo,
        BuildingName: profileState.buildingName,
        RegisteredAddress: {
          PostalCode: epicProfileData.RegisteredPostalCode,
          Block: epicProfileData.RegisteredBlockNumber,
          Street: epicProfileData.RegisteredStreetName,
          Unit: epicProfileData.RegisteredUnitNumber,
        },
        PreferredAddress: {
          PostalCode: PMAStates.PreferredPostalCode,
          Block: PMAStates.PreferredBlockNumber,
          Street: PMAStates.PreferredStreetName,
          Unit: PMAStates.PreferredCombinedUnitNumber,
        },
        HasSyncedParticulars: profileState.hasSyncedParticulars,
        HasSubscribed: true,
        UsePreferredAddress: profileState.usePreferredAddress,
        MyInfoCodeVerifier: null,
      };
    } else {
      PMpayload = {
        Name: epicProfileData.Name ? epicProfileData.Name : null,
        Title: epicProfileData.Title ? epicProfileData.Title : null,
        Sex: epicProfileData.Sex ? epicProfileData.Sex : null,
        DateOfBirth: convertISOStringToCustomDateString(
          epicProfileData.DateOfBirth,
        )
          ? convertISOStringToCustomDateString(epicProfileData.DateOfBirth)
          : null,
        Nationality: epicProfileData.Nationality
          ? epicProfileData.Nationality
          : null,
        NationalityCode: epicProfileData.NationalityCode
          ? epicProfileData.NationalityCode
          : null,
        Race: epicProfileData.Race ? epicProfileData.Race : null,
        RaceCode: epicProfileData.RaceCode ? epicProfileData.RaceCode : null,
        DocumentTypeCode: epicProfileData.DocumentTypeCode
          ? epicProfileData.DocumentTypeCode
          : null,
        DocumentTypeDisplay: epicProfileData.DocumentTypeDisplay
          ? epicProfileData.DocumentTypeDisplay
          : null,
        DocumentTypeExpiry: convertISOStringToCustomDateString(
          epicProfileData.DocumentTypeExpiry,
        )
          ? convertISOStringToCustomDateString(
              epicProfileData.DocumentTypeExpiry,
            )
          : null,
        RegisteredPostalCode: epicProfileData.RegisteredPostalCode
          ? epicProfileData.RegisteredPostalCode
          : "",
        RegisteredStreetName: epicProfileData.RegisteredStreetName
          ? epicProfileData.RegisteredStreetName
          : "",
        RegisteredBlockNumber: epicProfileData.RegisteredBlockNumber
          ? epicProfileData.RegisteredBlockNumber
          : "",
        RegisteredFloorNumber: epicProfileData.RegisteredUnitNumber
          ? epicProfileData.RegisteredUnitNumber?.split("-")[0]
              .trim()
              .replaceAll("#", "")
          : "",
        RegisteredUnitNumber: epicProfileData.RegisteredUnitNumber
          ? epicProfileData.RegisteredUnitNumber.split("-")[1]?.trim()
          : "",
        PreferredPostalCode: epicProfileData.PreferredPostalCode
          ? epicProfileData.PreferredPostalCode
          : "",
        PreferredStreetName: epicProfileData.PreferredStreetName
          ? epicProfileData.PreferredStreetName
          : "",
        PreferredBlockNumber: epicProfileData.PreferredBlockNumber
          ? epicProfileData.PreferredBlockNumber
          : "",
        PreferredFloorNumber: epicProfileData.PreferredUnitNumber
          ? epicProfileData.PreferredUnitNumber?.split("-")[0]
              .trim()
              .replaceAll("#", "")
          : "",
        PreferredUnitNumber: epicProfileData.PreferredUnitNumber
          ? epicProfileData.PreferredUnitNumber?.split("-")[1]?.trim()
          : "",
        MailingAddressStartDate: !epicProfileData.PreferredPostalCode
          ? null
          : !changeStartDate
            ? epicProfileData.MailingAddressStartDate
              ? convertISOStringToCustomDateString(
                  epicProfileData.MailingAddressStartDate,
                )
              : convertISOStringToCustomDateString(formatDateToISO(new Date()))
            : convertISOStringToCustomDateString(formatDateToISO(new Date())),
        MailingAddressExpiryDate: convertISOStringToCustomDateString(
          epicProfileData.MailingAddressExpiryDate,
        )
          ? convertISOStringToCustomDateString(
              epicProfileData.MailingAddressExpiryDate,
            )
          : null,
        ContactNumber: epicProfileData.ContactNumber
          ? epicProfileData.ContactNumber
          : "",
        Email: epicProfileData.Email ? epicProfileData.Email : "",
        HomeNumber: epicProfileData.HomeNumber
          ? epicProfileData.HomeNumber
          : "",
        WorkNumber: epicProfileData.WorkNumber
          ? epicProfileData.WorkNumber
          : "",
        SMSNumber: epicProfileData.SMSContactNumber
          ? epicProfileData.SMSContactNumber
          : "",
      };
      Userpayload = {
        Name: epicProfileData.Name,
        Nric: epicProfileData.Nric,
        DateOfBirth: epicProfileData.DateOfBirth,
        ContactNumber: epicProfileData.ContactNumber,
        Email: epicProfileData.Email,
        Photo: profileState.photo,
        BuildingName: profileState.buildingName,
        RegisteredAddress: {
          PostalCode: epicProfileData.RegisteredPostalCode,
          Block: epicProfileData.RegisteredBlockNumber,
          Street: epicProfileData.RegisteredStreetName,
          Unit: epicProfileData.RegisteredUnitNumber,
        },
        PreferredAddress: {
          PostalCode: epicProfileData.PreferredPostalCode,
          Block: epicProfileData.PreferredBlockNumber,
          Street: epicProfileData.PreferredStreetName,
          Unit: epicProfileData.PreferredUnitNumber,
        },
        HasSyncedParticulars: profileState.hasSyncedParticulars,
        HasSubscribed: true,
        UsePreferredAddress: profileState.usePreferredAddress,
        MyInfoCodeVerifier: null,
      };
    }
    return { PMpayload, Userpayload };
  };

  const updateProfile = async () => {
    setUpdateIsLoading(true);
    dispatch(setBypassEpicProfile(false));
    const payloads = updateProfilePayload(hasEditedPMA, PMAStates);
    try {
      await updatePatientMasterProfile(payloads.PMpayload, false);

      try {
        await updateUserProfile(payloads.Userpayload);
      } catch (error) {}

      mobileNavigate(ACTIONS.PROFILE_UPDATE_ACTION);
      // !exp setTimeout is needed when we are sending multiple actions to mobile for interception
      // !exp opening the confirmation modal sends an action by default to disable back button navigation
      setTimeout(() => setOpenUpdateSuccessfulDialog(true), 1000);
    } catch (error) {
      dispatch(
        handleErrorModal({
          open: true,
          message:
            "We are unable to update your particulars. You may wish to try again later.",
        }),
      );
    } finally {
      dispatch(
        setOldEpicProfileState({
          ...payloads.PMpayload,
          Nric: epicProfileData.Nric,
          SMSContactNumber: epicProfileData.SMSContactNumber,
          MismatchStatus: epicProfileData.MismatchStatus,
          PwrFlag: epicProfileData.PwrFlag,
          UserId: epicProfileData.UserId,
        }),
      );
      setUpdateIsLoading(false);
    }
  };

  // handlers
  const handleOnClickExpandAll = () => {
    setIsExpandAll(!isExpandAll);
  };
  const handleProfileImageOnUpload = (fileData: string | null) => {
    setProfileState({ ...profileState, photo: fileData });
  };
  const handleResetChange = (isForExpiredDate: boolean) => {
    dispatch(
      setEpicProfileState({
        ...epicProfileData,
        PreferredStreetName: "",
        PreferredBlockNumber: "",
        PreferredUnitNumber: "",
        MailingAddressExpiryDate: "",
        ...(isForExpiredDate ? { PreferredPostalCode: "" } : {}),
      }),
    );

    dispatch(setPreferredDateError(false));

    setProfileState({
      ...profileState,
      unitNo: "",
      block: "",
      streetAddress: "",
    });
  };

  const handleContactChange = (contact: string | null) => {
    setProfileState({ ...profileState, contact });

    dispatch(
      setEpicProfileState({
        ...epicProfileData,
        ContactNumber: contact,
      }),
    );
  };

  const handleSMSContactChange = (smsContact: string | null) => {
    dispatch(
      setEpicProfileState({
        ...epicProfileData,
        SMSContactNumber: smsContact,
      }),
    );
  };

  const handleEmailChange = (email: string | null) => {
    setProfileState({ ...profileState, email });

    dispatch(
      setEpicProfileState({
        ...epicProfileData,
        Email: email,
      }),
    );
  };

  const handleHomeNumberChange = (homeNumber: string | null) => {
    dispatch(
      setEpicProfileState({
        ...epicProfileData,
        HomeNumber: homeNumber,
      }),
    );
  };

  const handleWorkNumberChange = (workNumber: string | null) => {
    dispatch(
      setEpicProfileState({
        ...epicProfileData,
        WorkNumber: workNumber,
      }),
    );
  };

  // This handler is used on both buttons footer and syncParticulars modal
  const handleCancelButtonClick = () => {
    dispatch(setEpicProfileTempState(undefined));
    dispatch(setOldEpicProfileState(initialState.epicProfile.oldValue));
    dispatch(setBypassEpicProfile(false));
    mobileNavigate(ACTIONS.PM_SYNC_DO_IT_LATER);
  };

  const handleSyncYourParticularsNextClick = () => {
    logEventToGoogleAnalytics(EVENTS.SELECT_PM_SYNC_PARTICULARS_PROCEED);

    dispatch(setHasFetchedEpicProfile(false));
    dispatch(setFromInitialProfile(location.pathname));
    dispatch(
      setShouldDirectToMyInfo({
        shouldDirectToMyInfo: true,
        cancellationPath: location.pathname,
      }),
    );

    navigate(PATHS.MY_INFOPROFILE.path);
  };

  useEffect(() => {
    if (bypassEpicProfile) {
      return;
    }

    if (
      path?.path === PATHS.MY_PROFILE_MOBILE.path ||
      path?.path === PATHS.MY_PROFILE_MOBILE_ERROR.path ||
      path?.path === PATHS.MY_PROFILE.path
    ) {
      dispatch(fetchSystemSettings()); //retrieve tnc, disclaimers, error messages etc
      dispatch(
        fetchUserParticulars({ mapAddress: false, retrievePhoto: true }),
      );
      dispatch(setProfileAccessEntryPoint("SYSTEM AND PROFILE LISTING"));
      dispatch(
        fetchEpicProfile({
          myInfoName: null,
          myInfoDoB: null,
          myInfoSex: null,
          uuid,
          codeVerifier: null,
        }),
      );
    } else {
      dispatch(
        fetchEpicProfile({
          myInfoName: null,
          myInfoDoB: null,
          myInfoSex: null,
          uuid,
          codeVerifier: null,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (messageActionObj?.action === "backTriggered") {
      dispatch(setHasFetchedEpicProfile(false));
      resetPreventDirectBackNavigationState();

      mobileNavigate(ACTIONS.PM_SYNC_DO_IT_LATER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageActionObj?.action]);

  useEffect(() => {
    setProfileState(userState);
  }, [userState]);

  useEffect(() => {
    if (
      epicProfileState.hasFetchedEpicProfile &&
      !epicProfileState.hasErrored &&
      !userState.isLoading
    ) {
      // setLocalContactNumber(epicProfileData.ContactNumber);
      // setLocalSMSContactNumber(epicProfileData.SMSContactNumber);
      // setLocalEmail(epicProfileData.Email);
      // setLocalHomeNumber(epicProfileData.HomeNumber);
      // setLocalWorkNumber(epicProfileData.WorkNumber);
      setpwrErrorModalPopUp(epicProfileData.PwrFlag === true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    epicProfileState.hasFetchedEpicProfile,
    epicProfileState.hasErrored,
    userState.isLoading,
  ]);

  useEffect(() => {
    if (
      epicProfileData.MailingAddressExpiryDate &&
      formatDate(formatDateToISO(new Date()), "yyyy-MM-dd") >
        formatDate(
          formatDateToISO(new Date(epicProfileData.MailingAddressExpiryDate)),
          "yyyy-MM-dd",
        )
    ) {
      handleResetChange(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [epicProfileData.MailingAddressExpiryDate]);

  useEffect(() => {
    if (
      expandedAccordionsMemo.includes("block1") &&
      expandedAccordionsMemo.includes("block2")
    ) {
      setIsExpandAll(true);
    } else if (expandedAccordionsMemo.length === 0) {
      setIsExpandAll(false);
    }
  }, [expandedAccordionsMemo]);

  useEffect(() => {
    if (isExpandAll === true) {
      setExpandedAccordions(["block1", "block2"]);
    } else if (isExpandAll === false) {
      setExpandedAccordions([]);
    }
  }, [isExpandAll]);

  return (
    <>
      {userState.isLoading ||
      updateIsLoading ||
      pmIsLoading ||
      pmSettingsIsLoading ? (
        <Box display="flex" align-items="center" justifyContent="center" pt={4}>
          <CircularProgress />
        </Box>
      ) : pmSettingsHasErrored ? (
        <ErrorDisplay
          errorMessage={pmSettingsErrorMessage}
          onTryAgain={() => dispatch(fetchSystemSettings())}
        />
      ) : userState.hasErrored ? (
        <ErrorDisplay
          errorMessage={userState.errorMessage}
          onTryAgain={() =>
            dispatch(
              fetchUserParticulars({ mapAddress: false, retrievePhoto: true }),
            )
          }
        />
      ) : pmHasErrored ? (
        <ErrorDisplay
          errorMessage={pmErrorMessage}
          onTryAgain={
            pmErrorMessage?.includes(PM_ERROR_422)
              ? undefined
              : () =>
                  dispatch(
                    fetchEpicProfile({
                      myInfoName: null,
                      myInfoDoB: null,
                      myInfoSex: null,
                      uuid,
                      codeVerifier: null,
                    }),
                  )
          }
        />
      ) : (
        !userState.hasErrored &&
        !pmHasErrored &&
        !pmSettingsHasErrored && (
          <Box sx={classes.mainContainer}>
            <Box sx={classes.contenContainer}>
              <ProfileImageUploader
                photo={profileState.photo}
                onUpload={handleProfileImageOnUpload}
              />

              <Box sx={classes.expandAllButtonContainer}>
                <Button
                  variant="text"
                  sx={classes.expandAllButton}
                  onClick={handleOnClickExpandAll}
                >
                  {isExpandAll ? "Collapse all" : "Expand all"}
                </Button>
              </Box>

              <AccordionBlock
                expanded={expandedAccordionsMemo.includes("block1")}
                onExpand={() => {
                  if (expandedAccordionsMemo.includes("block1")) {
                    setExpandedAccordions(
                      expandedAccordionsMemo.filter(
                        (block) => block !== "block1",
                      ),
                    );
                  } else {
                    setExpandedAccordions([
                      ...expandedAccordionsMemo,
                      "block1",
                    ]);
                  }
                }}
                summaryNode={
                  <Box sx={classes.summaryContainer}>
                    <Typography sx={classes.summaryTitle}>
                      {accordionSection1Title}
                    </Typography>
                    {profileState.lastRetrievedMyInfoProfileOn?.trim() &&
                      !profileState.isMismatched && //if they are not mismatched, show last sync on
                      (epicProfileData.MismatchStatus === null ||
                        epicProfileData.MismatchStatus === 0) && (
                        <Typography sx={classes.summaryValue}>
                          {`Last synced on ${formatDate(
                            profileState.lastRetrievedMyInfoProfileOn,
                            formatOptions.myInfoRetrieved,
                          )}.`}
                        </Typography>
                      )}
                  </Box>
                }
                detailsNode={
                  <PersonalDetails
                    showUnsyncParticularsWarning={
                      !userState.hasSyncedParticulars
                    }
                  />
                }
              />

              <AccordionBlock
                expanded={expandedAccordionsMemo.includes("block2")}
                onExpand={() => {
                  if (expandedAccordionsMemo.includes("block2")) {
                    setExpandedAccordions(
                      expandedAccordionsMemo.filter(
                        (block) => block !== "block2",
                      ),
                    );
                  } else {
                    setExpandedAccordions([
                      ...expandedAccordionsMemo,
                      "block2",
                    ]);
                  }
                }}
                summaryNode={
                  <Box sx={classes.summaryContainer}>
                    <Typography sx={classes.summaryTitle}>
                      {accordionSection2Title}
                    </Typography>
                    <Typography sx={classes.summaryValue}>
                      Update email address, phone numbers and preferred local
                      mailing address.
                    </Typography>
                  </Box>
                }
                detailsNode={
                  <>
                    <ContactDetails
                      contactDisclaimer={
                        epicProfileState.hasEpicProfile ? (
                          <>
                            Tap 'Add' if you prefer to receive{" "}
                            <Typography
                              component={"span"}
                              sx={{
                                fontWeight: theme.typography.fontWeightBold,
                              }}
                            >
                              {" "}
                              Appointment SMS reminders{" "}
                            </Typography>{" "}
                            through a different number.
                          </>
                        ) : null
                      }
                      contact={epicProfileData.ContactNumber ?? ""}
                      smsContact={epicProfileData.SMSContactNumber ?? ""}
                      email={epicProfileData.Email ?? ""}
                      homeNumber={epicProfileData.HomeNumber ?? ""}
                      workNumber={epicProfileData.WorkNumber ?? ""}
                      hasEpicProfile={epicProfileState.hasEpicProfile}
                      hasEditedPMA={hasEditedPMA}
                      PMAStates={PMAStates}
                      handleContactChange={handleContactChange}
                      handleSMSContactChange={handleSMSContactChange}
                      handleEmailChange={handleEmailChange}
                      handleHomeNumberChange={handleHomeNumberChange}
                      handleWorkNumberChange={handleWorkNumberChange}
                    />
                  </>
                }
              />
            </Box>

            <Box sx={classes.buttonsFooterContainer}>
              <ButtonsFooter
                nextButtonText={"Confirm and save"}
                cancelButtonText="Cancel"
                isDisabled={disableNext}
                onClickNext={updateProfile}
                onClickCancel={handleCancelButtonClick}
              />
            </Box>

            {/* "Sync Your Particulars" modal */}
            <ConfirmationModal
              title={disclaimers.syncYourParticulars.title}
              body={MapRawStringToReactElement(
                disclaimers.syncYourParticulars.description ?? "",
              )}
              open={
                !epicProfileState.hasEpicProfile &&
                epicProfileState.hasFetchedEpicProfile
              }
              nextButtonText={"Proceed"}
              onClickNext={handleSyncYourParticularsNextClick}
              cancelButtonText={"No"}
              onClickCancel={handleCancelButtonClick}
            />

            {/* PWRFlag is true */}
            <ConfirmationModal
              title={"Oops..."}
              hideCancelButton={true}
              open={pwrErrorModalPopUp}
              nextButtonText={"OK"}
              body={
                "There seems to be an issue syncing your information from Myinfo. To update your details, please approach our patient service associate with your identification documents during your next visit."
              }
              onClickNext={() => {
                logEventToGoogleAnalytics(
                  EVENTS.SELECT_PM_VERIFY_PARTICULARS_OK_UNABLE_UPDATE,
                );
                setpwrErrorModalPopUp(false);
                dispatch(setHasFetchedEpicProfile(false));
                mobileNavigate(ACTIONS.PM_SYNC_DO_IT_LATER);
              }}
              onClose={() => {
                setpwrErrorModalPopUp(false);
              }}
            />

            {/* "Success" modal */}
            <ExitProfileDialog open={openUpdateSuccesfulDialog} />
          </Box>
        )
      )}
    </>
  );
};

export default MyProfile;

// Helper functions
export const expandAccordionRules = (
  profileState: UserState,
  epicProfileData: PatientMasterProfile,
) => {
  //check if user has mismatch status
  if (
    (epicProfileData.MismatchStatus !== null &&
      epicProfileData.MismatchStatus !== 0) ||
    profileState.isMismatched
  ) {
    return false;
  } else {
    //check if they have synced particulars
    if (!profileState.hasSyncedParticulars) {
      return true;
    } else {
      //check if last sync date is >6 mths
      const currentDate = new Date();
      const dateSixMonthsAgo = new Date(
        currentDate.setMonth(currentDate.getMonth() - 6),
      );
      return (
        new Date(profileState.lastRetrievedMyInfoProfileOn ?? "") <
        dateSixMonthsAgo
      );
    }
  }
};
