const isNUP = (institutionCode: string | null, logoCode: string | null) =>
  logoCode === "NUP" ||
  institutionCode === "BBK" ||
  institutionCode === "BPJ" ||
  institutionCode === "PIO" ||
  institutionCode === "CCK" ||
  institutionCode === "CLM" ||
  institutionCode === "QTP" ||
  institutionCode === "JUR";

export { isNUP };
