import { color } from "lib/theme/color";
import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    header: {
      backgroundColor: theme.palette.secondary.main,
      marginBottom: 2,
      "&.MuiCardHeader-root": { padding: theme.spacing(1.5, 2) },
      "& .MuiCardHeader-content": {
        typography: theme.typography.body1_semibold,
        color: theme.palette.grey[400],
      },
    },

    barcodeImgBox: {
      objectFit: "contain",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    barcodeImg: {
      width: "100%",
    },
    barcodeText: {
      textAlign: "center",
      typography: theme.typography.body2_regular,
    },
    journeyDetailBox: {
      margin: theme.spacing(1, 2),
    },

    borderBox: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(1, 1, 0),
      backgroundColor: theme.palette.secondary.main,
      boxShadow: "0px 0px 4px " + color.RGBA_0,
      borderRadius: theme.spacing(0.5),
      position: "relative",
    },
    importantNoticeBox: {
      margin: 2,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    errorText: {
      color: theme.palette.error.main,
      typography: theme.typography.body2_regular,
      padding: theme.spacing(0, 2),
    },

    infoBox: {
      background: theme.palette.info.light,
      padding: theme.spacing(1),
      display: "flex",
      lineHeight: theme.typography.body1.lineHeight,
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.error.dark}`,
    },
    infoText: {
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_12,
      padding: theme.spacing(0.1),
    },
    infoIcon: {
      margin: theme.spacing(0.2),
    },
    basicContainer: {
      display: "flex",
      alignItems: "center",
      "& div": {
        width: "33.33%",
        textAlign: "center",
        margin: theme.spacing("auto", 1),
      },
    },
    basicLabel: {
      margin: "16px auto",
    },

    line: {
      borderTop: `2px solid ${theme.palette.grey[100]}`,
      borderRadius: theme.spacing(1.25),
    },

    refreshIconButton: {
      display: "block",
      marginLeft: "auto",
      padding: theme.spacing(1, 4, 0, 0),
      color: theme.palette.primary.main,
    },
    refreshIcon: { height: "24px" },
    tutorialContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 2,
    },
    tutorialImage: {
      width: "200px",
      marginBottom: 1,
    },
    tutorialTitle: {
      typography: theme.typography.header4_semibold,
      textAlign: "center",
      marginBottom: 1,
    },
    tutorialBody: {
      typography: theme.typography.body2_regular,
      textAlign: "center",
      mx: 4,
    },
  } satisfies SxObjType;
};

export { sxStyles };
