import { AppointmentsState } from "./appointments.types";

// Initial state representing the time when appointments are not initialized yet
export const initialState: AppointmentsState = {
  init: {
    lookup: {
      // === A dictionary of all Appointments ===
      // This schema makes it easy to perform lookup operations, e.g. update / delete.
    },
    listing: {
      // === A collection of all Appointments (de-normalized from appointment lookup) ===
      // A collection containing lists of each type of appointment
      upcoming: null,
      missed: null,
      open: null,
      actualised: null,
    },
    isLoading: false, // True if the fetch is still ongoing, false if it has stopped
    hasErrored: false, // True if the fetch threw an error, false otherwise
    errorMessage: null, // Error message to to displayed, if the fetch threw an error
  },
  create: {
    // === For the Make an Appointment Flow ===
    // the service which was selected
    selectedService: null,

    selectedReferral: null,

    selectedPractitioner: null,

    // the institution which was selected
    selectedInstitution: null,

    // the institution code which was selected
    selectedInstitutionCode: null,

    // the specialty which was selected
    selectedSpecialty: null,

    // set this flag to true if user answers triage questions with 'yes' option, this flag will be sent to getAvailableSlots API
    isUrti: false,

    // the last visit appointment date time (for open apointment)
    lastVisitDateTime: null,

    // the extensionData of the available slot which was selected
    selectedAvailableSlotExtensionData: null,

    // the ID of the available slot which was selected
    selectedAvailableSlotId: "",

    // the DateTime of the available slot which was selected
    selectedAvailableSlotDateTime: null,
    isCreateFromAppointmentLanding: false,

    createLinkedAppointment: {
      visitedSlotsPage: false,
      selectedLinkedAppointment: null,
      selectedIndex: null,
      newSlotList: [],
    },
  },
  reschedule: {
    // === For the Reschedule an Appointment Flow ===
    // the ID of the appointment which was selected
    selectedAppointmentId: null,

    // the institution which was selected
    selectedInstitution: null,

    // the service which was selected
    selectedService: null,

    // the CURRENT appointment's datetime (from user's appointment list)
    selectedDateTime: null,

    // the ID of the available slot which was selected
    selectedAvailableSlotId: null,

    // the TO BE RE-SCHEDULED available slot datetime which was selected
    selectedAvailableSlotDateTime: null,

    // the TO BE RE-SCHEDULED available slot extensionData which was selected
    selectedAvailableSlotExtensionData: null,

    selectedDepartmentName: null,
  },
  rescheduleLinkedAppointment: {
    visitedSlotsPage: false,
    selectedLinkedAppointment: null,
    selectedIndex: null,
    newSlotList: [],
    isPreConfirmationModalOpen: true,
  },
  register: {
    // === For the Register an Appointment Flow ===
    // ID of the appointment to make a registration for
  },
  appointmentMeta: {
    // === Common metadata attributes, for all Appointment Flows ===
    // TargetSystem (source system) of:
    //   1.) selected institution (relevant for create flow)
    //   2.) selected appointment (relevant for re-schedule flow)
    // remapped from SourceSystem
    targetSystem: null,

    // InstitutionCode of:
    //   1.) selected institution (relevant for create flow)
    //   2.) selected appointment (relevant for re-schedule flow)
    institutionCode: null,

    // InstitutionCode of:
    //   1.) selected institution (relevant for registration flow)
    regInstitutionCode: null,

    // Institution Name of:
    //   1.) selected institution (relevant for registration flow)
    institutionName: null,

    // Consult Type of:
    //   1.) selected appointment (relevant for registration flow)
    consultType: null,

    // DepartmentCode of:
    //   1.) selected service (relevant for create flow)
    //   2.) selected appointment (relevant for re-schedule flow)
    departmentCode: null,

    // DepartmentName of:
    //   1.) selected service (relevant for create flow)
    //   2.) selected appointment (relevant for re-schedule flow)
    departmentName: null,

    // LogoCode of:
    //   1.) selected appointment (relevant for re-schedule flow)
    logoCode: null,

    slotId: null,

    // ClinicCode of:
    //   1.) selected institution (relevant for create flow)
    //   2.) selected appointment (relevant for re-schedule flow)
    clinicCode: null,

    // Service Type ID of:
    //   1.) selected service (relevant for create flow)
    //   2.) selected appointment's service (relevant for re-schedule flow)
    visitTypeId: null,

    // Whether same-day booking or in-advance bookings are allowed.
    // Whether this service allows for only a same day booking and if whether or not the
    // available slots datepicker is to be used to select a slot for a same-day booking or in-advance booking.
    isSameDayBooking: false,

    // Slot Search Dates for Rescheduling:
    // The slot search dates here represent the dates for which you may reschedule this appointment.
    slotSearchDateRangeFrom: null,
    slotSearchDateRangeTo: null,

    expectedOrderDate: null,

    // flag indicating whether it is virtual consult appointment
    // currently it is required to be stored in redux for actualisation API integration
    isVirtualConsultAppointment: false,

    appointmentType: null,
    appointmentId: null,

    location: null,
    actualizeStatus: null,

    calendarLocation: null,
    calendarTitle: null,
    zoneInfo: null,
    visitType: null,
    requireCheckIn: false,
    ihaveArrivedMessage: null,
    datetimeString: null,
    linkedAppointments: [],
    vcSupportStatus: null,
    regAppointmentId: null,
    exceedRescheduleLimit: false,
    isHsgAppt: false,
    HSG: {
      isHsgAppt: false,
      isHpCompleted: null,
      subsequentStartDate: null,
      subsequentEndDate: null,
    },
    centerContactNo: null,
  },
  mammogram: {
    questions: [],
    mammogramQuestionIsLoading: false,
    mammogramQuestionHasErrored: null,
    mammogramQuestionErrorMessage: null,
  },
  triage: {
    // === For the Consultation Triage Question Flow ===
    isLoading: false,
    hasErrored: false,
    errorMessage: null,
    questionList: [],
  },
  systemSettings: {
    isLoading: false,
    hasErrored: false,
    errorMessage: null,
    value: null,
    values: {},
  },
  documentByType: {
    isLoading: null,
    hasErrored: false,
    errorMessage: null,
    documentByTypeMap: {},
  },
  announcements: {
    isLoading: false,
    hasErrored: false,
    errorMessage: null,
    announcementMsg: null,
    hasAnnouncement: false,
  },
  persist: {},
  checkInSuccess: {
    checkInSuccessStatus: false,
    checkInSuccessTitleIcon: null,
    title: null,
    message: null,
  },
  popHealthStatus: {
    isEnroled: null,
    enrolmentDate: null,
    hciCode: null,
    isHpCompleted: null,
    subsequentStartDate: null,
    subsequentEndDate: null,
    healthPlanCompletionDate: null,
    popOutMessage: null,
  },

  teleconsultMessage: null,
  hasPromptedRegTutorial: false,
};

export const generateDefaultRegisteredAppt = () => {
  return {
    queueNumber: null,
    currentStation: null,
    currentAppointmentTime: null,
    queueStatus: null,
    statusMessage: null,
    patientWaiting: null,
    journeyList: null,
    isLoading: false,
    hasErrored: null,
    errorMessage: null,
    showStatusText: false,
    showPatientWaiting: false,
    institutionName: null,
    consultType: null,
    canProceedToVirtualConsult: false,
    encounterAction: [],
    hintMsg: null,
    requireCheckIn: false,
    ihaveArrivedMessage: null,
    currentClinicCode: null,
    meetingId: null,
    meetingIdFormat: null,
    passcode: null,
    lateThresholdMessage: null,
    appointmentTimeToArrive: null,
    encounterId: null,
    vcSupportStatus: null,
    logoCode: null,
  };
};
