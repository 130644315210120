import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    receiptLayout: {
      margin: theme.spacing(2),
    },
    checkStatusBox: {
      marginTop: "35%",
    },

    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.body2_semibold,
      marginTop: theme.spacing(1),
    },
    summaryCard: {
      borderBottom: "1px solid " + theme.palette.secondary.light,
    },
    cardRight: {
      justifyContent: "center",
      display: "flex",
      marginTop: theme.spacing(2),
      minWidth: 100,
    },
  } satisfies SxObjType;
};

export { sxStyles };
