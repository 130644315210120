import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Link } from "@mui/material";
import { ACTIONS, PATHS } from "lib/routing";
import Page from "lib/components/layout/Page/Page";
import SelectionInstructions from "../../../../common/selection-listing/SelectionInstructions/SelectionInstructions";
import SelectionList from "../../../../common/selection-listing/SelectionList/SelectionList";
import ServicesPageHeader from "../ServicesPageHeader/ServicesPageHeader";
import TriageQuestionPromptModal from "../TriageQuestionPromptModal/TriageQuestionPromptModal";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import { useGetServiceList } from "ui/appointment/hooks/useGetServiceList";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { QuestionResponse } from "api/appointment/GetTriageQuestionnaire/getTriageQuestionnaire.fromApi.types";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { renderErrorMessage } from "lib/components/error/ErrorDisplay/renderErrorMessage";
import MapRawStringtoReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
// Hooks
import { useAppDispatch } from "lib/redux/hooks";
import { useAppSelector } from "lib/redux/hooks";
import { selectMemberIdentifier, selectUser } from "lib/redux/user/selectors";
import { useGetPopHealthandAppointmentStatus } from "ui/appointment/hooks/useGetPopHealthandAppointmentStatus";
import {
  selectAppointmentDocByType,
  selectAppointmentMammogram,
  selectAppointmentMeta,
  selectAppointmentTriage,
  selectAppointments,
} from "ui/appointment/ducks/selectors";
import {
  fetchMammogramQuestionnaire,
  fetchDocumentForAllTypes,
} from "ui/appointment/ducks/thunks";
import {
  updateServiceForCreate,
  updateAppointmentMetaFromService,
  fetchTriageQuestionnaire,
  updateLastVisitDateTimeForCreate,
  updateIsUrtiForCreate,
  setPopHealthStatus,
} from "ui/appointment/ducks";
import { ErrorModalTitles } from "lib/components/navigation/AppRouteRenderer/ErrorModal/HelperFunctions";
import {
  mobileNavigate,
  navigateToExternalInMobileBrowser,
} from "lib/routing/navigate/navigate";
import { mammogramUrl } from "lib/configs/configs/config";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { Service } from "api/appointment/GetServiceList/getServiceList.toUi.types";
import { useGetLiveChatFlag } from "ui/appointment/hooks/useGetLiveChatFlag";

const ServicesPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const MAMMOGRAM_SURVERY_URL = mammogramUrl();

  //Redux states
  const { relationType } = useAppSelector(selectUser);
  const memberIdentifier = useAppSelector(selectMemberIdentifier);
  const location =
    useAppSelector(selectAppointments)?.create?.selectedInstitution || "";
  const { institutionCode, clinicCode, targetSystem } = useAppSelector(
    selectAppointmentMeta,
  );
  const {
    isLoading: isLoadingTriage,
    hasErrored: hasErroredTriage,
    errorMessage: errorMessageTriage,
    questionList: triageQuestionList,
  } = useAppSelector(selectAppointmentTriage);
  const {
    mammogramQuestionIsLoading: isLoadingMammogram,
    mammogramQuestionHasErrored: hasErroredMammogram,
    mammogramQuestionErrorMessage: errorMessageMammogram,
  } = useAppSelector(selectAppointmentMammogram);
  const {
    isLoading: isLoadingMammogramDisclaimers,
    hasErrored: hasErroredMammogramDisclaimers,
    errorMessage: errorMessageMammogramDisclaimers,
  } = useAppSelector(selectAppointmentDocByType);

  // Local states
  const [pageFlag, setPageFlag] = useState(true);
  const [triggerPopHealthStatus, setTriggerPopHealthStatus] = useState(false);
  const [selectedService, setSelectedService] = useState<Service | null>(null);
  const [openBeforeCareMessageModal, setOpenBeforeCareMessageModal] =
    useState(false);
  const [openBeforeCareMessageErrorModal, setOpenBeforeCareMessageErrorModal] =
    useState(false);
  const [beforeCareMessageText, setBeforeCareMessageText] = useState<
    string | React.ReactNode
  >("");
  const [beforeCareMessageErrorText, setBeforeCareMessageErrorText] = useState<
    string | React.ReactNode
  >("");
  const [triageQuestionPrompts, setTriageQuestionPrompts] = useState<string[]>(
    [],
  );
  const [openTriage, setOpenTriage] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [triageQuestionPromptsAns, setTriageQuestionPromptsAns] = useState<
    QuestionResponse[]
  >([]);
  const [calledFetchTriageQuestionnaire, setCalledFetchTriageQuestionnaire] =
    useState(false);

  const onClickOnlineRequest = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_APPT_SUBMIT_A_REQUEST_ONLINE);
    if (!!isLiveChatEnabled) {
      mobileNavigate(
        ACTIONS.LIVECHAT_PAGE_INTERCEPTION(PATHS.APPOINTMENT_MOBILE.path),
      );
    } else {
      navigate(PATHS.APPOINTMENT_CREATE_FORM.path);
    }
  };

  // API Call to fetch service list for this location (particular institution)
  const {
    data: services,
    isLoading,
    hasErrored,
    errorMessage,
    dentalBlockedMessage,
  } = useGetServiceList(institutionCode);

  const { isLiveChatEnabled, isLiveChatLoading } = useGetLiveChatFlag();

  const [
    [
      pophealthstatus,
      pophealthstatusisLoading,
      pophealthstatushasErrored,
      pophealthstatuserrorMessage,
    ],
    [
      appointmentstatus,
      appointmentstatusisLoading,
      appointmentstatushasErrored,
      appointmentstatuserrorMessage,
    ],
  ] = useGetPopHealthandAppointmentStatus({
    memberIdentifier,
    clinicCode,
    relationType,
    institutionCode,
    targetSystem,
    trigger: triggerPopHealthStatus,
  });

  // Reset redux states
  useEffect(() => {
    dispatch(updateServiceForCreate(null));
  }, [dispatch]);

  useEffect(() => {
    if (
      !pophealthstatusisLoading &&
      !pophealthstatushasErrored &&
      pophealthstatus &&
      !appointmentstatusisLoading &&
      !appointmentstatushasErrored &&
      appointmentstatus
    ) {
      dispatch(setPopHealthStatus(pophealthstatus));
      if (!pophealthstatus.isEnroled) {
        setOpenBeforeCareMessageErrorModal(true);
        setBeforeCareMessageErrorText(
          renderErrorMessage(
            pophealthstatus.popOutMessage || "",
            "APPOINTMENT_CREATE",
            isLiveChatEnabled || false,
          ),
        );
      } else if (
        appointmentstatus.chronicApptId ||
        appointmentstatus.hsgApptId
      ) {
        setOpenBeforeCareMessageErrorModal(true);
        setBeforeCareMessageErrorText(
          renderErrorMessage(
            appointmentstatus.popOutMessage || "",
            "APPOINTMENT_CREATE",
            isLiveChatEnabled || false,
          ),
        );
      } else {
        const selectedInstitutionObj = services.find(
          (service) => service.isHsgAppt === true,
        );
        if (
          selectedInstitutionObj != null &&
          selectedInstitutionObj.beforeCareMessage != null
        ) {
          setBeforeCareMessageText(
            renderErrorMessage(
              selectedInstitutionObj.beforeCareMessage,
              "APPOINTMENT_CREATE",
              isLiveChatEnabled || false,
            ),
          );
          setOpenBeforeCareMessageModal(true);
        }
      }
    } else {
      setTriggerPopHealthStatus(false);
    }
  }, [
    pophealthstatusisLoading,
    appointmentstatusisLoading,
    appointmentstatus,
    pophealthstatus,
    pophealthstatushasErrored,
    appointmentstatushasErrored,
    pophealthstatuserrorMessage,
    appointmentstatuserrorMessage,
    services,
    isLiveChatEnabled,
    dispatch,
  ]);

  useEffect(() => {
    if (
      !isLoadingTriage &&
      !hasErroredTriage &&
      triageQuestionList.length !== 0 &&
      calledFetchTriageQuestionnaire
    ) {
      setTriageQuestionPrompts(
        triageQuestionList.map((question) => question.QuestionText),
      );
      setOpenTriage(true);
    }
  }, [
    isLoadingTriage,
    hasErroredTriage,
    triageQuestionList,
    triageQuestionPrompts.length,
    calledFetchTriageQuestionnaire,
  ]);

  useEffect(() => {
    if (
      // !isLoadingMammogram &&
      // hasErroredMammogram === false &&
      // isLoadingMammogramDisclaimers === false &&
      // hasErroredMammogramDisclaimers === false &&
      selectedService?.visitTypeId === "113019" //113019 === mammogram screening
    ) {
      navigateToExternalInMobileBrowser(MAMMOGRAM_SURVERY_URL, true);
      setSelectedService(null);
    }
  }, [selectedService, navigate, MAMMOGRAM_SURVERY_URL]);

  const handleTriageQuestionPromptsAns = (ans: string) => {
    setOpenTriage(false);
    var updated = [...triageQuestionPromptsAns];
    const QuestionId = triageQuestionList[currentQuestionIndex].QuestionId;
    const QuestionDescription =
      triageQuestionList[currentQuestionIndex].QuestionText;
    const textToCmp =
      triageQuestionList[currentQuestionIndex].AnswerOptions?.length > 0
        ? triageQuestionList[currentQuestionIndex].AnswerOptions[0]?.AnswerText
        : "Yes";
    const Response = textToCmp === ans ? true : false;
    const ResponseText = ans;
    updated[currentQuestionIndex] = {
      QuestionId,
      QuestionDescription,
      Response,
      ResponseText,
    };
    setTriageQuestionPromptsAns(updated);
    // if the current answered question is still not the last question
    if (currentQuestionIndex < triageQuestionPrompts.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setOpenTriage(true);
    } else {
      handleSubmitTriageQuestionnaire(updated);
    }
  };

  // not calling API to submit triage answer anymore
  const handleSubmitTriageQuestionnaire = async (
    triageAnsList: QuestionResponse[],
  ) => {
    if (
      triageAnsList.find((ans) => ans.ResponseText?.toLowerCase() === "yes")
    ) {
      dispatch(updateIsUrtiForCreate(true));
    } else {
      dispatch(updateIsUrtiForCreate(false));
    }
    navigate(PATHS.APPOINTMENT_CREATE_AVAILABLE_SLOTS.path);
  };

  return (
    <Page>
      {isLoading ||
      isLoadingTriage ||
      isLoadingMammogram ||
      isLoadingMammogramDisclaimers ||
      isLiveChatLoading ||
      pophealthstatusisLoading ||
      appointmentstatusisLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : hasErrored ? (
        <ErrorDisplayContainer
          errorMessage={errorMessage}
          onTryAgain={() => setPageFlag(!pageFlag)}
        />
      ) : hasErroredTriage ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageTriage}
          onTryAgain={() =>
            dispatch(
              fetchTriageQuestionnaire(
                selectedService?.questionnaireId || null,
              ),
            )
          }
        />
      ) : hasErroredMammogram ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageMammogram}
          onTryAgain={() => dispatch(fetchMammogramQuestionnaire())}
        />
      ) : hasErroredMammogramDisclaimers ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageMammogramDisclaimers}
          onTryAgain={() => dispatch(fetchDocumentForAllTypes())}
        />
      ) : pophealthstatuserrorMessage || appointmentstatuserrorMessage ? (
        <ErrorDisplayContainer
          errorMessage={
            pophealthstatuserrorMessage || appointmentstatuserrorMessage
          }
          onTryAgain={() => {
            setTriggerPopHealthStatus(true);
          }}
        />
      ) : (
        <>
          <ServicesPageHeader location={location} />
          <SelectionInstructions text="Select a Service" />
          <Box>
            <SelectionList
              items={services}
              onSelect={(selectedValue) => {
                const selectedInstitutionObj = services.find(
                  (service) => service.displayName === selectedValue,
                );

                if (selectedInstitutionObj) {
                  setSelectedService(selectedInstitutionObj);

                  if (
                    selectedInstitutionObj.visitTypeId === "113019" //113019 === mammogram screening
                  ) {
                    logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_SERVICE); //todo: check if this is still needed
                    setSelectedService(selectedInstitutionObj);
                  } else if (selectedInstitutionObj.isHsgAppt) {
                    setTriggerPopHealthStatus(true);
                  } else if (
                    selectedInstitutionObj.visitTypeId === "113015" && //113015 === Dental Cleaning (Scaling & Polishing)
                    dentalBlockedMessage //TODO: improve on targeting dental by unique identifier or code
                  ) {
                    setSelectedService(selectedInstitutionObj);
                    setBeforeCareMessageErrorText(
                      renderErrorMessage(
                        dentalBlockedMessage,
                        "APPOINTMENT_CREATE",
                      ),
                    );
                    setOpenBeforeCareMessageErrorModal(
                      !openBeforeCareMessageErrorModal,
                    );
                  } else if (
                    selectedInstitutionObj.beforeCareMessage &&
                    !selectedInstitutionObj.isHsgAppt
                  ) {
                    // this service has a before care message to display in proceed/cancel modal,
                    // which might also have triage questions after this, so do not directly navigate
                    // to available slots page first.
                    setBeforeCareMessageText(
                      renderErrorMessage(
                        selectedInstitutionObj.beforeCareMessage,
                        "APPOINTMENT_CREATE",
                        isLiveChatEnabled || false,
                      ),
                    );
                    setOpenBeforeCareMessageModal(true);
                  } else if (
                    !selectedInstitutionObj.beforeCareMessage &&
                    selectedInstitutionObj.hasTriageQuestions
                  ) {
                    // this service does not have a before care message to display in proceed/cancel modal.
                    // However, it has triage questions after this, so do not directly navigate
                    // to available slots page first.
                    dispatch(updateServiceForCreate(selectedValue));
                    dispatch(updateLastVisitDateTimeForCreate(null));
                    dispatch(updateIsUrtiForCreate(false));
                    dispatch(
                      updateAppointmentMetaFromService({
                        visitTypeId: selectedInstitutionObj.visitTypeId,
                        departmentCode: selectedInstitutionObj.departmentCode,
                      }),
                    );

                    dispatch(
                      fetchTriageQuestionnaire(
                        selectedInstitutionObj.questionnaireId || null,
                      ),
                    );
                    setCalledFetchTriageQuestionnaire(true);

                    logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_SERVICE);
                    logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_TRIAGE);
                  } else {
                    // proceed directly to available slots page, since there are no disclaimer modals to show
                    dispatch(updateServiceForCreate(selectedValue));
                    dispatch(updateLastVisitDateTimeForCreate(null));
                    dispatch(updateIsUrtiForCreate(false));
                    dispatch(
                      updateAppointmentMetaFromService({
                        visitTypeId: selectedInstitutionObj.visitTypeId,
                        departmentCode: selectedInstitutionObj.departmentCode,
                      }),
                    );
                    logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENT_SERVICE);
                    navigate(PATHS.APPOINTMENT_CREATE_AVAILABLE_SLOTS.path);
                  }
                }
              }}
            />
          </Box>
          <Box sx={{ p: 2 }}>
            <SharpNoticePanel showIcon bgColor="warn">
              Can't find the service you are looking for? (e.g. Immunisation,
              Driving License Assessment, etc.)
              <Link sx={{ ml: 1 }} onClick={onClickOnlineRequest}>
                Submit a request online.
              </Link>
            </SharpNoticePanel>
          </Box>
        </>
      )}
      {/* Before Care Message MODAL */}
      <ConfirmationModal
        title={selectedService?.displayName || ""}
        body={beforeCareMessageText}
        open={openBeforeCareMessageModal}
        nextButtonText="Proceed"
        cancelButtonText="Cancel"
        onClickNext={() => {
          const selectedInstitutionObj = services.find(
            (service) => service.displayName === selectedService?.displayName,
          );

          if (selectedInstitutionObj) {
            if (selectedInstitutionObj?.isHsgAppt) {
              dispatch(
                updateServiceForCreate(selectedService?.displayName || ""),
              );
              dispatch(updateLastVisitDateTimeForCreate(null));
              dispatch(updateIsUrtiForCreate(false));
              dispatch(
                updateAppointmentMetaFromService({
                  visitTypeId: pophealthstatus?.isHpCompleted
                    ? selectedInstitutionObj.alternateServiceType
                    : selectedInstitutionObj.visitTypeId,
                  departmentCode: null,
                  isHsgAppt: selectedInstitutionObj.isHsgAppt,
                  maxSlot: appointmentstatus?.maxslot || 0,
                  HSG: {
                    isHsgAppt: selectedInstitutionObj.isHsgAppt,
                    isHpCompleted: pophealthstatus?.isHpCompleted ?? null,
                    subsequentStartDate:
                      pophealthstatus?.subsequentStartDate ?? null,
                    subsequentEndDate:
                      pophealthstatus?.subsequentEndDate ?? null,
                  },
                }),
              );
              navigate(PATHS.APPOINTMENT_CREATE_LINKED_AVAILABLE_SLOTS.path);
            } else {
              dispatch(
                updateServiceForCreate(selectedService?.displayName || ""),
              );
              dispatch(updateLastVisitDateTimeForCreate(null));
              dispatch(updateIsUrtiForCreate(false));
              dispatch(
                updateAppointmentMetaFromService({
                  visitTypeId: selectedInstitutionObj.visitTypeId,
                  departmentCode: selectedInstitutionObj.departmentCode,
                }),
              );

              if (selectedInstitutionObj.hasTriageQuestions) {
                // this service has triage questions to display
                dispatch(
                  fetchTriageQuestionnaire(
                    selectedInstitutionObj?.questionnaireId || null,
                  ),
                );
                setCalledFetchTriageQuestionnaire(true);
                setOpenBeforeCareMessageModal(false);
              } else {
                navigate(PATHS.APPOINTMENT_CREATE_AVAILABLE_SLOTS.path);
              }
            }
          }
        }}
        onClickCancel={() => {
          setOpenBeforeCareMessageModal(false);
          setTriggerPopHealthStatus(false);
        }}
        onClose={() => {}}
      />
      {/* Before Care Message Unable to schedule appointment */}
      <ConfirmationModal
        title={ErrorModalTitles.needHelp}
        body={beforeCareMessageErrorText}
        open={openBeforeCareMessageErrorModal}
        showCloseButton={false}
        hideNextButton={true}
        hideCancelButton={true}
        hideAdditionalButton={false}
        additionalButtonText={
          pophealthstatus?.isEnroled ||
          selectedService?.visitTypeId === "113015" //113015 === Dental Cleaning (Scaling & Polishing)
            ? "Go to Appointment"
            : "Close"
        }
        onClickAdditional={() => {
          if (
            pophealthstatus?.isEnroled ||
            selectedService?.visitTypeId === "113015" //113015 === Dental Cleaning (Scaling & Polishing)
          ) {
            navigate(PATHS.APPOINTMENT_MOBILE.path);
          } else {
            setOpenBeforeCareMessageErrorModal(false);
            setTriggerPopHealthStatus(false);
          }
        }}
        onClose={() => {}}
      />
      {/* OPTIONAL TRIAGE QUESTION MODALS */}
      <TriageQuestionPromptModal
        disableBackdropClick={true}
        open={openTriage}
        body={MapRawStringtoReactElement(
          triageQuestionPrompts[currentQuestionIndex],
        )}
        nextButtonText={
          triageQuestionList[currentQuestionIndex]?.AnswerOptions?.length > 0
            ? triageQuestionList[currentQuestionIndex]?.AnswerOptions[0]
                .AnswerText
            : "Yes"
        }
        cancelButtonText={
          triageQuestionList[currentQuestionIndex]?.AnswerOptions?.length > 1
            ? triageQuestionList[currentQuestionIndex]?.AnswerOptions[1]
                .AnswerText
            : "No"
        }
        cancelButtonLabel={null}
        onClickNext={() =>
          handleTriageQuestionPromptsAns(
            triageQuestionList[currentQuestionIndex]?.AnswerOptions?.length > 0
              ? triageQuestionList[currentQuestionIndex]?.AnswerOptions[0]
                  .AnswerText
              : "Yes",
          )
        }
        onClickCancel={() =>
          handleTriageQuestionPromptsAns(
            triageQuestionList[currentQuestionIndex]?.AnswerOptions?.length > 1
              ? triageQuestionList[currentQuestionIndex]?.AnswerOptions[1]
                  .AnswerText
              : "No",
          )
        }
        onClose={() => {}}
      />
    </Page>
  );
};

export default ServicesPage;
