import { useState } from "react";
import { Box, Button } from "@mui/material";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import {
  AvailableSlotsSectionProps,
  Slot,
  OnSelectCallback,
} from "./AvailableSlotsSection.types";
import { useGetAvailableSlots } from "../../../../hooks/useGetAvailableSlots";
import Datepicker from "lib/components/formInputs/Datepicker/Datepicker";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import { sxStyles } from "./AvailableSlotsSection.styles";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import AvailableSlotsListContainer from "../AvailableSlotsList/AvailableSlotsListContainer";
import { isJHC } from "ui/appointment/helper/isJHC/isJHC";
import AvailableSlotsSectionSkeletons from "./AvailableSlotsSectionSkeletons";
import { useAppSelector } from "lib/redux/hooks";
import { selectCreateAppointment } from "ui/appointment/ducks/selectors";

// ==========================
// CONSTANTS
// ==========================

// Initial state of selected slot
const INITIAL_SELECTED_SLOT = {
  slotId: "",
  slotDateTime: null,
  extensionData: null,
};

// ==========================
// COMPONENT
// ==========================

const AvailableSlotsSection = ({
  isLinkedAppointment = false,
  isNewAppointment,
  isSameDayBooking,
  memberIdentifier,
  appointmentId,
  visitTypeId,
  targetSystem,
  institutionCode,
  departmentCode,
  isUrti,
  minStartDate,
  maxEndDate,
  shouldGetAvailableSlots,
  slotIdList,
  isBundledLinkedAppointments,
  centerContactNo,
  expectedOrderDate,
  onSelect,
  onCancel,
}: AvailableSlotsSectionProps) => {
  const classes = sxStyles();

  // Redux States
  const { selectedPractitioner: selectedConsultant } = useAppSelector(
    selectCreateAppointment,
  );

  /* Local State */
  const [isPreview, setIsPreview] = useState(true); // controls whether should show all slots retrieved from API or not
  const [isDateChanged, setIsDateChanged] = useState(false); // indicated whether a new date is picked to fetch slots
  const [paginationIndex, setPaginationIndex] = useState(0); // controls the current page index of slots
  const [selectedSlot, setSelectedSlot] = useState<Slot>(INITIAL_SELECTED_SLOT);
  const [selectedDate, setSelectedDate] = useState<Date>(
    getDefaultDate(minStartDate),
  );

  const minDate = formatDateToISO(minStartDate);
  const maxDate = formatDateToISO(maxEndDate);

  /* Hook to fetch available slots data */
  const {
    availableSlots,
    isLoading,
    hasErrored,
    errorMessage,
    isLiveChatEnabled,
    hasMoreSlots,
    noSlotMessage,
  } = useGetAvailableSlots({
    pageFlag: true,
    isNewAppointment,
    shouldGetAvailableSlots,
    paginationIndex,
    startDate: selectedDate,
    memberIdentifier,
    visitTypeId,
    institutionCode,
    endDate: maxDate,
    isDateChanged,
    slotIdList: slotIdList,
    isBundledLinkedAppointments: false,
    answerToUrtiTriage: isUrti,
    practitionerId: selectedConsultant?.practitionerId,
  });

  const showCenterContactNo = isJHC(institutionCode);

  //filter slots according to data range for reschedule flow
  const slotsInRange = availableSlots
    ? availableSlots.filter((slot) => {
        if (!minDate && !maxDate) {
          // this is not reschedule, no time period restriction
          return true;
        } else {
          const slotDateObj = getDateFromISOString(slot.date);
          var maxEndNextDate = getNextDate(maxEndDate);
          return (
            minStartDate &&
            maxEndDate &&
            slotDateObj &&
            slotDateObj < maxEndNextDate
          );
        }
      })
    : availableSlots;

  const hasExceedRange = availableSlots
    ? availableSlots.length > slotsInRange.length
    : false;

  const disableNextButton =
    selectedSlot.slotId === null || selectedSlot.slotId === "";
  const hideNextButton = slotsInRange?.length === 0 && isSameDayBooking;

  return (
    <Box>
      <Box display="flex" flexDirection="column">
        {/* Select a Date/Time section (when needed) */}
        {isSameDayBooking ? null : (
          <Box>
            <Box sx={classes.datepicker}>
              <Datepicker
                label="Date"
                autoOk={false}
                showAcceptButton
                showCancelButton
                disablePast={true}
                value={selectedDate}
                onSelect={(date) => {
                  setSelectedDate(date);
                  setIsDateChanged(true);
                  setSelectedSlot(INITIAL_SELECTED_SLOT);
                }}
                minDate={minDate}
                maxDate={maxDate}
                required={false} //it is mandatory, but we don't want to show the *required text here
              />
            </Box>
          </Box>
        )}

        {isLoading ? (
          <AvailableSlotsSectionSkeletons />
        ) : hasErrored ? (
          <ErrorDisplayContainer
            errorMessage={errorMessage}
            isLiveChatEnabled={isLiveChatEnabled}
          />
        ) : (
          <AvailableSlotsListContainer
            showCenterContactNo={showCenterContactNo}
            centerContactNo={centerContactNo}
            slots={slotsInRange}
            isPreview={isPreview}
            isDateChanged={isDateChanged}
            hasExceedRange={hasExceedRange}
            hasMoreSlots={hasMoreSlots}
            noSlotMessage={noSlotMessage}
            onSelect={(slotId, slotDateTime) => {
              setSelectedSlot({ slotId, slotDateTime });
              setIsDateChanged(false);
            }}
            onNextPage={() => {
              setPaginationIndex(paginationIndex + 1);
              setIsDateChanged(false);
            }}
            onShowAll={() => setIsPreview(false)}
            isLiveChatEnabled={isLiveChatEnabled}
          />
        )}
      </Box>

      <Box sx={{ padding: 2 }}>
        {/* Confirm Selection */}
        {isNewAppointment ? (
          <ButtonsFooter
            isDisabled={disableNextButton}
            hideNextButton={hideNextButton}
            nextButtonText={isLinkedAppointment ? "Done" : "Next"}
            cancelButtonText={"Back"}
            onClickNext={onSelectSlot(selectedSlot, onSelect)}
            onClickCancel={onCancel}
          />
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button sx={{ mb: 2 }} onClick={onCancel}>
              Back
            </Button>
            {!hideNextButton && (
              <Button
                onClick={onSelectSlot(selectedSlot, onSelect)}
                variant="outlined"
                disabled={disableNextButton}
              >
                Next
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

// ==========================
// HELPER METHODS
// ==========================

/**
 * Obtains the default date the datepicker should start at
 *
 * @param {Date | undefined | null} startDate  (Optional) A defined minimum start date
 * @returns {Date}  The default date to set in the datepicker
 */
const getDefaultDate = (startDate: Date | undefined | null) => {
  const today = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  );

  try {
    if (startDate) {
      if (startDate.getTime() > today.getTime()) {
        // min date is later than today, hence we should restrict the beginning range of dates
        return startDate;
      } else {
        return today;
      }
    } else {
      // no min date given, means that the default should be today's date
      return today;
    }
  } catch (error) {
    return today;
  }
};

/**
 * Returns the next date for a truty given date, or max date in javascript optherwise
 * won't mutate the passed in date
 *
 * @param {Date | undefined | null} maxDate  The date which needs to be get next date for
 * @returns {Date}  The next date / max date in javascript
 */
const getNextDate = (maxDate: Date | null | undefined) => {
  if (maxDate) {
    var nextDate = new Date(maxDate.getTime());
    nextDate.setDate(nextDate.getDate() + 1);
    return nextDate;
  } else {
    return new Date(8640000000000000); // max date in javascript
  }
};

/**
 * Returns a function, that when invoked, will trigger the onSelect callback
 * param with the selected slot's details.
 *
 * @param {Slot} slot  The selected Available Slot
 * @param {OnSelectCallback} onSelect  The onSelect handler
 *
 * @returns Function, that when invoked, triggers the onSelect callback param
 */
const onSelectSlot = (slot: Slot, onSelect: OnSelectCallback) => {
  return () => {
    const { slotId, slotDateTime } = slot;

    onSelect(slotId, slotDateTime);
  };
};

export default AvailableSlotsSection;
