import axios from "axios";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { getRequestOptions } from "../../resources/configs/requests";
import { GET_AVAILABLE_SLOTS_URL } from "../../resources/configs/URL";
import { formatGetAvailableSlotsErrorMessage } from "../../resources/messages/ErrorMessage";
import { GetAvailableSlotsPayload } from "./getAvailableSlots.fromApi.types";
import { parseResponse } from "../../resources/handlers/parseResponse/parseResponse";

/**
 * Fetches all available appointment slots which can be booked
 *
 * POST
 * Endpoint: /GetAvailableSlots
 *
 * @param {string} memberIdentifier  Member Identifier of the person we are making this request for
 * @param {string | null} appointmentId  ID of an existing appointment, if any (e.g. appointment re-schedule)
 * @param {Date} date  Appointment Date to book
 * @param {string | null} extensionData
 *   Extension Data, that serves as metadata required to be passed this endpoint
 * @param {string | null} nextPageRequestData
 *   Pagination Data, that services as metadata required to be passed this endpoint to fetch the next page
 * @param {number | null} serviceTypeId  This service's Type ID
 * @param {string | OnBeforeUnloadEventHandlerNonNull} targetSystem  Source system from which these slots are from
 * @param {string | OnBeforeUnloadEventHandlerNonNull} departmentCode  Department code of the service to create an appointment for / appointment to reschedule for
 * @param {boolean} isUrti  isUrti flag metadata set depending on how the Triage Questions were answered
 * @param {string | null} nextPageRequestData a string as a key to let backend return slots that are continuing from last request, will be returned by last request when available
 * @param {string | null} endDate the maximum date which the user can select for the appointment
 * @param {VisitToSearchParam[] | null} visitToSearchParams array of fields required for bundled linked appointments
 * @param {string|null} providerId Id required for NTFGH single appointment reschedule
 * @param {boolean} isNewAppointment is true for creating new appointments. False for rescheduling appointments
 * @returns {GetAvailableSlotsPayload} Response payload from the remote API
 */

export interface getAvailableSlotsRequest {
  isNewAppointment: boolean;
  memberIdentifier: string | null;
  institutionCode: string | null;
  nextPageRequestData?: string | null;
  slotIdList: (string | null)[] | null;
  startDate: Date | null;
  endDate: string | null;
  visitTypeId: string | null;
  answerToUrtiTriage?: boolean;
  isHsgSubsequent?: boolean | null;
  practitionerId?: string | null;
}

const getAvailableSlots = async ({
  isNewAppointment,
  memberIdentifier,
  institutionCode,
  nextPageRequestData,
  slotIdList,
  startDate,
  endDate,
  visitTypeId,
  answerToUrtiTriage,
  isHsgSubsequent,
  practitionerId,
}: getAvailableSlotsRequest): Promise<GetAvailableSlotsPayload> => {
  try {
    const response = await axios.post<GetAvailableSlotsPayload>(
      GET_AVAILABLE_SLOTS_URL(),
      createRequestPayload({
        isNewAppointment,
        memberIdentifier,
        institutionCode,
        nextPageRequestData,
        slotIdList,
        startDate,
        endDate,
        visitTypeId,
        answerToUrtiTriage,
        isHsgSubsequent,
        practitionerId,
      }),
      getRequestOptions(),
    );
    return parseResponse(response, GET_AVAILABLE_SLOTS_URL());
  } catch (error) {
    const msg = formatGetAvailableSlotsErrorMessage(GET_AVAILABLE_SLOTS_URL());
    console.error(msg);
    throw error;
  }
};

const createRequestPayload = ({
  isNewAppointment,
  memberIdentifier,
  institutionCode,
  nextPageRequestData,
  slotIdList,
  startDate,
  endDate,
  visitTypeId,
  answerToUrtiTriage = false,
  isHsgSubsequent = false,
  practitionerId,
}: getAvailableSlotsRequest) => {
  return {
    MemberIdentifier: memberIdentifier,
    Start: formatDateToISO(startDate),
    End: endDate,
    SlotIdList: isNewAppointment ? null : slotIdList,
    NextPageRequestData: nextPageRequestData ?? null,
    VisitTypeId: visitTypeId,
    InstitutionCode: institutionCode,
    AnswerToUrtiTriage: answerToUrtiTriage,
    IsHsgSubsequent: isHsgSubsequent,
    PractitionerId: practitionerId,
  };
};

export { getAvailableSlots };
