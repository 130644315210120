import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Box, CircularProgress, Button } from "@mui/material";
import { PATHS, ACTIONS } from "lib/routing";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { formatAddToCalendarURI } from "lib/mobileIntegration/addToCalendar/formatUrl";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { SelectLinkedApptsProps } from "./SelectLinkedAppts.types";
import { Appointment } from "api/appointment/GetAppointmentList/getAppointmentList.toUi.types";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import { sxStyles } from "./SelectLinkedAppts.styles";
import { rescheduleLinkedAppointment } from "api/appointment/RescheduleLinkedAppointment/rescheduleLinkedAppointment";
import LinkedApptCard from "../LinkedApptCard/LinkedApptCard";
import ConfirmationModal from "lib/components/modals/ConfirmationModal/ConfirmationModal";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";
import { CONNECT_TO_LIVE_AGENT_BUTTON_TEXT } from "lib/components/navigation/AppRouteRenderer/ErrorModal/ErrorModal";
import PreCancelRescheduleModal from "ui/appointment/components/common/PreCancelRescheduleModal/PreCancelRescheduleModal";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectRescheduleLinkedAppointments } from "ui/appointment/ducks/selectors";
import { setIsPreConfirmationModalOpen } from "ui/appointment/ducks/appointmentsSlice";
import { AxiosError } from "axios";
import { setMessageToSend } from "lib/redux/navigation/navigationSlice";
import { MessageActions } from "lib/routing/messageChannel/messageActions";

const SelectLinkedAppts = ({
  fromMobile,
  visitedSlotsPage,
  isLoadingAppointments,
  hasErroredAppointments,
  errorMessageAppointments,
  appointment,
  newSlotDateTimeList,
  memberIdentifier,
  isLoadingPreConsultMsg,
  preConsultMsg,
  preventDirectBackNavigationState,
  fetchAppointments,
  initializeNewSlotDateTimeList,
  selectAppointment,
  clearVisitedSlotsPageFlag,
  handleErrorModal,
  fetchPreConsultMsg,
  resetPreventDirectBackNavigationState,
}: SelectLinkedApptsProps) => {
  const classes = sxStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const linkedAppointments = flattenLinkedAppointment(appointment);

  // State management
  const [isLoadingReschedule, setIsLoadingReschedule] = useState(false);
  const [openRescheduleConfirmation, setOpenConfirmation] = useState(false);
  const handleOpenConfirmation = () => setOpenConfirmation(true);
  const handleCloseConfirmation = () => setOpenConfirmation(false);

  // Redux State
  const { isPreConfirmationModalOpen } = useAppSelector(
    selectRescheduleLinkedAppointments,
  );

  // Fetch appointments on loading to get the corresponding appointment details
  useEffect(() => {
    if (fromMobile && !visitedSlotsPage) {
      fetchAppointments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!visitedSlotsPage) {
      initializeNewSlotDateTimeList(linkedAppointments.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitedSlotsPage, linkedAppointments.length]);

  // check isLoadingPreConsultMsg to ensure only fetch once in the rescheduling flow
  useEffect(() => {
    if (isLoadingPreConsultMsg === null) {
      fetchPreConsultMsg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPreConsultMsg]);

  //customized back navigation when back button is clicked
  useEffect(() => {
    if (preventDirectBackNavigationState) {
      // reset the back button flag setting
      resetPreventDirectBackNavigationState();
      fromMobile
        ? mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH)
        : navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
    }
    // we only want this hook to fire on back navigation
    // eslint-disable-next-line
  }, [preventDirectBackNavigationState]);

  // Request handler
  const sendRescheduleLinkedAppointmentRequest = async () => {
    try {
      logEventToGoogleAnalytics(EVENTS.VIEW_RESCHEDULE_CONFIRMATION);
      setIsLoadingReschedule(true);

      const slotIdList: (string | null)[] = newSlotDateTimeList.map(
        (slot, index) => {
          if (slot) {
            // user picked this slot to reschedule to for this appointment
            return slot.slotId;
          } else {
            // user did not pick a slot to reschedule to for this appointment, so use original appointment object's metadata
            return null;
          }
        },
      );

      await rescheduleLinkedAppointment(
        memberIdentifier,
        appointment?.institutionCode || "",
        slotIdList,
      );
      dispatch(setMessageToSend(MessageActions.rating()));

      handleOpenConfirmation();
      clearVisitedSlotsPageFlag();
    } catch (error) {
      if (error instanceof AxiosError) {
        handleErrorModal(
          true,
          error.response?.data.Message,
          error.response?.data.IsLiveChatEnabled
            ? CONNECT_TO_LIVE_AGENT_BUTTON_TEXT
            : undefined,
        );
      } else {
        handleErrorModal(true, null);
      }
    } finally {
      setIsLoadingReschedule(false);
    }
  };

  const handleOnBack = () => {
    clearVisitedSlotsPageFlag();

    fromMobile
      ? mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH)
      : navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
  };

  const handleOnBackPreResch = () => {
    logEventToGoogleAnalytics(EVENTS.CLICK_APPT_RESCH_HEALTHMATTERS_BACK);
    clearVisitedSlotsPageFlag();

    fromMobile
      ? mobileNavigate(ACTIONS.MOBILE_DASHBOARD_PATH)
      : navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
  };

  const handleOnProceed = () => {
    sendRescheduleLinkedAppointmentRequest();
  };

  const isProceedDisabled =
    newSlotDateTimeList.every((x) => x === null) || isLoadingReschedule;

  return (
    <>
      {isLoadingAppointments ||
      isLoadingPreConsultMsg ||
      isLoadingReschedule ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            pt: 4,
          }}
        >
          <CircularProgress />
        </Box>
      ) : hasErroredAppointments ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageAppointments}
          onTryAgain={() => fetchAppointments()}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography sx={classes.title}>
              Select the Appointment to Reschedule
            </Typography>{" "}
            {/* Suggestion Text */}
            {!newSlotDateTimeList.every((item) => item === null) ? (
              <Box sx={classes.suggestion}>
                <SharpNoticePanel bgColor="warn">
                  {preConsultMsg && MapRawStringToReactElement(preConsultMsg)}
                  Remember to remove your old appointment from your calendar.{" "}
                </SharpNoticePanel>
              </Box>
            ) : null}
            {/* Linked Appointment Status Cards */}
            {linkedAppointments.map((appt, index) => {
              let slot = newSlotDateTimeList[index];
              let slotDateTime = slot ? slot.datetime : null;

              return (
                <LinkedApptCard
                  key={index}
                  appointment={appt}
                  newDateTime={slotDateTime}
                  onSelect={() => {
                    selectAppointment(appt, index);
                    navigate(
                      PATHS.APPOINTMENT_RESCHEDULE_LINKED_AVAILABLE_SLOTS.path,
                    );
                  }}
                />
              );
            })}
          </Box>
          {/* Page Footer */}
          <Box
            sx={{ p: 2.5, display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Button onClick={handleOnBack}>Back</Button>
            <Button
              variant="outlined"
              disabled={isProceedDisabled}
              onClick={handleOnProceed}
            >
              Proceed to reschedule
            </Button>
          </Box>
          <PreCancelRescheduleModal
            isOpen={isPreConfirmationModalOpen}
            onClickFirstButton={handleOnBackPreResch}
            onClickSecondButton={() => {
              logEventToGoogleAnalytics(
                EVENTS.CLICK_APPT_RESCH_HEALTHMATTERS_PROCEED,
              );
              dispatch(setIsPreConfirmationModalOpen(false));
            }}
            type={"reschedule"}
          />
          {/* Save to Calendar Modal */}
          <ConfirmationModal
            open={openRescheduleConfirmation}
            title="Appointment Changed"
            body={
              // after care message
              MapRawStringToReactElement(linkedAppointments[0]?.reminder ?? "")
            }
            nextButtonText="Refill medication"
            cancelButtonText="Go to Appointment"
            hideAdditionalButton={false}
            additionalButtonText="Add to calendar"
            onClickNext={() => {
              mobileNavigate(ACTIONS.MOBILE_MEDICATION_LANDING);
            }}
            onClickCancel={() => {
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClickAdditional={() => {
              const firstAppointment = linkedAppointments[0];

              // ensure user saves the most relevant datetime in the calendar
              let calendarDateTime = firstAppointment?.datetime;
              const slotDateTime = getDateFromISOString(
                newSlotDateTimeList[0] ? newSlotDateTimeList[0].datetime : null,
              );
              if (slotDateTime) {
                calendarDateTime = slotDateTime;
              }

              mobileNavigate(
                formatAddToCalendarURI(
                  firstAppointment.appointmentId || "",
                  calendarDateTime,
                  firstAppointment.calendarLocation || "",
                  firstAppointment.institutionName,
                  firstAppointment.calendarTitle,
                ),
              );
              navigate(PATHS.APPOINTMENT_MOBILE.path, { replace: true });
            }}
            onClose={handleCloseConfirmation}
          />
        </Box>
      )}
    </>
  );
};

/**
 * Flattens an appointment itself and all of its linked child appointments such that
 * they are strung together into a single list.
 *
 * @param {Appointment | null}  appointment  Linked appointment object
 *
 * @returns {BaseAppointment[]} List of the appointment itself and all of its linked child appointments
 */
const flattenLinkedAppointment = (
  appointment: Appointment | null,
): Appointment[] => {
  if (appointment) {
    const parent: Appointment = appointment;
    let children: Appointment[] = [];

    if (appointment.linkedAppointments) {
      children = appointment.linkedAppointments.map((x) => x);
    }

    return [parent, ...children];
  } else {
    return [];
  }
};

export default SelectLinkedAppts;
