import { Box, Typography, useAutocomplete, useTheme } from "@mui/material";
import SimpleAutocomplete from "lib/components/formInputs/SimpleAutocomplete/SimpleAutocomplete";
import { DisplayableItem } from "lib/data/data.types";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { PATHS } from "lib/routing";
import { useNavigate } from "react-router-dom";
import SelectionList from "ui/appointment/components/common/selection-listing/SelectionList/SelectionList";
import { updateSpecialtyForCreate } from "ui/appointment/ducks/appointmentsSlice";
import HeaderBlock from "../../components/HeaderBlock/HeaderBlock";
import { useEffect, useMemo } from "react";
import { selectAppointments } from "ui/appointment/ducks/selectors";
import { fetchSystemSettings } from "ui/appointment/ducks";
import { SpecialtyFromSystemSettings } from "./SpecialtySelectionPage.type";
import { parseJson } from "lib/util/StringUtil/jsonParser/parseJson";
import ErrorDisplay from "lib/components/error/ErrorDisplay/ErrorDisplay";
import SpecialtySelectionPageSkeletons from "./SpecialtySelectionPageSkeletons";

const SpecialtySelectionPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { selectedInstitution, selectedInstitutionCode } = useAppSelector(
    (state) => state.appointments.create,
  );
  const {
    isLoading,
    hasErrored,
    errorMessage,
    value: systemSettingsValue,
  } = useAppSelector(selectAppointments).systemSettings;

  const specialtyList = useMemo(() => {
    if (systemSettingsValue) {
      const parsedSettings = parseJson(systemSettingsValue) satisfies Record<
        string,
        SpecialtyFromSystemSettings[]
      >;

      return parsedSettings[selectedInstitutionCode ?? ""];
    } else {
      return [];
    }
  }, [selectedInstitutionCode, systemSettingsValue]);

  const displayList = useMemo(() => {
    if (specialtyList) {
      const mappedSpecialties = specialtyList
        .map(
          (specialty: SpecialtyFromSystemSettings): DisplayableItem => ({
            displayName: specialty.Description,
            category: "",
          }),
        )
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

      return mappedSpecialties;
    } else {
      return [];
    }
  }, [specialtyList]);

  const { getInputProps, groupedOptions } = useAutocomplete({
    options: displayList as DisplayableItem[],
    getOptionLabel: (option: { displayName: string }) => option.displayName,
  });

  // Reset specialty state
  useEffect(() => {
    dispatch(
      updateSpecialtyForCreate({
        description: "",
        isFirstVisit: false,
        beforeCareMessage: "",
        code: "",
      }),
    );
  }, [dispatch]);

  // Get specialty list
  useEffect(() => {
    if (!systemSettingsValue) {
      dispatch(
        fetchSystemSettings(
          "RequestForm",
          "NUHsAndOthers",
          null,
          selectedInstitutionCode,
        ),
      );
    }
  }, [dispatch, selectedInstitutionCode, systemSettingsValue]);

  return (
    <>
      {isLoading ? (
        <>
          <HeaderBlock location={selectedInstitution} />
          <Typography
            variant="header4_semibold"
            sx={{ m: 2, color: theme.palette.primary.dark }}
          >
            Select Specialty
          </Typography>
          <SpecialtySelectionPageSkeletons />
        </>
      ) : hasErrored ? (
        <ErrorDisplay errorMessage={errorMessage} />
      ) : (
        <>
          <HeaderBlock location={selectedInstitution} />
          <Typography
            variant="header4_semibold"
            sx={{ m: 2, color: theme.palette.primary.dark }}
          >
            Select Specialty
          </Typography>
          {displayList.length !== 0 && (
            <>
              {" "}
              <Box sx={{ m: 2 }}>
                <SimpleAutocomplete
                  placeholder="Search specialty"
                  items={[]}
                  showHistoryKeyword
                  onSelect={(selectedValue) => {
                    console.log(selectedValue);
                  }}
                  inputProps={getInputProps}
                />
              </Box>
              <SelectionList
                items={
                  groupedOptions.length === 0
                    ? (displayList as DisplayableItem[])
                    : (groupedOptions as DisplayableItem[])
                }
                onSelect={(selectedValue) => {
                  const selectedSpecialty = specialtyList?.find(
                    (element) => element.Description === selectedValue,
                  );

                  if (selectedSpecialty) {
                    dispatch(
                      updateSpecialtyForCreate({
                        description: selectedSpecialty.Description,
                        isFirstVisit: selectedSpecialty.IsFirstVisit,
                        beforeCareMessage: selectedSpecialty.BeforeCareMessage,
                        code: selectedSpecialty.Code,
                      }),
                    );

                    selectedSpecialty.IsFirstVisit
                      ? navigate(PATHS.APPOINTMENT_CREATE_REFERRAL.path)
                      : navigate(PATHS.APPOINTMENT_CREATE_FORM.path);
                  }
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default SpecialtySelectionPage;
