import { PATHS } from "../paths/paths";

export const LANDING_PAGE_PATHS = [
  PATHS.APPOINTMENT_MOBILE.path,
  PATHS.PAYMENT_MOBILE.path,
  PATHS.MEDICATIONS_MOBILE.path,
  PATHS.HT_MOBILE.path,
  PATHS.MRRP_MOBILE.path,
  PATHS.MY_PROFILE_MOBILE.path,
];

/**
 * Paths that should not have the back button enabled
 */
export const NON_LANDING_PAGE_PATHS_BACK_DISABLE = [
  // ====================
  // Profile
  // ====================
  PATHS.PM_ENTRY_MOBILE.path,
  PATHS.MY_PROFILE_MOBILE_ERROR.path,
  PATHS.MY_PROFILE_PREFERRED_MAILING.path,
  PATHS.MY_INFOPROFILE.path,
  PATHS.MYINFO_AND_PROFILE_CONFIRM.path,
  PATHS.MYINFO_AND_PROFILE_COMPARE.path,

  // ====================
  // Payment
  // ====================
  PATHS.PAYMENT_RECEIPT.path,
  PATHS.PAYMENT_PAYOR_INFO_TRY_AGAIN.path,
  PATHS.PAYMENT_RECEIPT_DD.path,

  // ====================
  // Appointments
  // ====================
  PATHS.APPOINTMENT_QUEUECHIT_JOURNEY_NO_BACK.path,

  // ====================
  // MRRP
  // ====================
  PATHS.MRRP_NEW_REQUEST_RECEIPT.path,

  // ====================
  // MedRefill
  // ====================
  PATHS.MED_REFILL_PARTICULARS.path,
  PATHS.MED_TOPUP_PARTICULARS.path,

  // MedRefill - VCP Order Med
  PATHS.MED_VIRTUALCONSULT_ORDER_MEDICATION_PARTICULARS_MOBILE.path,
];

export const HIDDEN_BACK_BUTTON_PATHS: string[] = [];
