import { Box } from "@mui/material";
import { AppointmentCardListProps } from "./AppointmentCardList.types";
import UpcomingAppointmentCard from "../cards/UpcomingAppointmentCard/UpcomingAppointmentCard";
import MissedAppointmentCard from "../cards/MissedAppointmentCard/MissedAppointmentCard";
import OpenAppointmentCard from "../cards/OpenAppointmentCard/OpenAppointmentCard";
import { sxStyles } from "./AppointmentCardList.styles";
import IMAGES from "lib/assets/images";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import { AppointmentCardSkeletons } from "./AppointmentCardSkeletons";

const defaultInfoMsg = `If you cannot find your appointment, pull down to refresh the page.`;

const AppointmentCardList = ({
  isLoading,
  hasErrored,
  errorMessage,
  appointmentList,
  appointmentType,
  isLiveChatEnabled,
}: AppointmentCardListProps) => {
  const classes = sxStyles();

  return (
    <>
      {isLoading ? (
        <AppointmentCardSkeletons />
      ) : hasErrored ? (
        <ErrorDisplayContainer errorMessage={errorMessage} />
      ) : (
        <>
          {appointmentList && appointmentList.length === 0 ? (
            <Box sx={classes.noRecordBox}>
              <Box sx={classes.noRecordImage}>
                {appointmentType === "upcoming" ? (
                  <Box
                    component="img"
                    src={IMAGES.appointment.NoUpcomingAppointment}
                    alt="No Upcoming Appointment"
                  />
                ) : appointmentType === "missed" ? (
                  <Box
                    component="img"
                    src={IMAGES.appointment.NoMissedAppointment}
                    alt="No Missed Appointment"
                  />
                ) : appointmentType === "open" ? (
                  <Box
                    component="img"
                    src={IMAGES.appointment.NoOpenAppointment}
                    alt="No Open Appointment"
                  />
                ) : null}
              </Box>
              <Box sx={classes.noRecordText}>
                You have no {appointmentType} appointments.
              </Box>
            </Box>
          ) : (
            appointmentList?.map((appt) => {
              if (appt.type === "upcoming") {
                return (
                  <Box key={appt.appointmentId} marginBottom={2}>
                    <UpcomingAppointmentCard appointment={appt} />
                  </Box>
                );
              }
              if (appt.type === "missed") {
                return (
                  <Box key={appt.appointmentId} marginBottom={2}>
                    <MissedAppointmentCard
                      appointment={appt}
                      isLiveChatEnabled={isLiveChatEnabled}
                    />
                  </Box>
                );
              }
              if (appt.type === "open") {
                return (
                  <Box key={appt.appointmentId} marginBottom={2}>
                    <OpenAppointmentCard
                      appointment={appt}
                      isLiveChatEnabled={isLiveChatEnabled}
                    />
                  </Box>
                );
              }
              return null;
            })
          )}

          <SharpNoticePanel bgColor="warn" showIcon>
            {defaultInfoMsg}
          </SharpNoticePanel>
        </>
      )}
    </>
  );
};

export default AppointmentCardList;
