import { useRef, useState } from "react";
import { Box, Avatar, Badge, Typography } from "@mui/material";
import { ProfileImageUploaderProps } from "./ProfileImageUploader.types";
import { sxStyles } from "./ProfileImageUploader.styles";
import { removeImageDataEncodingPrefix } from "lib/util/ReactComponentUtil/removeImageDataEncodingPrefix/removeImageDataEncodingPrefix";
import * as CompressionModule from "browser-image-compression";
import {
  getCorruptedFileFormatErrorState,
  getInitialUploadState,
  getInvalidFileFormatErrorState,
  getIsLoadingUploadState,
  getSuccessfulUploadState,
} from "lib/components/upload/ImageUploader/processUploadedFile.states";
import { UploadState } from "lib/components/upload/ImageUploader/ImageUploader.types";
import IMAGES from "lib/assets/images";

// Inline image encoding scheme prefix
const DEFAULT_INLINE_IMAGE_ENCODING_SCHEME_PREFIX = "data:image/jpeg;base64,";
const IMAGE_SIZE_LIMIT = 0.1; // in MB

const ProfileImageUploader = ({
  photo,
  disabled = false,
  onUpload,
}: ProfileImageUploaderProps) => {
  const classes = sxStyles();
  const inputFieldRef = useRef<HTMLInputElement>(null);
  const [fileUploadState, setFileUploadState] = useState<UploadState>({
    ...getInitialUploadState(),
  });

  const compressImage = async (file: File) => {
    return CompressionModule.default(file, {
      // compresses the image until its size is below this threshold
      maxSizeMB: IMAGE_SIZE_LIMIT,

      maxIteration: 100,

      // use main thread so there's no reliance on web worker
      useWebWorker: false,
    });
  };

  return (
    <Box sx={classes.background}>
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={
          !disabled &&
          !fileUploadState.isLoading && (
            <Avatar
              sx={classes.cameraAvatar}
              src={IMAGES.profile.ProfileCamera}
            />
          )
        }
      >
        <Avatar
          src={
            fileUploadState.isLoading
              ? IMAGES.general.LoadingIcon
              : photo
                ? DEFAULT_INLINE_IMAGE_ENCODING_SCHEME_PREFIX + photo
                : IMAGES.general.NoImageSquare
          }
          onClick={() => {
            // Simulates a file upload. Invokes the appropriate file input field's onChange
            // handler, as that is where the data for the uploaded file will be passed to
            if (fileUploadState.isLoading) {
            } else {
              inputFieldRef?.current?.click();
            }
          }}
          sx={classes.profileAvatar}
        />
      </Badge>

      {fileUploadState.errorMessage ? (
        <Typography sx={classes.errorText}>
          {fileUploadState.errorMessage}
        </Typography>
      ) : null}

      <Box
        component={"input"}
        title="image uploader"
        sx={classes.fileInputField}
        ref={inputFieldRef}
        disabled={disabled}
        type="file"
        accept="image/*"
        onChange={async (event) => {
          if (event?.target?.files) {
            const file = event.target.files[0]; // file object from user input
            if (file) {
              // !exp checks against acceptable file formats
              if (file.name.match(/.(jpg|jpeg|png|gif|bmp|heic)$/i)) {
                const firstReader = new FileReader();

                firstReader.readAsText(file);
                firstReader.onloadend = async (e) => {
                  // !exp checks if an acceptable image file contains svg
                  if (e.target?.result?.toString().includes("svg")) {
                    setFileUploadState(getCorruptedFileFormatErrorState());
                  } else {
                    setFileUploadState(getIsLoadingUploadState());
                    const compressedImageFile = await compressImage(file);
                    const reader = new FileReader();
                    reader.readAsDataURL(compressedImageFile);
                    reader.onloadend = (e) => {
                      const base64StringWithPrefix =
                        e.target?.result?.toString();
                      const fileData = base64StringWithPrefix
                        ? removeImageDataEncodingPrefix(base64StringWithPrefix)
                        : null;
                      onUpload(fileData);
                      setFileUploadState(
                        getSuccessfulUploadState(file.name, fileData ?? ""),
                      );
                    };
                  }
                };
              } else {
                setFileUploadState(getInvalidFileFormatErrorState());
              }
            }
          }
        }}
      />
    </Box>
  );
};

export default ProfileImageUploader;
