import React, { useState } from "react";
import { Paper, Tabs, Tab, Box, Typography } from "@mui/material";
import { AppointmentTabsProps, TabPanelProps } from "./AppointmentTabs.types";
import { sxStyles } from "./AppointmentTabs.styles";
import AppointmentCardList from "../AppointmentCardList/AppointmentCardList";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1, pt: 2, px: 2 }}>{children}</Box>}
    </Box>
  );
}

const AppointmentTabs = ({
  isLoading,
  hasErrored,
  errorMessage,
  appointmentsListCategorized,
  isLiveChatEnabled = false,
}: AppointmentTabsProps) => {
  const classes = sxStyles();

  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    switch (newValue) {
      case 0:
        logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENTS_UPCOMING);
        break;
      case 1:
        logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENTS_MISSED);
        break;
      case 2:
        logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENTS_OPEN);
        break;
      default:
        break;
    }
    setValue(newValue);
  };

  return (
    <Paper sx={classes.tabsPanel}>
      <Tabs value={value} onChange={handleChange} centered sx={classes.tabs}>
        <Tab label="Upcoming" sx={classes.unSelectedTab} />
        <Tab label="Missed" sx={classes.unSelectedTab} />
        <Tab label="Open" sx={classes.unSelectedTab} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AppointmentCardList
          appointmentType="upcoming"
          appointmentList={appointmentsListCategorized.upcoming}
          isLoading={isLoading}
          hasErrored={hasErrored}
          errorMessage={errorMessage}
          isLiveChatEnabled={isLiveChatEnabled}
        />
      </TabPanel>

      <TabPanel value={value} index={1}>
        <AppointmentCardList
          appointmentType="missed"
          appointmentList={appointmentsListCategorized.missed}
          isLoading={isLoading}
          hasErrored={hasErrored}
          errorMessage={errorMessage}
          isLiveChatEnabled={isLiveChatEnabled}
        />
      </TabPanel>

      <TabPanel value={value} index={2}>
        {!hasErrored &&
          (appointmentsListCategorized.open?.length ? (
            <Typography sx={classes.lastVisitTitle}>Your last visit</Typography>
          ) : null)}
        <AppointmentCardList
          appointmentType="open"
          appointmentList={appointmentsListCategorized.open}
          isLoading={isLoading}
          hasErrored={hasErrored}
          errorMessage={errorMessage}
          isLiveChatEnabled={isLiveChatEnabled}
        />
      </TabPanel>
    </Paper>
  );
};

export default AppointmentTabs;
