import { Theme } from "@mui/material/styles";
import theme from "lib/theme/theme";
import { size } from "lib/theme/size";
import { SharpNoticePanelStyleProps } from "./SharpNoticePanel.types";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = (props: SharpNoticePanelStyleProps) => {
  return {
    body: {
      background: getBgColor(props, theme),
      color: theme.palette.grey[400],
      fontSize: size.FONTSIZE_12,
      paddingLeft: theme.spacing(1),
      // whiteSpace: "pre-line",
    },
    infoBox: getBoxStyles(props, theme),
    infoIcon: getIconStyles(props, theme),
  } satisfies SxObjType;
};

/**
 * Resolves to the correct bg color based on props
 *
 * @param {SharpNoticePanelStyleProps} props  Style props
 * @param {Theme} theme  Global theme object
 *
 * @returns {string}  The hex code of the appropriate bg color
 */
const getBgColor = (props: SharpNoticePanelStyleProps, theme: Theme) => {
  if (props.bgColor === "warn") {
    return theme.palette.info.main;
  } else if (props.bgColor === "extremeWarn") {
    return theme.palette.info.light;
  } else {
    return theme.palette.secondary.main;
  }
};

/**
 * Resolves to the correct bg color based on props
 *
 * @param {SharpNoticePanelStyleProps} props  Style props
 * @param {Theme} theme  Global theme object
 *
 * @returns {string}  The hex code of the appropriate bg color
 */
const getBorderColor = (props: SharpNoticePanelStyleProps, theme: Theme) => {
  if (props.bgColor === "warn") {
    return theme.palette.primary.main;
  } else if (props.bgColor === "extremeWarn") {
    return theme.palette.error.dark;
  } else {
    return theme.palette.secondary.main;
  }
};

const getBoxStyles = (props: SharpNoticePanelStyleProps, theme: Theme) => {
  if (props.announcement) {
    return {
      background: theme.palette.info.light,
      padding: theme.spacing(1),
      // margin: theme.spacing(1),
      display: "flex",
      lineHeight: theme.typography.body1.lineHeight,
      borderRadius: theme.spacing(0.5),
      border: `1px solid ${theme.palette.error.dark}`,
    };
  } else {
    return {
      display: "flex",
      border: `1px solid ${getBorderColor(props, theme)}`,
      background: getBgColor(props, theme),
      padding: theme.spacing(1),
      lineHeight: theme.typography.body1.lineHeight,
      // margin: theme.spacing(1, 0, 2),
    };
  }
};

const getIconStyles = (props: SharpNoticePanelStyleProps, theme: Theme) => {
  if (props.announcement) {
    return {
      margin: theme.spacing(0.5),
      alignItems: "flex-start",
    };
  } else {
    return {
      margin: theme.spacing(0.1),
      alignSelf: "flex-start",
    };
  }
};

export { sxStyles };
