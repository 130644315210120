import { Box, FormControlLabel, Radio } from "@mui/material";
import { RadioButtonProps } from "./FullRadioButton.types";
import { sxStyles } from "./FullRadioButton.styles";
import IMAGES from "lib/assets/images";
import MapRawStringToReactElement from "lib/util/ReactComponentUtil/mapRawStringToReactElement/MapRawStringtoReactElement";

const RadioButton = ({ checked, label, value }: RadioButtonProps) => {
  const classes = sxStyles();

  return (
    <FormControlLabel
      control={
        <Radio
          icon={
            <Box
              component={"img"}
              src={IMAGES.general.RadioButtonUncheckedIcon}
              sx={classes.radioIcon}
            />
          }
          checkedIcon={
            <Box
              component={"img"}
              src={IMAGES.general.RadioButtonCheckedIcon}
              sx={classes.radioIcon}
            />
          }
          color="primary"
          sx={classes.radio}
        />
      }
      label={MapRawStringToReactElement(label)}
      value={value}
      sx={classes.radioButton}
    />
  );
};

export default RadioButton;
