import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CardActions,
  Box,
} from "@mui/material";
import { BillCardProps } from "./BillCard.types";
import { sxStyles } from "./BillCard.styles";
import { useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import PendingDisclaimer from "../../common/PendingDisclaimer/PendingDisclaimer";
import IMAGES from "lib/assets/images";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";

const BillCard1 = ({
  outstandingBill,
  addedBill,
  onClickBill,
}: BillCardProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();
  let dateTimeObj: Date | null = null;

  const isPending =
    outstandingBill != null
      ? outstandingBill.HasPendingArPosting ||
        outstandingBill.HasPaymentInPastThreeDays
      : false;

  dateTimeObj = getDateFromISOString(
    outstandingBill != null
      ? outstandingBill.SortDate
      : addedBill != null
        ? addedBill.BillDate
        : null,
  );
  return (
    <>
      <Card
        key={
          outstandingBill != null
            ? outstandingBill.InvoiceNumber
            : addedBill != null
              ? addedBill.InvoiceNumber
              : null
        }
        sx={classes.card}
        onClick={() => {
          onClickBill(
            outstandingBill != null
              ? outstandingBill.InvoiceNumber
              : addedBill != null
                ? addedBill.InvoiceNumber
                : null,
          );
          outstandingBill != null
            ? navigate(PATHS.PAYMENT_OUTSTANDING_BILL_DETAILS.path)
            : navigate(PATHS.PAYMENT_ADDED_BILL_DETAILS.path);
        }}
        data-testid="card"
      >
        <CardHeader
          title={
            dateTimeObj ? formatDate(formatDateToISO(dateTimeObj), "MMM") : null
          }
          subheader={
            dateTimeObj ? formatDate(formatDateToISO(dateTimeObj), "dd") : null
          }
          sx={classes.cardHeader}
          action={
            dateTimeObj
              ? formatDate(formatDateToISO(dateTimeObj), "yyyy")
              : null
          }
          data-testid="card-header"
        />
        <CardContent
          sx={
            dateTimeObj === null
              ? classes.cardDetailWrapper
              : classes.cardDetails
          }
          data-testid="card-content"
        >
          <Box sx={classes.cardDetailsWithoutDate}>
            <Typography sx={classes.cardTitle}>
              {outstandingBill != null
                ? outstandingBill.InstitutionName
                : addedBill != null
                  ? addedBill.InstitutionName
                  : null}
            </Typography>
            <Typography sx={classes.cardContent}>
              {addedBill != null ? addedBill.PatientName : null}
            </Typography>
            <Typography sx={classes.cardContent}>
              Bill Reference No:{" "}
              {outstandingBill != null
                ? outstandingBill.InvoiceNumber
                : addedBill != null
                  ? addedBill.InvoiceNumber
                  : null}
            </Typography>
            {isPending ? (
              <PendingDisclaimer />
            ) : (
              <>
                <Typography sx={classes.cardLabel}>Amount to Pay</Typography>
                <Typography sx={classes.cardAmount}>
                  {formatPayment(
                    Number(
                      outstandingBill != null
                        ? outstandingBill.AmountDueFromPatient
                        : addedBill != null
                          ? addedBill.PaymentAmount
                          : null,
                    ),
                  )}
                </Typography>
              </>
            )}
          </Box>
        </CardContent>
        <CardActions disableSpacing>
          <Box
            component={"img"}
            src={IMAGES.general.ArrowForward}
            sx={classes.icon}
          />
        </CardActions>
      </Card>
    </>
  );
};

export default BillCard1;
