import { Button, Box, Link, useTheme } from "@mui/material";
import { sxStyles } from "./AppointmentCardFooterSingleBtn.styles";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { PATHS } from "lib/routing/paths/paths";
import { AppointmentCardFooterSingleBtnProps } from "./AppointmentCardFooterSingleBtn.types";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { Appointment } from "api/appointment/GetAppointmentList/getAppointmentList.toUi.types";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { isSameDayReschedule } from "api/appointment/GetAppointmentList/mappers/isSameDayBooking";
import { useAppDispatch } from "lib/redux/hooks";
import {
  updateApptDetailForReschedule,
  updateInstitutionForCreate,
  updateServiceForCreate,
  updateInstitutionCodeForCreate,
} from "ui/appointment/ducks";
import { updateAppointmentMetaAppointmentType } from "ui/appointment/ducks/appointmentsSlice";
import { ACTIONS } from "lib/routing";
import { mobileNavigate } from "lib/routing/navigate/navigate";

const AppointmentCardFooterSingleBtn = ({
  btnText,
  appointment,
  isLiveChatEnabled = false,
}: AppointmentCardFooterSingleBtnProps) => {
  const dispatch = useAppDispatch();

  const theme = useTheme();
  const classes = sxStyles(theme);
  const navigate = useNavigate();

  const { type, canMake, institutionCode } = appointment;

  const onSelect = (appointment: Appointment) => {
    const {
      appointmentId,
      type,
      institutionName,
      institutionCode,
      datetime,
      sourceSystem,
      departmentCode,
      departmentName,
      location,
      consultType,
      logoCode,
      calendarTitle,
      slotId,
      calendarLocation,
      visitTypeId,
      serviceDisplayName: serviceName,
      slotSearchDateRangeFrom,
      slotSearchDateRangeTo,
      expectedOrderDate,
      rescheduleToDays,
    } = appointment;

    const serializedDateTime = formatDateToISO(datetime);
    const isSameDay = isSameDayReschedule(consultType, logoCode);

    const linkedAppointments = appointment.linkedAppointments;

    const linkedAppointmentsMetaData =
      linkedAppointments !== null
        ? [
            ...linkedAppointments.map((appt) => {
              return {
                appointmentId: appt.appointmentId,
                regAppointmentId: appt.regAppointmentId,
              };
            }),
          ]
        : [];

    if (type === "missed" || type === "open") {
      dispatch(
        updateApptDetailForReschedule({
          appointmentId,
          institutionName,
          location,
          serviceName,
          dateTime: serializedDateTime,
          institutionCode,
          sourceSystem,
          departmentCode,
          departmentName,
          slotId,
          logoCode,
          slotSearchDateRangeFrom,
          slotSearchDateRangeTo,
          isSameDayBooking: isSameDay,
          calendarTitle,
          calendarLocation,
          visitTypeId,
          expectedOrderDate,
          linkedAppointments: linkedAppointmentsMetaData,
          rescheduleToDays,
        }),
      );
      if (type === "open") {
        dispatch(updateAppointmentMetaAppointmentType("open"));
        if (institutionCode === "AH" || institutionCode === "NUH") {
          dispatch(updateInstitutionForCreate(institutionName));
          dispatch(updateServiceForCreate(serviceName));
          dispatch(updateInstitutionCodeForCreate(institutionCode));
        }
      } else {
        dispatch(updateAppointmentMetaAppointmentType("missed"));
      }
    }
  };

  return (
    <>
      {/* Online form submission is disabled for JCH only for both missed and open appointments*/}
      {!canMake ? (
        institutionCode === "JCH" ? null : (
          <Box display="flex" ml={1} mr={1} mb={1}>
            <Link
              component={RouterLink}
              to={
                type === "missed"
                  ? PATHS.APPOINTMENT_MISSED_FORM.path
                  : PATHS.APPOINTMENT_OPEN_FORM.path
              }
              onClick={() => {
                onSelect(appointment);
              }}
              sx={classes.btnLink}
            >
              {"Submit a request online"}
            </Link>
          </Box>
        )
      ) : (
        <Box sx={classes.footerContainer}>
          <Button
            variant="contained"
            sx={classes.fullButton}
            onClick={() => {
              onSelect(appointment);

              if (type === "missed") {
                logEventToGoogleAnalytics(EVENTS.CLICK_APPT_REBOOK_APPT);
                if (isLiveChatEnabled) {
                  mobileNavigate(
                    ACTIONS.LIVECHAT_PAGE_INTERCEPTION(
                      PATHS.APPOINTMENT_MOBILE.path,
                    ),
                  );
                } else {
                  navigate(PATHS.APPOINTMENT_MISSED_FORM.path);
                }
              }
              if (type === "open") {
                logEventToGoogleAnalytics(EVENTS.CLICK_APPT_BOOK_NEXT_APPT);
                if (isLiveChatEnabled) {
                  mobileNavigate(
                    ACTIONS.LIVECHAT_PAGE_INTERCEPTION(
                      PATHS.APPOINTMENT_MOBILE.path,
                    ),
                  );
                } else {
                  navigate(PATHS.APPOINTMENT_OPEN_FORM.path);
                }
              }
            }}
          >
            {btnText}
          </Button>
        </Box>
      )}
    </>
  );
};

export default AppointmentCardFooterSingleBtn;
