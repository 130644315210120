import { Typography, Box } from "@mui/material";
import { sxStyles } from "../../AppointmentForm.styles";
import { CreateApptFormFieldProps } from "./CreateApptFormField.types";
import nameof from "ts-nameof.macro";
import Dropdown from "lib/components/formInputs/Dropdown/Dropdown";
import MultiLineTextField from "lib/components/formInputs/MultiLineTextField/MultiLineTextField";
import ReferralBlock from "ui/appointment/components/createAppointment/components/ReferralBlock/ReferralBlock";

const CreateApptFormField = ({
  specialtyList,
  name,
  createApptFormField,
  handleChange,
}: CreateApptFormFieldProps) => {
  const classes = sxStyles();

  return (
    <>
      <Box sx={classes.layout}>
        <Typography variant="subtitle1" sx={classes.intensionText}>
          I want to <strong>book</strong> an appointment at:
        </Typography>
        <Typography variant="subtitle1" sx={classes.intensionLocationText}>
          {name}
        </Typography>
      </Box>

      {specialtyList.length > 0 && (
        <Box sx={classes.textBox}>
          <Dropdown
            name={nameof(createApptFormField.specialty)}
            label="Specialty"
            value={createApptFormField.specialty}
            items={specialtyList}
            handleChange={handleChange}
          />
        </Box>
      )}

      <ReferralBlock />

      {/* <FormControl component="fieldset" fullWidth> */}
      <Typography sx={classes.multiLineTextFieldLabel}>
        Please provide a brief description of your condition or symptoms.
      </Typography>
      <MultiLineTextField
        name={nameof(createApptFormField.description)}
        placeholder="Description"
        value={createApptFormField.description}
        rowNumber={3}
        maxNumber={5}
        maxCharLength={200}
        handleChange={handleChange}
      />
      {/* </FormControl> */}
    </>
  );
};

export default CreateApptFormField;
