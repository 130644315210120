import { useEffect, useState } from "react";
import { Box, Typography, Button, CircularProgress } from "@mui/material";
import { BillDetailProps } from "./BillDetail.types";
import { sxStyles } from "./BillDetail.styles";
import IMAGES from "lib/assets/images";
import ProfileHeaderContainer from "../../common/ProfileHeader/ProfileHeaderContainer";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import SingleLineTextField from "lib/components/formInputs/SingleLineTextField/SingleLineTextField";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import { useNavigate } from "react-router-dom";
import { ACTIONS, PATHS } from "lib/routing";
import {
  MAX_AMOUNT_PAYABLE_PER_TRANSACTION,
  MAX_DIGIT_OF_FRACTIONAL_PART,
  MAX_DIGIT_OF_WHOLE_NUMBER_PART,
  TRANSACTION_LIMIT_ERROR,
} from "../../Constants";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import IconTextButton from "lib/components/buttons/IconTextButton/IconTextButton";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import PendingDisclaimer from "../../common/PendingDisclaimer/PendingDisclaimer";

const BillDetail = ({
  memberIdentifier,
  billDetailsState,
  billInvoiceNumber,
  systemBill,
  isLoadingPendingMsg,
  clearPastReports,
  callGetBillSummaryDetails,
  setBillToPay,
  fetchPendingMsg,
  amountToPayStateValue,
}: BillDetailProps) => {
  const navigate = useNavigate();
  const classes = sxStyles();

  const isLoadingBillDetails = billDetailsState?.isLoading;
  const hasErroredBillDetails = billDetailsState?.hasErrored;
  const errorMessageBillDetails = billDetailsState?.errorMessage;
  const billDetails = billDetailsState?.billDetails;
  // const otherSubsidies = billDetails?.OtherSubsidies;

  const isBeingProceesed =
    systemBill.HasPendingArPosting || systemBill.HasPaymentInPastThreeDays;

  const downloadPdfForThisBill = () => {
    if (billDetails) {
      mobileNavigate(
        `${ACTIONS.GET_PDF_BILL_ACTION}&BillDate=${systemBill.RequestedDateTime}&BillingSystem=${systemBill.BillingSystem}&InstitutionCode=${systemBill.InstitutionCode}&DownloadId=${systemBill.DownloadId}&MemberIdentifier=${memberIdentifier}&BillCategory=Outstanding`,
      );
    }
  };

  useEffect(() => {
    // onLoad of the page
    callGetBillSummaryDetails(billInvoiceNumber, systemBill.InstitutionCode);
    fetchPendingMsg();
    logEventToGoogleAnalytics(EVENTS.VIEW_PAYMENT_LANDING);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let dateTimeObj: Date | null = null;
  dateTimeObj = getDateFromISOString(billDetails?.BillDate || null);
  const [amountToPay, setAmountToPay] = useState<string | null>(null);

  // TODO: change useEffect to function approach
  useEffect(() => {
    const amount =
      amountToPayStateValue !== null
        ? amountToPayStateValue
        : billDetails?.FinalAmountPayable || null;
    const formattedAmount = formatPayment(amount).slice(2);
    setAmountToPay(formattedAmount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billDetails]);

  const disablePayment = () => {
    if (isBeingProceesed) {
      return true;
    } else if (amountToPay && billDetails) {
      const payment = Number(amountToPay);
      if (
        payment <= billDetails.FinalAmountPayable &&
        payment <= MAX_AMOUNT_PAYABLE_PER_TRANSACTION &&
        payment > 0
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      <ProfileHeaderContainer title="'s Bills" />
      {isLoadingBillDetails || isLoadingPendingMsg ? (
        <Box
          display="flex"
          align-items="center"
          justifyContent="center"
          mt={4}
          p={5}
        >
          <CircularProgress />
        </Box>
      ) : hasErroredBillDetails ? (
        <ErrorDisplayContainer
          errorMessage={errorMessageBillDetails}
          onTryAgain={() => {
            clearPastReports();
            callGetBillSummaryDetails(
              billInvoiceNumber,
              systemBill.InstitutionCode,
            );
          }}
        />
      ) : (
        <>
          <Box sx={classes.card}>
            {!isBeingProceesed ? (
              <Box sx={classes.cardHeader}>
                <Typography sx={classes.cardMainTitle}>
                  Final Amount Payable
                </Typography>

                <Typography sx={classes.cardAmountValue}>
                  {formatPayment(billDetails?.FinalAmountPayable || null)}
                </Typography>
              </Box>
            ) : null}
            <Box sx={classes.cardDetailWrapper}>
              <Box sx={classes.cardHeadDetails}>
                <Box sx={classes.cardLeft}>
                  <Typography sx={classes.cardLabel}>
                    Bill Date
                    <Typography sx={classes.cardDate}>
                      {dateTimeObj
                        ? formatDate(
                            formatDateToISO(dateTimeObj),
                            "dd MMM yyyy",
                          )
                        : null}
                    </Typography>
                  </Typography>
                </Box>
                <Box sx={classes.cardRight}>
                  <IconTextButton
                    icon={IMAGES.general.DownloadIcon}
                    label={["Download", "bill"]}
                    onClick={async () => downloadPdfForThisBill()}
                    ariaLabel={"Download bill"}
                  />
                </Box>
              </Box>
              <Box pt={0.5}>
                <Box sx={classes.cardDetails}>
                  <Box>
                    <Typography sx={classes.cardTitle}>
                      {systemBill.InstitutionName}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={classes.billRef}>
                  <Typography sx={classes.billRefLabel}>
                    Bill Reference No:
                  </Typography>
                  <Typography sx={classes.billRefNumber}>
                    {billDetails?.InvoiceRefNo}
                  </Typography>
                </Box>
              </Box>
              {!isBeingProceesed ? (
                <Box sx={classes.cardFooter}>
                  <Box sx={classes.cardLeft}>
                    <Typography sx={classes.cardTitle}>
                      Final Amount Payable:
                    </Typography>
                  </Box>
                  <Box sx={classes.cardRight}>
                    <Box sx={classes.cardValueBox}>
                      $
                      <SingleLineTextField
                        type="decimal"
                        decimalPlaces={MAX_DIGIT_OF_FRACTIONAL_PART}
                        maxCharLength={MAX_DIGIT_OF_WHOLE_NUMBER_PART}
                        value={amountToPay}
                        disabled={false}
                        required={false}
                        error={
                          Number(amountToPay) >
                          MAX_AMOUNT_PAYABLE_PER_TRANSACTION
                        }
                        errorText={TRANSACTION_LIMIT_ERROR}
                        handleChange={(event) =>
                          setAmountToPay(event.target.value)
                        }
                      ></SingleLineTextField>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box sx={classes.cardDetails}>
                  <PendingDisclaimer />
                </Box>
              )}
            </Box>
          </Box>
          {!isBeingProceesed ? (
            <Box sx={classes.cardButtons}>
              <Button
                variant="contained"
                color="primary"
                sx={classes.fullButton}
                disabled={disablePayment()}
                onClick={() => {
                  setBillToPay({
                    InvNo: billDetails?.InvoiceRefNo ?? "",
                    Selected: true,
                    AmountToPay: amountToPay ?? "0",
                    AmountDue: amountToPay ?? "0",
                    IsFullyPaid:
                      Number(amountToPay) === billDetails?.FinalAmountPayable,
                  });
                  if (Number(amountToPay) === billDetails?.FinalAmountPayable) {
                    logEventToGoogleAnalytics(
                      EVENTS.SELECT_PAY_OUTSTANDING_BILL_UNEDITED,
                    );
                  } else {
                    logEventToGoogleAnalytics(
                      EVENTS.SELECT_PAY_OUTSTANDING_BILL_EDITED,
                    );
                  }
                  navigate(PATHS.PAYMENT_PAYOR_INFO.path);
                }}
              >
                Pay
              </Button>
            </Box>
          ) : null}
        </>
      )}
    </>
  );
};

export default BillDetail;
