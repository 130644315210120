import { getPractitionerList } from "api/appointment/GetPractitionerList/GetPractitionerList";
import { PractitionerGroups } from "api/appointment/GetPractitionerList/GetPractitionerList.types";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

const useGetPractitionerList = ({
  specialtyCode,
  institutionCode,
  memberIdentifier,
}: {
  specialtyCode: string;
  institutionCode: string;
  memberIdentifier: string;
}): {
  data: PractitionerGroups | null;
  isLoading: boolean;
  hasErrored: boolean;
  errorMessage: string | null;
} => {
  const [data, setData] = useState<PractitionerGroups | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrored, setHasErrored] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const responseData = await getPractitionerList({
          specialtyCode,
          institutionCode,
          memberIdentifier,
        });

        setData(responseData.PractitionerGroups);
        setHasErrored(false);
        setErrorMessage(null);
      } catch (error) {
        setData(null);
        setHasErrored(true);
        if (error instanceof AxiosError) {
          setErrorMessage(error.response?.data.Message);
        } else {
          setErrorMessage(null);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [institutionCode, memberIdentifier, specialtyCode]);

  return { data, isLoading, hasErrored, errorMessage };
};

export { useGetPractitionerList };
