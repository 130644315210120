import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { sxStyles } from "./Receipt.styles";
import { Box, Typography, CircularProgress } from "@mui/material";
import ErrorDisplayContainer from "lib/components/error/ErrorDisplay/ErrorDisplayContainer";
import ButtonsFooter from "lib/components/buttons/ButtonsFooter/ButtonsFooter";
import StatusCard from "./StatusCard/StatusCard";
import ReceiptCard from "./ReceiptCard/ReceiptCard";
import SummaryCard from "./SummaryCard/SummaryCard";
import { ACTIONS, PATHS } from "lib/routing";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import StepPageLayout from "../common/StepPageLayout/StepPageLayout";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { mobileNavigate } from "lib/routing/navigate/navigate";
import { ApiEnum } from "ui/payment/ducks/payment.types";
// Hooks
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { setCustomisedBack } from "lib/redux/navigation";
import {
  clearState,
  fetchPaymentInfo,
  resetApiStatus,
  setReceiptPageLanding,
} from "ui/payment/ducks";
import { selectPayments } from "ui/payment/ducks/selectors";
import {
  setMessageToSend,
  setshouldShowBackOnEntryHijack,
} from "lib/redux/navigation/navigationSlice";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";
import { MessageActions } from "lib/routing/messageChannel/messageActions";
import IconLink from "lib/components/links/IconLink/IconLink";

const buttonTitle = "View details";

const Receipt = () => {
  const navigate = useNavigate();
  const classes = sxStyles();
  const dispatch = useAppDispatch();

  const receiptPageLanding = useAppSelector(selectPayments).receiptPageLanding;
  const getReportInfoApiStatus =
    useAppSelector(selectPayments).apiStatus[ApiEnum.GetPaymentInfo];
  const paymentInfo = useAppSelector(selectPayments).payment.paymentInfo;
  const paymentPersist = useAppSelector(selectPayments).paymentPersist;

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  const downloadPdfForThisReceipt = () => {
    mobileNavigate(
      `${ACTIONS.GET_RECEIPT_PDF_ACTION}&MerchantRefNum=${paymentPersist.merchantRefNumber}`,
    );
  };

  // log page to GA on mount
  useEffect(() => {
    logEventToGoogleAnalytics(EVENTS.VIEW_PAYMENT_RECEIPT);

    // reset to unfreeze title
    dispatch(
      setMessageToSend(
        MessageActions.staticTitle({ title: "", isEnabled: false }),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // only eNETS / Direct Debit will show the page landing ("Check Payment Status" button page)
  // for Credit Card payment, will get PaymentInfo on load of the page
  useEffect(() => {
    // restrict loading of the receipt page only after persisted data is stored back to redux
    if (
      paymentPersist.paymentToken &&
      (paymentPersist.selectedPaymentMode === "CC" ||
        paymentPersist.selectedPaymentMode === "AMEX")
    ) {
      dispatch(
        fetchPaymentInfo(
          paymentPersist.paymentToken,
          paymentPersist.merchantRefNumber,
          paymentPersist.merchantRefNumber,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentPersist]);

  useEffect(() => {
    if (messageActionObj?.action === "backTriggered") {
      dispatch(clearState());
      logEventToGoogleAnalytics(EVENTS.SELECT_OK_SUCCESSFUL_RECEIPT);
      navigate(PATHS.PAYMENT_MOBILE.path, { replace: true });
      consumeMessageActionObj();

      // TODO: remove this line after the back button issue is resolved
      dispatch(setshouldShowBackOnEntryHijack(true));
      dispatch(setCustomisedBack(false));
    }
  }, [consumeMessageActionObj, dispatch, messageActionObj?.action, navigate]);

  // Status Card props
  const paymentStatusCode = paymentInfo.PaymentStatus;
  const statusMessage = paymentInfo.StatusMessage;
  const toEmail = paymentInfo.PayorDetails?.Email ?? paymentPersist.email;

  const RECEIPT_DATE_FORMAT = "dd MMMM yyyy, h:mm a";

  // Receipt Card props
  const payorName =
    (paymentInfo.PayorDetails?.PayorName ?? paymentPersist.payorName) || "-";
  const receiptDate =
    formatDate(paymentInfo.ReceiptDate, RECEIPT_DATE_FORMAT) || "-";
  const receiptID = paymentInfo.ReceiptNumber || "-";
  // eNETS / Direct Debit won't have card number
  const hasPaymentCardNo =
    paymentPersist.selectedPaymentMode === "CC" ||
    paymentPersist.selectedPaymentMode === "AMEX";
  const paymentCardNo = paymentInfo.PaymentCardNumber || "-";
  const totalPaymentAmount = formatPayment(paymentInfo.TotalAmount);

  // Summary Card props
  const receiptSummaryList = paymentInfo.PaymentDetails;

  return (
    <StepPageLayout currentStepIndex={2}>
      {!paymentPersist.selectedPaymentMode ||
      getReportInfoApiStatus.isLoading ? (
        <Box display="flex" align-items="center" justifyContent="center" mt={4}>
          <CircularProgress />
        </Box>
      ) : receiptPageLanding && paymentPersist.selectedPaymentMode === "DD" ? (
        <Box px={2} sx={classes.checkStatusBox}>
          <ButtonsFooter
            nextButtonText={buttonTitle}
            onClickNext={() => {
              dispatch(setReceiptPageLanding(false));
              dispatch(
                fetchPaymentInfo(
                  paymentPersist.paymentToken,
                  paymentPersist.merchantRefNumber,
                  paymentPersist.merchantRefNumber,
                ),
              );
            }}
            hideCancelButton={true}
            cancelButtonText=""
          />
        </Box>
      ) : getReportInfoApiStatus.hasErrored ? (
        <>
          <ErrorDisplayContainer
            errorMessage={getReportInfoApiStatus.errorMessage}
            onTryAgain={() => {
              dispatch(resetApiStatus());
              dispatch(
                fetchPaymentInfo(
                  paymentPersist.paymentToken,
                  paymentPersist.merchantRefNumber,
                  paymentPersist.merchantRefNumber,
                ),
              );
            }}
          />
          <Box px={2}>
            <ButtonsFooter
              hideNextButton={true}
              nextButtonText=""
              cancelButtonText="Back to Home"
              onClickCancel={() => {
                dispatch(setshouldShowBackOnEntryHijack(true));
                dispatch(setCustomisedBack(false));
                dispatch(clearState());
                navigate(PATHS.PAYMENT_MOBILE.path, { replace: true });
              }}
            />
          </Box>
        </>
      ) : (
        <Box py={1} sx={classes.receiptLayout}>
          <StatusCard
            paymentStatusCode={paymentStatusCode}
            statusMessage={statusMessage}
            toEmail={toEmail}
          />
          <ReceiptCard
            payorName={payorName}
            receiptID={receiptID}
            receiptDate={receiptDate}
            hasPaymentCardNo={hasPaymentCardNo}
            paymentCardNo={paymentCardNo}
            payAmount={totalPaymentAmount}
          />
          <Typography sx={classes.title}>Summary</Typography>
          {receiptSummaryList.map((item) => {
            return (
              <Box key={item.ReferenceNumber} sx={classes.summaryCard}>
                <SummaryCard
                  patientName={item.PatientName}
                  patientNRIC={item.TargetIdentifier}
                  institutionName={item.InstitutionName}
                  billReferenceNumber={item.ReferenceNumber}
                  amount={formatPayment(item.Amount)}
                />
              </Box>
            );
          })}
          {paymentStatusCode === "S" && (
            <Box sx={classes.cardRight}>
              <IconLink
                icon="blueDownload"
                onClick={async () => downloadPdfForThisReceipt()}
                title={"Download receipt"}
              />
            </Box>
          )}
          <Box px={2}>
            {paymentStatusCode === "S" ? (
              <ButtonsFooter
                nextButtonText="Done"
                onClickNext={() => {
                  dispatch(setshouldShowBackOnEntryHijack(true));
                  dispatch(setCustomisedBack(false));
                  dispatch(clearState());
                  logEventToGoogleAnalytics(
                    EVENTS.SELECT_OK_SUCCESSFUL_RECEIPT,
                  );
                  navigate(PATHS.PAYMENT_MOBILE.path, { replace: true });
                }}
                hideCancelButton={true}
                cancelButtonText=""
              />
            ) : paymentStatusCode === "F" ? (
              <ButtonsFooter
                nextButtonText="Try again"
                onClickNext={() => {
                  dispatch(setReceiptPageLanding(true));
                  dispatch(resetApiStatus());
                  navigate(PATHS.PAYMENT_PAYOR_INFO_TRY_AGAIN.path, {
                    replace: true,
                  });
                }}
                cancelButtonText="Back to Home"
                onClickCancel={() => {
                  dispatch(setshouldShowBackOnEntryHijack(true));
                  dispatch(setCustomisedBack(false));
                  dispatch(clearState());
                  logEventToGoogleAnalytics(
                    EVENTS.SELECT_BACK_TO_HOME_UNSUCCESSFUL_RECEIPT,
                  );
                  navigate(PATHS.PAYMENT_MOBILE.path, { replace: true });
                }}
              />
            ) : (
              <ButtonsFooter
                hideNextButton={true}
                nextButtonText=""
                cancelButtonText="Back to Payment"
                onClickCancel={() => {
                  dispatch(setshouldShowBackOnEntryHijack(true));
                  dispatch(setCustomisedBack(false));
                  dispatch(clearState());
                  logEventToGoogleAnalytics(
                    EVENTS.SELECT_BACK_TO_PAYMENT_PENDING_RECEIPT,
                  );
                  navigate(PATHS.PAYMENT_MOBILE.path, { replace: true });
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </StepPageLayout>
  );
};

export default Receipt;
