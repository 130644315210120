import { Box, Typography } from "@mui/material";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { AvailableSlotsHeaderProps } from "./AvailableSlotsHeader.types";
import SelectionListingHeader from "../../selection-listing/SelectionListingHeader/SelectionListingHeader";
import SelectionListingHeaderText from "../../selection-listing/SelectionListingHeaderText/SelectionListingHeaderText";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";
import { sxStyles } from "./AvailableSlotsHeader.styles";
import { useTheme } from "@mui/material";
import HeaderBlock from "ui/appointment/components/createAppointment/components/HeaderBlock/HeaderBlock";

const AvailableSlotsHeader = ({
  location,
  service,
  specialist,
  lastVisitDatetime = null,
  currentDateTime = null,
  inAdvanceNoticeComponent,
  appointmentType,
}: AvailableSlotsHeaderProps) => {
  const classes = sxStyles();
  const theme = useTheme();

  return (
    <SelectionListingHeader>
      <Box
        style={{
          backgroundColor: theme.palette.secondary.main,
          padding: "4px 0",
        }}
      >
        {appointmentType === "open" && lastVisitDatetime && (
          <SelectionListingHeaderText
            label="Your last visit"
            text={formatDate(formatDateToISO(lastVisitDatetime))}
            sameLine={false}
          />
        )}

        {currentDateTime &&
          (appointmentType === "missed" ? (
            <Box sx={classes.currentDateTimeBox}>
              <Typography sx={classes.currentDateAndTimeLabel}>
                We missed you on
              </Typography>
              <Typography
                variant="subtitle1"
                sx={classes.currentDateAndTimeText}
              >
                <b>{currentDateTime}</b>
              </Typography>
            </Box>
          ) : (
            // for upcoming appointment rescheduling (appointmentType = NULL or upcoming)
            // dashboard to slots page will result in NULL appointment type
            <SelectionListingHeaderText
              label="Current Date and Time"
              text={currentDateTime}
              sameLine={false}
            />
          ))}

        <HeaderBlock
          location={location}
          service={service}
          specialist={specialist}
        />
      </Box>
      {inAdvanceNoticeComponent}
    </SelectionListingHeader>
  );
};

export default AvailableSlotsHeader;
