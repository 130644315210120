import React from "react";
import { Box, Typography } from "@mui/material";
import { TransactionHistoryCardProps } from "./TransactionHistoryCard.types";
import { sxStyles } from "./TransactionHistoryCard.styles";
import { getDateFromISOString } from "lib/util/DateTimeUtil/getDateFromISOString/getDateFromISOString";
import { IconButton } from "@mui/material";
// import clsx from "clsx";
import Collapse from "@mui/material/Collapse";
import SummaryCard from "../SummaryCard/SummaryCard";
import { formatPayment } from "lib/util/StringUtil/formatPayment/formatPayment";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import Tags from "lib/components/tags/Tags";
import IMAGES from "lib/assets/images";
import { formatDateToISO } from "lib/util/DateTimeUtil/formatDateToISO/formatDateToISO";
import { formatDate } from "lib/util/DateTimeUtil/formatDate/formatDate";

const TransactionHistoryCard = ({
  paymentMethod,
  paymentDetails,
  merchantRefNum,
  totalAmountPaid,
  paymentDate,
  paymentStatus,
}: TransactionHistoryCardProps) => {
  const classes = sxStyles();

  let dateTimeObj: Date | null = null;
  dateTimeObj = getDateFromISOString(paymentDate);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    if (expanded === false)
      logEventToGoogleAnalytics(EVENTS.EXPAND_TRANSACTION_HISTORY);
    setExpanded(!expanded);
  };

  return (
    <Box sx={classes.cardPanel}>
      <Box sx={classes.cardDetailWrapper}>
        <Box sx={classes.cardHeader}>
          <Typography sx={classes.cardHeaderLeft}>
            {dateTimeObj
              ? formatDate(
                  formatDateToISO(dateTimeObj),
                  "dd MMM yyyy, hh:mm a",
                ).toUpperCase()
              : null}
          </Typography>
          <Typography sx={classes.cardHeaderRight}>
            <IconButton
              sx={[
                classes.expand,
                expanded === true ? classes.expandOpen : classes.expand,
              ]}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <Box component={"img"} src={IMAGES.general.AccordionIcon} />
            </IconButton>
          </Typography>
        </Box>
        <Box sx={classes.cardDetails}>
          <Box sx={classes.cardLeft}>
            <Typography sx={classes.cardLabel}>Payment Receipt ID</Typography>
            <Typography sx={classes.cardTitle}>{merchantRefNum}</Typography>
          </Box>
          <Box sx={classes.cardRight}>
            <Typography sx={classes.cardLabel}>Mode of Payment</Typography>
            <Typography sx={classes.cardTitle}>{paymentMethod}</Typography>
          </Box>
        </Box>
        <Box sx={classes.cardDetails}>
          <Box sx={classes.cardLeft}>
            <Typography sx={classes.cardHeaderLeft}>
              Total Paid Amount:
            </Typography>
          </Box>
          <Box sx={classes.cardRight}>
            <Typography sx={classes.cardAmount}>
              {formatPayment(totalAmountPaid)}
            </Typography>
            <Box sx={classes.cardTitle}>
              <Tags
                label={paymentStatus?.toLocaleUpperCase() ?? null}
                colorChoice={
                  paymentStatus?.toLocaleLowerCase() === "successful"
                    ? "green"
                    : paymentStatus?.toLocaleLowerCase() === "processing"
                      ? "lightOrange"
                      : paymentStatus?.toLocaleLowerCase() === "failed"
                        ? "red"
                        : "grey"
                }
              ></Tags>
            </Box>
          </Box>
        </Box>

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box sx={classes.cardSummaryDetails}>
            <Box sx={classes.cardLeft}>
              <Typography sx={classes.cardHeaderLeft}>Summary</Typography>
            </Box>
          </Box>
          {paymentDetails.map((item) => {
            return (
              <Box
                key={item.ReferenceNumber}
                sx={classes.cardSummaryDetailWrapper}
              >
                <SummaryCard
                  billReferenceNumber={item.ReferenceNumber}
                  institutionName={item.InstitutionName}
                  paidAmount={item.AmountPaid}
                />
              </Box>
            );
          })}
        </Collapse>
      </Box>
    </Box>
  );
};

export default TransactionHistoryCard;
