import { useLocation, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import { PATHS, retrievePath } from "lib/routing";
import { AppRouteRendererProps } from "./AppRouteRenderer.types";
import { Path } from "lib/routing/paths/paths.types";
import { sxStyles } from "./AppRouteRenderer.styles";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import { ROUTES } from "../GlobalRoutes/GlobalRoutes";
import ErrorModal from "./ErrorModal/ErrorModal";
import { containsDynamicBackButtonConfigPath } from "../customizedRoutes/routesWithDynamicBackButtonDisplayConfig";
import { useEffect, useRef } from "react";
import CustomOidcSecure from "lib/components/auth/CustomOidcSecure/CustomOidcSecure";
import {
  HIDDEN_BACK_BUTTON_PATHS,
  LANDING_PAGE_PATHS,
  NON_LANDING_PAGE_PATHS_BACK_DISABLE,
} from "lib/routing/messageChannel/pathsToSendMessage";
import { MessageActions } from "lib/routing/messageChannel/messageActions";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import { selectNavigation } from "lib/redux/navigation/selectors";
import { setMessageToSend } from "lib/redux/navigation/navigationSlice";
import { initializeMessageChannel } from "lib/routing/messageChannel/initializeMessageChannel";
import { MessageActionType } from "lib/routing/messageChannel/messageActions.types";
import { sendIOSMessage } from "index";

/**
 * The BrowserRouter for the entire application. Mount this component in order
 * to register all of the application's routes provided by the routing module.
 */
const AppRouteRenderer = ({
  openFlagErrorModal,
  messageErrorModal,
  customizedErrorModalButtonText,
  customizedNavBarDisplayName,
  closeErrorModal,
}: AppRouteRendererProps) => {
  const classes = sxStyles();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const path = retrievePath(pathname);
  const title = path?.displayName
    ? path?.displayName
    : customizedNavBarDisplayName;

  const { messageToSend, isAcknowledged } = useAppSelector(selectNavigation);

  const sendAndroidMessageRef = useRef<
    ((messageAction: MessageActionType | string) => void) | null
  >();

  if (!isAcknowledged) {
    // Initialize Android message channel and storing android message port to ref
    sendAndroidMessageRef.current =
      initializeMessageChannel.android().sendAndroidMessage;
  }

  // This useEffect is responsible for sending messages to mobile
  useEffect(() => {
    if (!messageToSend) {
      return;
    }

    if (isAcknowledged && navigator.userAgent.includes("Android")) {
      sendAndroidMessageRef.current?.(messageToSend);
      dispatch(setMessageToSend(""));
    } else if ((window as any).webkit?.messageHandlers?.iosMessageHandler) {
      sendIOSMessage(messageToSend);
      dispatch(setMessageToSend(""));
    }
  }, [dispatch, isAcknowledged, messageToSend]);

  // This useEffect is for sending mobile messages from this page
  // Do not use dispatch to redux to update message to send on this page because it will conflict with children that also dispatch to messageToSend
  useEffect(() => {
    if (navigator.userAgent.includes("Android")) {
      // Send a message to mobile to indicate that the user has landed on a landing page
      sendAndroidMessageRef.current?.(
        MessageActions.landing(LANDING_PAGE_PATHS.includes(path?.path ?? "")),
      );

      if (HIDDEN_BACK_BUTTON_PATHS.includes(path?.path ?? "")) {
        sendAndroidMessageRef.current?.(MessageActions.back("HIDDEN"));
      } else if (
        NON_LANDING_PAGE_PATHS_BACK_DISABLE.includes(path?.path ?? "")
      ) {
        sendAndroidMessageRef.current?.(MessageActions.back("DISABLED"));
      } else {
        sendAndroidMessageRef.current?.(MessageActions.back("ENABLED"));
      }
    } else if ((window as any).webkit?.messageHandlers?.iosMessageHandler) {
      sendIOSMessage(
        MessageActions.landing(LANDING_PAGE_PATHS.includes(path?.path ?? "")),
      );

      if (HIDDEN_BACK_BUTTON_PATHS.includes(path?.path ?? "")) {
        sendIOSMessage(MessageActions.back("HIDDEN"));
      } else if (
        NON_LANDING_PAGE_PATHS_BACK_DISABLE.includes(path?.path ?? "")
      ) {
        sendIOSMessage(MessageActions.back("DISABLED"));
      } else {
        sendIOSMessage(MessageActions.back("ENABLED"));
      }
    }
  }, [dispatch, path, isAcknowledged]);

  //  ============
  // Title setter
  // =============
  useEffect(() => {
    document.title = title ?? "";
  }, [title]);

  return (
    <>
      <ScrollToTop />
      <Box sx={classes.noNav}>
        <Routes>
          {ROUTES.map(({ path, component: Component }) => {
            if (
              path === PATHS.PAYMENT_RECEIPT_DD.path ||
              (window.location.href.includes("localhost") &&
                path === PATHS.UI_SANDBOX.path)
            ) {
              return (
                <Route
                  key={path}
                  path={path}
                  element={
                    <CustomOidcSecure isDisabled>
                      <Component />
                    </CustomOidcSecure>
                  }
                />
              );
            } else {
              return (
                <Route
                  key={path}
                  path={path}
                  element={
                    <CustomOidcSecure>
                      <Component />
                    </CustomOidcSecure>
                  }
                />
              );
            }
          })}
        </Routes>

        <ErrorModal
          open={openFlagErrorModal}
          path={path}
          errorMessage={messageErrorModal}
          customizedButtonText={customizedErrorModalButtonText}
          closeErrorModal={closeErrorModal}
        />
      </Box>
    </>
  );
};

// ======================
// Helper functions
// ======================

/**
 * This function determines whether to show a back button based on certain conditions.
 * @param {Path | null} path - The `path` parameter is of type `Path` or `null`, and it represents the
 * current path in the application.
 * @param {boolean} shouldShowBackButtonOnEntry - `shouldShowBackButtonOnEntry` is a boolean flag
 * indicating whether the back button should be shown on entry.
 * @param {boolean | null} shouldShowBackOnEntryHijack - The `shouldShowBackOnEntryHijack` parameter is
 * a boolean value that determines whether the back button should be shown on entry hijack. If it is
 * set to `true`, the back button should be shown on entry hijack. If it is set to `false`, the back
 * button
 * @returns either `shouldShowBackButtonOnEntry`, `shouldShowBackOnEntryHijack`, or
 * `path?.allowGoBackInHistory` based on the conditions provided in the function.
 */
const shouldShowBackButton = (
  path: Path | null,
  shouldShowBackButtonOnEntry: boolean,
  shouldShowBackOnEntryHijack: boolean | null,
) => {
  if (containsDynamicBackButtonConfigPath(path?.path || "") === true) {
    return shouldShowBackButtonOnEntry;
  }

  if (shouldShowBackOnEntryHijack !== null) {
    return shouldShowBackOnEntryHijack;
  }

  return path?.allowGoBackInHistory;
};

export default AppRouteRenderer;
export const exportForTesting = { shouldShowBackButton };
