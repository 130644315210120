import { size } from "lib/theme/size";
import theme from "lib/theme/theme";
import { SxObjType } from "lib/theme/types/SxObjType.type";

const sxStyles = () => {
  return {
    statusSection: {
      py: theme.spacing(1),
      textAlign: "center",
      borderBottom: "1px solid " + theme.palette.secondary.light,
    },
    title: {
      color: theme.palette.text.primary,
      typography: theme.typography.header4_semibold,
      padding: theme.spacing(1, 0, 0.5),
    },
    content: {
      color: theme.palette.grey[600],
      typography: theme.typography.caption1_semibold,
      padding: theme.spacing(1, 0, 0.5),
    },
    emailLInk: {
      color: theme.palette.grey[600],
      fontSize: size.FONTSIZE_12,
      textDecoration: "none",
      pointerEvents: "none",
    },
    successIcon: {
      marginTop: theme.spacing(-2),
    },
    statusIcon: {
      marginTop: theme.spacing(2),
    },
  } satisfies SxObjType;
};

export { sxStyles };
