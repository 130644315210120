import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import getMsFromMinutes from "lib/util/DateTimeUtil/getMsFromMinutes/getMsFromMinutes";
import { EVENTS } from "lib/util/GoogleAnalyticsUtil/events";
import { logEventToGoogleAnalytics } from "lib/util/GoogleAnalyticsUtil/logEvent";
import { Box, Typography } from "@mui/material";
import Page from "lib/components/layout/Page/Page";
import AnnouncementsPanelSkeleton from "lib/components/notice/AnnouncementPanel/AnnouncementPanelSkeleton";
import PullToRefreshWrapper from "lib/components/pullToRefreshWrapper/PullToRefreshWrapper";
import { crossClusterUrl } from "lib/configs/configs/config";
import { navigateToExternalInMobileBrowser } from "lib/routing/navigate/navigate";
import UserProfileContainer from "../../common/user/UserProfile/UserProfileContainer";
import AppointmentTabs from "../AppointmentTabs/AppointmentTabs";
import RegisteredAppointmentAreaContainer from "../RegisteredAppointmentArea/RegisteredAppointmentAreaContainer";
import { sxStyles } from "./AppointmentPage.styles";
import SharpNoticePanel from "lib/components/notice/RoundedNoticePanel/SharpNoticePanel";
import ExternalLinkCard from "lib/components/cards/ExternalLinkCard/ExternalLinkCard";
import { useAppDispatch, useAppSelector } from "lib/redux/hooks";
import {
  selectAppointmentsAnnouncements,
  selectAppointmentsInit,
  selectAppointmentsSystemSettings,
} from "ui/appointment/ducks/selectors";
import {
  clearAppointmentMetaState,
  clearCreateAppointmentState,
  clearRescheduleAppointmentState,
  clearRescheduleLinkedAppointmentState,
  clearRegistrationAppointmentState,
  clearDocumentByTypeState,
  fetchAppointments,
  fetchAnnouncements,
  fetchSystemSettings,
} from "ui/appointment/ducks";
import {
  clearAnnouncementsState,
  setIsPreConfirmationModalOpen,
} from "ui/appointment/ducks/appointmentsSlice";
import { useGetMessageAction } from "lib/routing/messageChannel/hooks/useGetMessageAction";
import { useGetLiveChatFlag } from "ui/appointment/hooks/useGetLiveChatFlag";

const OTHER_APPT_TITLE = "From Other Providers";

const AppointmentPage = () => {
  const classes = sxStyles();
  const dispatch = useAppDispatch();
  const { isLiveChatEnabled, isLiveChatLoading } = useGetLiveChatFlag();

  // Redux states
  const {
    isLoading: isInitLoading,
    hasErrored: initHasErrored,
    errorMessage: initErrorMessage,
    listing: appointmentsListCategorized,
  } = useAppSelector(selectAppointmentsInit);

  const {
    isLoading: isSystemSettingsLoading,
    hasErrored: systemSettingsHasErrored,
    errorMessage: systemSettingsErrorMessage,
  } = useAppSelector(selectAppointmentsSystemSettings);

  const {
    isLoading: isAnnouncementLoading,
    hasAnnouncement,
    announcementMsg,
    hasErrored: announcementHasErrored,
  } = useAppSelector(selectAppointmentsAnnouncements);

  const isLoading =
    isInitLoading || isSystemSettingsLoading || isLiveChatLoading;
  const hasErrored = initHasErrored || systemSettingsHasErrored;
  const errorMessage = initErrorMessage || systemSettingsErrorMessage;

  // Local states
  const [isRefreshed, setIsRefreshed] = useState(false);
  const [queueStatusFlag, setQueueStatusFlag] = useState(true); // to control refresh of queueStatus

  const actualisedAppointmentsMemo = useMemo(
    () => appointmentsListCategorized.actualised,
    [appointmentsListCategorized.actualised],
  );

  const refreshTimeStampRef = useRef(0);

  const onLoad = useCallback(() => {
    // Clear relevant state slices, at this point, because this page is the start of
    // all appointment creation, reschedule, cancel and registration flows.
    dispatch(clearAppointmentMetaState());
    dispatch(clearCreateAppointmentState());
    dispatch(clearRescheduleAppointmentState());
    dispatch(clearRescheduleLinkedAppointmentState());
    dispatch(clearRegistrationAppointmentState());
    dispatch(clearDocumentByTypeState());
    dispatch(clearAnnouncementsState());
    dispatch(setIsPreConfirmationModalOpen(true));

    // Make API call to fetch appointment lists (for this page and subsequest pages that reference appointments)
    dispatch(fetchAppointments());

    // Make API call to fetch disclaimer list
    dispatch(fetchSystemSettings("VIRTUALCONSULT", "APPOINTMENT", null, null));

    // Make API call to fetch announcements for Appointment module
    dispatch(fetchAnnouncements("OneNUHSAppointments"));

    // Updates refresh timeStamp
    refreshTimeStampRef.current = Math.floor(Date.now() / 1000);
  }, [dispatch]);

  const onRefresh = useCallback(
    (isFromMobile: boolean) => {
      const timeDifference =
        Math.floor(Date.now() / 1000) - refreshTimeStampRef.current;
      const isPast60Seconds = () => timeDifference >= 60;

      return new Promise((resolve) => {
        if (isFromMobile || isPast60Seconds()) {
          onLoad();
          setQueueStatusFlag(!queueStatusFlag);
        }

        setTimeout(resolve, 0);
        setIsRefreshed(!isRefreshed);
      });
    },
    [isRefreshed, onLoad, queueStatusFlag],
  );

  const { messageActionObj, consumeMessageActionObj } = useGetMessageAction();

  useEffect(() => {
    // Load data every time this component mounts
    onLoad();
    logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENTS_LANDING);

    if (actualisedAppointmentsMemo) {
      logEventToGoogleAnalytics(EVENTS.VIEW_APPOINTMENTS_REGISTERED);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Disable autorefresh only for NTFGH
    if (
      actualisedAppointmentsMemo?.find((appt) => {
        return appt.institutionCode === "NTFGH";
      })
    ) {
      return;
    }

    const refreshTimer = setTimeout(() => {
      // auto refresh on every given setTimeout in minutes
      onRefresh(false);
    }, getMsFromMinutes(5));

    // react cleanup function to remove setTimeout
    return () => {
      setIsRefreshed(!isRefreshed);
      clearTimeout(refreshTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshed]);

  // Mobile will send a message to force refresh when user switches modules from bottom tab
  useEffect(() => {
    if (messageActionObj?.action === "refreshLanding") {
      onRefresh(true);
      consumeMessageActionObj();
    }
  }, [consumeMessageActionObj, messageActionObj?.action, onRefresh]);

  return (
    <Page>
      <PullToRefreshWrapper refreshData={() => onRefresh(false)}>
        <Box mt={5.5}>
          <UserProfileContainer />
        </Box>

        {isAnnouncementLoading ? (
          <AnnouncementsPanelSkeleton />
        ) : (
          hasAnnouncement &&
          announcementMsg &&
          !announcementHasErrored && (
            <Box m={1}>
              <SharpNoticePanel
                bgColor="warn"
                announcement={true}
                showError={true}
                announcementMessage={announcementMsg}
                children={null}
              />
            </Box>
          )
        )}

        {actualisedAppointmentsMemo &&
        actualisedAppointmentsMemo.length >= 1 ? (
          <>
            <Box sx={classes.title}>Current Appointment</Box>
            {actualisedAppointmentsMemo.map((appt, index) => {
              return (
                <RegisteredAppointmentAreaContainer
                  key={`actualisedAppointments${index}`}
                  appointment={appt}
                  queueStatusFlag={queueStatusFlag}
                  onRefreshAll={() => onRefresh(false)}
                />
              );
            })}
          </>
        ) : null}

        <Box>
          <AppointmentTabs
            isLoading={isLoading}
            hasErrored={hasErrored}
            errorMessage={errorMessage}
            appointmentsListCategorized={appointmentsListCategorized}
            isLiveChatEnabled={isLiveChatEnabled}
          />
        </Box>

        <Box sx={classes.bottomTitleContainer}>
          <Typography sx={classes.bottomTitle}>{OTHER_APPT_TITLE}</Typography>
          <ExternalLinkCard
            externalLinkObject={[
              {
                title: "Appointment",
                endIcon: "externalLink",
                onClickCard: () => {
                  logEventToGoogleAnalytics(
                    EVENTS.CLICK_APPT_OTHER_PROVIDERS_APPT,
                  );
                  navigateToExternalInMobileBrowser(crossClusterUrl(), false);
                },
              },
            ]}
          />
        </Box>
      </PullToRefreshWrapper>
    </Page>
  );
};

export default AppointmentPage;
