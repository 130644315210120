import * as TITLES from "../pageTitles/pageTitles";
import { AppPaths } from "./paths.types";

/**
 * == PATHS ==
 *
 * This file contains routes for the entire application, including its individual modules.
 *
 * The paths listed here are actually sub-paths from the domain of this application's URL.
 * These sub-paths should be relative to the base domain URL.
 *
 * For example, if the base domain URL = "https://dev.domain.nuhs.edu.sg", then routing to
 * "/appointments" would yield the URL: "https://dev.domain.nuhs.edu.sg/appointments"
 */
// === Profile Module ===
const PROFILE_PATH = "/profile";

// === Appointments Module ===
const APPOINTMENT_PATH = "/appointments";
const CREATE_APPOINTMENT_PATH = `${APPOINTMENT_PATH}/create`;
const RESCHEDULE_APPOINTMENT_PATH = `${APPOINTMENT_PATH}/reschedule`;
const RESCHEDULE_LINKED_APPOINTMENT_PATH = `${APPOINTMENT_PATH}/reschedule/linked`;
const RESCHEDULE_BUNDLED_LINKED_APPOINTMENT_PATH = `${APPOINTMENT_PATH}/reschedule/bundledLinked`;
const CANCEL_APPOINTMENT_PATH = `${APPOINTMENT_PATH}/cancel`;
const REGISTER_APPOINTMENT_PATH = `${APPOINTMENT_PATH}/register`;

// === MedRefill Module ===
const MED_PATH = "/medrefill";
const MED_TOPUP_PATH = `${MED_PATH}/topup`;
const MED_REFILL_PATH = `${MED_PATH}/refill`;
const MED_VIRTUALCONSULT_PATH = `${MED_PATH}/orderMedication`;

// === My Health Map Module ===
const MHM_PATH = "/myHealthMap";
const MHM_FIRST_TIME_USER_PATH = `${MHM_PATH}/firstTimeUser`;
const MHM_ENROLLED_USER_PATH = `${MHM_PATH}/enrolledUser`;

// === MRRP Module ===
const MRRP_PATH = "/mrrp";
const MRRP_PATH_CAP = "/MRRP";
const MRRP_NEW_REQUEST = `${MRRP_PATH}/newRequest`;

// === Health Together Module ===
const HT_PATH = "/ht";

// === Payments Module ===
const PAYMENT_PATH = "/payments";

// === For any special handling ===
// specific sub paths required to distinguish pages that require specially customized forms of navigation,
// e.g. prevent user from "going back" using the navigation bar.
const SPECIAL_HANDLING_STATIC = "static";

// ==============================
// === For Mobile Integration ===
// ==============================
// specific sub paths required to distinguish pages that are rendered by navigation from the mobile client directly
// each of these sub paths may require special handling on the mobile client
const MOBILE_PATH = "from-mobile";

/**
 * === APP PATHS ===
 *
 * Top level application routes leading to specific modules.
 * e.g. Home page, Login page, appointment module, med-refill module ...
 */
export const PATHS = {
  ROOT: {
    path: "/onenuhs/v2",
    displayName: TITLES.ROOT,
    allowGoBackInHistory: false,
  },

  // =========================
  // UI SandBox
  // =========================

  UI_SANDBOX: {
    path: "/uiSandbox",
    displayName: TITLES.UI_SANDBOX,
    allowGoBackInHistory: true,
  },

  // =========================
  // Log Out SandBox
  // =========================

  LOGOUT: {
    path: "/logout",
    displayName: TITLES.LOGOUT,
    allowGoBackInHistory: true,
  },

  // =========================
  // Admin
  // =========================

  ADMIN: {
    path: "/admin",
    displayName: TITLES.ADMIN,
    allowGoBackInHistory: true,
  },

  // ======================
  // MY PROFILE
  // ======================

  // View Profile
  MY_PROFILE_MOBILE: {
    path: `${PROFILE_PATH}/${MOBILE_PATH}`,
    displayName: TITLES.PROFILE.MAIN,
    allowGoBackInHistory: true,
  },
  MY_PROFILE_MOBILE_ERROR: {
    path: `${PROFILE_PATH}/error/${MOBILE_PATH}`,
    displayName: TITLES.PROFILE.MAIN,
    allowGoBackInHistory: true,
  },
  MY_PROFILE: {
    path: `${PROFILE_PATH}`,
    displayName: TITLES.PROFILE.MAIN,
    allowGoBackInHistory: true,
  },
  MY_PROFILE_PREFERRED_MAILING: {
    path: `${PROFILE_PATH}/preferredMailing`,
    displayName: TITLES.PROFILE.PREFERRED_MAILING,
    allowGoBackInHistory: true,
  },

  // Myinfo
  MY_INFOPROFILE: {
    path: `${PROFILE_PATH}/myInfoProfile`,
    displayName: TITLES.PROFILE.MAIN,
    allowGoBackInHistory: true,
  },

  // This path will handle comparison logic between epic and myinfo, if no difference, it will render confirmation
  MYINFO_AND_PROFILE_CONFIRM: {
    path: `/editMInfoProfile`, //note, url path name does not reflect purpose now due to requirement to change return path on myinfo that is configured to point to /editMyInfoProfile in the past
    displayName: TITLES.PROFILE.MAIN,
    allowGoBackInHistory: true,
  },

  // Show difference between patient master and myInfo
  MYINFO_AND_PROFILE_COMPARE: {
    path: `${PROFILE_PATH}/myInfoAndProfileCompare`,
    displayName: TITLES.PROFILE.MYINFO_VERIFY,
    allowGoBackInHistory: true,
  },

  // User has no record in patient master
  PM_ENTRY_MOBILE: {
    path: `${PROFILE_PATH}/pmEntry/${MOBILE_PATH}`,
    displayName: TITLES.PROFILE.PM_ENTRY,
    allowGoBackInHistory: false,
  },
  PM_ENTRY: {
    path: `${PROFILE_PATH}/pmEntry`,
    displayName: TITLES.PROFILE.PM_ENTRY,
    allowGoBackInHistory: false,
  },

  // =================
  // APPOINTMENT MODULE
  // =================

  APPOINTMENT_MOBILE: {
    path: `${APPOINTMENT_PATH}/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },

  // ------------------------
  // Make an Appointment Flow
  // ------------------------

  APPOINTMENT_CREATE_INSTITUTIONS: {
    path: `${CREATE_APPOINTMENT_PATH}/institutions`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CREATE_INSTITUTIONS_MOBILE: {
    path: `${CREATE_APPOINTMENT_PATH}/institutions/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CREATE_SERVICES: {
    path: `${CREATE_APPOINTMENT_PATH}/services`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CREATE_SPECIALTY: {
    path: `${CREATE_APPOINTMENT_PATH}/specialty`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CREATE_REFERRAL: {
    path: `${CREATE_APPOINTMENT_PATH}/referral`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CREATE_CONSULTANT: {
    path: `${CREATE_APPOINTMENT_PATH}/consultant`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CREATE_AVAILABLE_SLOTS: {
    path: `${CREATE_APPOINTMENT_PATH}/available-slots`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CREATE_LINKED_AVAILABLE_SLOTS: {
    path: `${CREATE_APPOINTMENT_PATH}/linked-available-slots`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CREATE_CONFIRM: {
    path: `${CREATE_APPOINTMENT_PATH}/confirm`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CREATE_BUNDLED_LINKED_CONFIRM: {
    path: `${CREATE_APPOINTMENT_PATH}/linked-confirm`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CREATE_FORM: {
    path: `${CREATE_APPOINTMENT_PATH}/form`,
    displayName: TITLES.APPOINTMENTS.APPOINTMENT_REQUEST,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_OPEN_FORM: {
    path: `${CREATE_APPOINTMENT_PATH}/open-form`,
    displayName: TITLES.APPOINTMENTS.APPOINTMENT_REQUEST,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_MAMMOGRAM_FORM: {
    path: `${CREATE_APPOINTMENT_PATH}/mammogram-form`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },

  // ----------------------------
  // Re-schedule Appointment Flow
  // ----------------------------

  APPOINTMENT_RESCHEDULE_AVAILABLE_SLOTS: {
    path: `${RESCHEDULE_APPOINTMENT_PATH}/available-slots`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_RESCHEDULE_AVAILABLE_SLOTS_MOBILE: {
    path: `${RESCHEDULE_APPOINTMENT_PATH}/available-slots/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_RESCHEDULE_CONFIRM: {
    path: `${RESCHEDULE_APPOINTMENT_PATH}/confirm`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_RESCHEDULE_FORM: {
    path: `${RESCHEDULE_APPOINTMENT_PATH}/form`,
    displayName: TITLES.APPOINTMENTS.APPOINTMENT_REQUEST,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_RESCHEDULE_FORM_MOBILE: {
    path: `${RESCHEDULE_APPOINTMENT_PATH}/form/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.APPOINTMENT_REQUEST,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_MISSED_FORM: {
    path: `${RESCHEDULE_APPOINTMENT_PATH}/missed-form`,
    displayName: TITLES.APPOINTMENTS.APPOINTMENT_REQUEST,
    allowGoBackInHistory: true,
  },

  // ------------------------------
  // Re-schedule Linked Appointments Entry Flow
  // ------------------------------
  APPOINTMENT_RESCHEDULE_LINKED_ENTRY: {
    path: `${RESCHEDULE_APPOINTMENT_PATH}/entry-linked-available-slots`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_RESCHEDULE_LINKED_ENTRY_MOBILE: {
    path: `${RESCHEDULE_APPOINTMENT_PATH}/entry-linked-available-slots/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },

  // ----------------------------
  // Re-schedule Linked Appointments Flow
  // ----------------------------

  APPOINTMENT_RESCHEDULE_LINKED_AVAILABLE_SLOTS: {
    path: `${RESCHEDULE_LINKED_APPOINTMENT_PATH}/available-slots`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_RESCHEDULE_LINKED_CONFIRM: {
    path: `${RESCHEDULE_LINKED_APPOINTMENT_PATH}/confirm`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_RESCHEDULE_LINKED_CONFIRM_MOBILE: {
    path: `${RESCHEDULE_LINKED_APPOINTMENT_PATH}/confirm/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  // ================
  // Bundled Linked Appointment reschedule
  // ================
  APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS_MOBILE: {
    path: `${RESCHEDULE_BUNDLED_LINKED_APPOINTMENT_PATH}/available-slots/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_RESCHEDULE_AVAILABLE_BUNDLED_SLOTS: {
    path: `${RESCHEDULE_BUNDLED_LINKED_APPOINTMENT_PATH}/available-slots`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_RESCHEDULE_BUNDLED_LINKED_CONFIRM: {
    path: `${RESCHEDULE_BUNDLED_LINKED_APPOINTMENT_PATH}/confirm`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },

  // ----------------------------
  // Cancel Appointment Flow
  // ----------------------------

  APPOINTMENT_CANCEL_FORM: {
    path: `${CANCEL_APPOINTMENT_PATH}/form`,
    displayName: TITLES.APPOINTMENTS.APPOINTMENT_REQUEST,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CANCEL_FORM_MOBILE: {
    path: `${CANCEL_APPOINTMENT_PATH}/form/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.APPOINTMENT_REQUEST,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CANCEL: {
    path: `${CANCEL_APPOINTMENT_PATH}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CANCEL_MOBILE: {
    path: `${CANCEL_APPOINTMENT_PATH}/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },

  // ----------------------------
  // Register Appointment Flow
  // ----------------------------
  APPOINTMENT_REGISTER_KIOSK: {
    path: `${REGISTER_APPOINTMENT_PATH}/kiosk`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_REGISTER_KIOSK_MOBILE: {
    path: `${REGISTER_APPOINTMENT_PATH}/kiosk/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CHECKIN: {
    path: `${REGISTER_APPOINTMENT_PATH}/kiosk-checkIn`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_CHECKIN_MOBILE: {
    path: `${REGISTER_APPOINTMENT_PATH}/kiosk-checkIn/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_QUEUECHIT_JOURNEY: {
    path: `${REGISTER_APPOINTMENT_PATH}/queueChitAndJourney`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_QUEUECHIT_JOURNEY_MOBILE: {
    path: `${REGISTER_APPOINTMENT_PATH}/queueChitAndJourney/${MOBILE_PATH}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: true,
  },
  APPOINTMENT_QUEUECHIT_JOURNEY_NO_BACK: {
    path: `${REGISTER_APPOINTMENT_PATH}/queueChitAndJourney/${SPECIAL_HANDLING_STATIC}`,
    displayName: TITLES.APPOINTMENTS.MAIN,
    allowGoBackInHistory: false,
  },

  // ======================
  // MEDICATION REFILL / TOPUP MODULE
  // ======================

  MEDICATIONS_MOBILE: {
    path: `${MED_PATH}/${MOBILE_PATH}`,
    displayName: TITLES.MEDREFILL.MAIN,
    allowGoBackInHistory: true,
  },
  MEDICATIONS_PAST_REQUESTS: {
    path: `${MED_PATH}/past-request-history`,
    displayName: TITLES.MEDREFILL.REQUEST,
    allowGoBackInHistory: true,
  },

  // ----------------------------
  // Medication Refill Flow
  // ----------------------------

  MED_REFILL_SELECT_INSTITUTIONS: {
    path: `${MED_REFILL_PATH}/institutions`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: true,
  },
  MED_REFILL_SELECT_INSTITUTIONS_MOBILE: {
    path: `${MED_REFILL_PATH}/institutions/${MOBILE_PATH}`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: true,
  },
  MED_REFILL_PARTICULARS: {
    path: `${MED_REFILL_PATH}/particulars`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: true,
  },
  MED_REFILL_QUANTITY: {
    path: `${MED_REFILL_PATH}/quantity`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: true,
  },
  MED_REFILL_QUANTITY_EDIT: {
    path: `${MED_REFILL_PATH}/quantity/edit`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: false,
  },
  MED_REFILL_COLLECTION: {
    path: `${MED_REFILL_PATH}/collection`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: true,
  },
  MED_REFILL_COLLECTION_EDIT: {
    path: `${MED_REFILL_PATH}/collection/edit`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: false,
  },
  MED_REFILL_PAYMENT: {
    path: `${MED_REFILL_PATH}/payment`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: true,
  },
  MED_REFILL_PAYMENT_EDIT: {
    path: `${MED_REFILL_PATH}/payment/edit`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: false,
  },
  MED_REFILL_ORDER_SUMMARY: {
    path: `${MED_REFILL_PATH}/order-summary`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: true,
  },
  MED_REFILL_PAST_REQUEST: {
    path: `${MED_REFILL_PATH}/past-request-history`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: true,
  },
  MED_REFILL_PAST_REQUEST_MOBILE: {
    path: `${MED_REFILL_PATH}/past-request-history/${MOBILE_PATH}`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: true,
  },
  MED_REFILL_VIEW_PAST_REQUEST_DETAIL: {
    path: `${MED_REFILL_PATH}/view-past-request-detail`,
    displayName: TITLES.MEDREFILL.REFILL,
    allowGoBackInHistory: true,
  },

  // ----------------------------
  // Medication Topup Flow (renamed to Repeat Prescription)
  // ----------------------------

  MED_TOPUP_SELECT_INSTITUTIONS: {
    path: `${MED_TOPUP_PATH}/institutions`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: true,
  },
  MED_TOPUP_SELECT_INSTITUTIONS_MOBILE: {
    path: `${MED_TOPUP_PATH}/institutions/${MOBILE_PATH}`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: true,
  },
  MED_TOPUP_PARTICULARS: {
    path: `${MED_TOPUP_PATH}/particulars`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: true,
  },
  MED_TOPUP_PRESCRIPTION: {
    path: `${MED_TOPUP_PATH}/prescription`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: true,
  },
  MED_TOPUP_PRESCRIPTION_EDIT: {
    path: `${MED_TOPUP_PATH}/prescription/edit`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: false,
  },
  MED_TOPUP_COLLECTION: {
    path: `${MED_TOPUP_PATH}/collection`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: true,
  },
  MED_TOPUP_COLLECTION_EDIT: {
    path: `${MED_TOPUP_PATH}/collection/edit`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: false,
  },
  MED_TOPUP_PAYMENT: {
    path: `${MED_TOPUP_PATH}/payment`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: true,
  },
  MED_TOPUP_PAYMENT_EDIT: {
    path: `${MED_TOPUP_PATH}/payment/edit`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: false,
  },
  MED_TOPUP_ORDER_SUMMARY: {
    path: `${MED_TOPUP_PATH}/order-summary`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: true,
  },
  MED_TOPUP_PAST_REQUEST: {
    path: `${MED_TOPUP_PATH}/past-request-history`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: true,
  },
  MED_TOPUP_PAST_REQUEST_MOBILE: {
    path: `${MED_TOPUP_PATH}/past-request-history/${MOBILE_PATH}`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: true,
  },
  MED_TOPUP_VIEW_PAST_REQUEST_DETAIL: {
    path: `${MED_TOPUP_PATH}/view-past-request-detail`,
    displayName: TITLES.MEDREFILL.TOPUP,
    allowGoBackInHistory: true,
  },

  // --------------------------------
  // Medication Virtual Consult Flow
  // --------------------------------
  MED_VIRTUALCONSULT_ORDER_MEDICATION_MOBILE: {
    path: `${MED_VIRTUALCONSULT_PATH}/${MOBILE_PATH}`,
    displayName: TITLES.MEDREFILL.ORDER_MEDICATION,
    allowGoBackInHistory: true,
  },
  MED_VIRTUALCONSULT_ORDER_MEDICATION: {
    path: `${MED_VIRTUALCONSULT_PATH}`,
    displayName: TITLES.MEDREFILL.ORDER_MEDICATION,
    allowGoBackInHistory: true,
  },
  MED_VIRTUALCONSULT_ORDER_MEDICATION_PARTICULARS_MOBILE: {
    path: `${MED_VIRTUALCONSULT_PATH}/particulars/${MOBILE_PATH}`,
    displayName: TITLES.MEDREFILL.ORDER_MEDICATION,
    allowGoBackInHistory: true,
  },
  MED_VIRTUALCONSULT_ORDER_MEDICATION_COLLECTION: {
    path: `${MED_VIRTUALCONSULT_PATH}/collection`,
    displayName: TITLES.MEDREFILL.ORDER_MEDICATION,
    allowGoBackInHistory: true,
  },
  MED_VIRTUALCONSULT_ORDER_MEDICATION_COLLECTION_EDIT: {
    path: `${MED_VIRTUALCONSULT_PATH}/collection/edit`,
    displayName: TITLES.MEDREFILL.ORDER_MEDICATION,
    allowGoBackInHistory: false,
  },
  MED_VIRTUALCONSULT_ORDER_MEDICATION_PAYMENT: {
    path: `${MED_VIRTUALCONSULT_PATH}/payment`,
    displayName: TITLES.MEDREFILL.ORDER_MEDICATION,
    allowGoBackInHistory: true,
  },
  MED_VIRTUALCONSULT_ORDER_MEDICATION_PAYMENT_EDIT: {
    path: `${MED_VIRTUALCONSULT_PATH}/payment/edit`,
    displayName: TITLES.MEDREFILL.ORDER_MEDICATION,
    allowGoBackInHistory: false,
  },
  MED_VIRTUALCONSULT_ORDER_MEDICATION_ORDER_SUMMARY: {
    path: `${MED_VIRTUALCONSULT_PATH}/order-summary`,
    displayName: TITLES.MEDREFILL.ORDER_MEDICATION,
    allowGoBackInHistory: true,
  },
  MED_VIRTUALCONSULT_ORDER_MEDICATION_VIEW_PAST_REQUEST_DETAIL: {
    path: `${MED_VIRTUALCONSULT_PATH}/view-past-request-detail`,
    displayName: TITLES.MEDREFILL.ORDER_MEDICATION,
    allowGoBackInHistory: true,
  },

  // ======================
  // PAYMENT MODULE
  // ======================

  PAYMENT_MOBILE: {
    path: `${PAYMENT_PATH}/${MOBILE_PATH}`,
    displayName: TITLES.PAYMENT.MAIN,
    allowGoBackInHistory: true,
  },

  PAYMENT_PAID_BILLS: {
    path: `${PAYMENT_PATH}/paid-bills`,
    displayName: TITLES.PAYMENT.PAID_BILLS,
    allowGoBackInHistory: true,
  },

  PAYMENT_TRANSACTION_HISTORY: {
    path: `${PAYMENT_PATH}/transaction-history`,
    displayName: TITLES.PAYMENT.TRANSACTION_HISTORY,
    allowGoBackInHistory: true,
  },

  PAYMENT_OUTSTANDING_BILL_DETAILS: {
    path: `${PAYMENT_PATH}/outstanding-bill-details`,
    displayName: TITLES.PAYMENT.OUTSTANDING_BILL_DETAILS,
    allowGoBackInHistory: true,
  },

  PAYMENT_ADDED_BILL_DETAILS: {
    path: `${PAYMENT_PATH}/added-bill-details`,
    displayName: TITLES.PAYMENT.ADDED_BILL_DETAILS,
    allowGoBackInHistory: true,
  },

  PAYMENT_BILL_SUMMARY_MOBILE: {
    path: `${PAYMENT_PATH}/bill-summary/${MOBILE_PATH}`,
    displayName: TITLES.PAYMENT.MAIN,
    allowGoBackInHistory: true,
  },

  PAYMENT_BILL_SUMMARY: {
    path: `${PAYMENT_PATH}/bill-summary`,
    displayName: TITLES.PAYMENT.MAIN,
    allowGoBackInHistory: true,
  },
  PAYMENT_PAYOR_INFO: {
    path: `${PAYMENT_PATH}/payor-info`,
    displayName: TITLES.PAYMENT.MAIN,
    allowGoBackInHistory: true,
  },
  PAYMENT_RECEIPT: {
    path: `${PAYMENT_PATH}/receipt`,
    displayName: TITLES.PAYMENT.MAIN,
    allowGoBackInHistory: false,
  },
  PAYMENT_RECEIPT_DD: {
    path: `${PAYMENT_PATH}/receipt/dd`,
    displayName: TITLES.PAYMENT.MAIN,
    allowGoBackInHistory: false,
  },

  PAYMENT_PAYOR_INFO_TRY_AGAIN: {
    path: `${PAYMENT_PATH}/payor-info/try-again`,
    displayName: TITLES.PAYMENT.MAIN,
    allowGoBackInHistory: true,
  },
  PAYMENT_OTHER_ENQUIRIES: {
    path: `${PAYMENT_PATH}/other-enquiries`,
    displayName: TITLES.PAYMENT.OTHER_ENQUIRIES,
    allowGoBackInHistory: true,
  },

  // ======================
  // MRRP MODULE
  // ======================
  MRRP_MOBILE: {
    path: `${MRRP_PATH}/${MOBILE_PATH}`,
    displayName: TITLES.MRRP.MOBILE,
    allowGoBackInHistory: true,
  },
  MRRP_PAST_REPORT_DETAIL: {
    path: `${MRRP_PATH}/pastReportDetail`,
    displayName: TITLES.MRRP.PAST_REPORT_DETAIL,
    allowGoBackInHistory: true,
  },
  MRRP_NEW_REQUEST_SELECT_HOSPITAL: {
    path: `${MRRP_NEW_REQUEST}/selectHospital`,
    displayName: TITLES.MRRP.NEW_REQUEST_SELECT_HOSPITAL,
    allowGoBackInHistory: true,
  },
  MRRP_NEW_REQUEST_VERIFY_PARTICULARS: {
    path: `${MRRP_NEW_REQUEST}/verifyParticulars`,
    displayName: TITLES.MRRP.NEW_REQUEST_VERIFY_PARTICULARS,
    allowGoBackInHistory: true,
  },
  MRRP_NEW_REQUEST_REPORT_DETAILS: {
    path: `${MRRP_NEW_REQUEST}/reportDetails`,
    displayName: TITLES.MRRP.NEW_REQUEST_REPORT_DETAILS,
    allowGoBackInHistory: true,
  },
  MRRP_NEW_REQUEST_COLLECTION: {
    path: `${MRRP_NEW_REQUEST}/collection`,
    displayName: TITLES.MRRP.NEW_REQUEST_COLLECTION,
    allowGoBackInHistory: true,
  },
  MRRP_NEW_REQUEST_SUMMARY: {
    path: `${MRRP_NEW_REQUEST}/summary`,
    displayName: TITLES.MRRP.NEW_REQUEST_SUMMARY,
    allowGoBackInHistory: true,
  },
  MRRP_NEW_REQUEST_PAYMENT: {
    path: `${MRRP_NEW_REQUEST}/payment`,
    displayName: TITLES.MRRP.NEW_REQUEST_PAYMENT,
    allowGoBackInHistory: true,
  },
  MRRP_NEW_REQUEST_RECEIPT: {
    path: `${MRRP_PATH_CAP}/Receipt`,
    displayName: TITLES.MRRP.NEW_REQUEST_RECEIPT,
    allowGoBackInHistory: true,
  },

  // ======================
  // HEALTH TOGETHER MODULE
  // ======================

  HT_MOBILE: {
    path: `${HT_PATH}/${MOBILE_PATH}`,
    displayName: TITLES.HT.HEALTH_TOGETHER,
    allowGoBackInHistory: true,
  },
  HT_SELF_HELP_TOOLS: {
    path: `${HT_PATH}/self-help-tools`,
    displayName: TITLES.HT.SELF_HELP_TOOLS,
    allowGoBackInHistory: true,
  },
  HT_COMMUNITY_CARE: {
    path: `${HT_PATH}/community-care-programmes`,
    displayName: TITLES.HT.COMMUNITY_CARE_PROGRAMMES,
    allowGoBackInHistory: true,
  },

  // ----------------------------
  // My Health Map
  // ----------------------------

  MHM_DASHBOARD: {
    path: `${MHM_PATH}`,
    displayName: TITLES.MHM.MAIN,
    allowGoBackInHistory: true,
  },

  MHM_FIRST_TIME_USER_INTRODUCTION_MOBILE: {
    path: `${MHM_FIRST_TIME_USER_PATH}/introduction/${MOBILE_PATH}`,
    displayName: TITLES.MHM.MAIN,
    allowGoBackInHistory: true,
  },
  MHM_FIRST_TIME_USER_VERIFY_PARTICULARS: {
    path: `${MHM_FIRST_TIME_USER_PATH}/verifyParticulars`,
    displayName: TITLES.MHM.VERIFY_PARTICULARS,
    allowGoBackInHistory: true,
  },
  MHM_DASHBOARD_MOBILE: {
    path: `${MHM_PATH}/${MOBILE_PATH}`,
    displayName: TITLES.MHM.MAIN,
    allowGoBackInHistory: true,
  },
  MHM_ENROLLED_USER_HMT: {
    path: `${MHM_ENROLLED_USER_PATH}/hmt`,
    displayName: null,
    allowGoBackInHistory: true,
  },
  MHM_ENROLLED_USER_QUESTIONNAIRE: {
    path: `${MHM_ENROLLED_USER_PATH}/questionnaire`,
    displayName: null,
    allowGoBackInHistory: true,
  },
} satisfies AppPaths;
